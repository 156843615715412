import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Typography,
  Button,
  Divider,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import SourceType from "../../components/filterPopups/ModalOne";
import FilterSubject from "../../components/filterPopups/ModalTwo";
import FilterYear from "../../components/filterPopups/ModalThree";
import FilterCategory from "../../components/filterPopups/ModalOne";
import FilterAuthor from "../../components/filterPopups/ModalTwo";
import FilterSpeaker from "../../components/filterPopups/ModalTwo";
import FilterResearchGuide from "../../components/filterPopups/ModalTwo";
import FilterJournal from "../../components/filterPopups/ModalTwo";
import FilterRanking from "../../components/filterPopups/ModalOne";
import FilterCountry from "../../components/filterPopups/ModalTwo";
import PublishingAuthor from "../../components/filterPopups/ModalTwo";
import FilterPublishers from "../../components/filterPopups/ModalTwo";
import FilterUniversity from "../../components/filterPopups/ModalTwo";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ResearchModal from "../../components/filterPopups/ModalTwo";
import {
  checkDuplicateFilter,
  getParamsByName,
  goToTop,
} from "../../utils/helper";

// Redux
import {
  updateSourceType,
  updateSubject,
  updateAuthor,
  updateSpeaker,
  updateResearch,
  updateJournal,
  updateSourceIndex,
  updateCountryOfPublication,
  updatePublisher,
  updateInstitution,
  updateJournalRank,
  updateCountryOfPublishingAuthor,
  clearAllFilters,
  updateOpenAccess,
  updateRetractedArticle,
  updateDateset,
  updateAllFilter,
  removeAppliedFilters,
  removeAllFilterType,
  updateResearcher,
} from "../../store/slice/filterSlice";
import { ReducerObj } from "../../types/interface";
import { onSearch as search } from "../../screens/Search/onSearch";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import NewIcon from "../../assets/img/New.png";
import { updateQueries } from "../../store/slice/appliedQueries";
import { filterUpdate } from "../../api/redux-thunks/filterUpdate";
import { usagelogApi } from "../../api/usageReportApi";

// Types

type YearType = { val: string; count: string };

interface PropType {
  yearFromData?: YearType[];
  jsonFacets?: any;
}

const SearchFilter = (props: PropType) => {
  // Props
  const { yearFromData, jsonFacets } = props;
  const dispatch = useAppDispatch();
  const searchTerm = getParamsByName("searchterm");
  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const [sourceTypeModalStatus, setSourceTypeModalStatus] = useState(false);
  const [filterSubjectModalStatus, setFilterSubjectModalStatus] =
    useState(false);
  const [filterYearModalStatus, setFilterYearModalStatus] = useState(false);
  const [filterCategoryModalStatus, setFilterCategoryModalStatus] =
    useState(false);
  const [filterAuthorModalStatus, setFilterAuthorModalStatus] = useState(false);
  const [filterSpeakerModalStatus, setFilterSpeakerModalStatus] =
    useState(false);
  const [filterResearchModalStatus, setFilterResearchModalStatus] =
    useState(false);
  const [filterJournalModalStatus, setFilterJournalModalStatus] =
    useState(false);
  const [filterRankingModalStatus, setFilterRankingModalStatus] =
    useState(false);
  // const [filterSourceModalStatus, setFilterSourceModalStatus] = useState(false);
  const [filterCountryModalStatus, setFilterCountryModalStatus] =
    useState(false);
  const [filterPublisherModalStatus, setFilterPublisherModalStatus] =
    useState(false);
  const [filterUnivModalStatus, setFilterUnivModalStatus] = useState(false);
  const [triggerCount, setTriggerCount] = useState<number>(0);
  const [copaModalStatus, setCopaModalStatus] = useState(false);
  const [reserchModal, setResearchModal] = useState(false);

  // Applied filter values
  const sourceIndex = useAppSelector(
    (state) => state.filterReducer.sourceIndex
  );
  const openAccess = useAppSelector((state) => state.filterReducer.openAccess);

  const retractedArticle = useAppSelector(
    (state) => state.filterReducer.retractedArticle
  );

  const datasetAvailability = useAppSelector(
    (state) => state.filterReducer.datesetAvailability
  );

  const allAppliedFilter = useAppSelector(
    (state) => state.filterReducer.allAppliedFilter
  );
  const profileData = useAppSelector((state: any) => state.login);

  const isDSpace = useAppSelector((state) => state.searchReducer.dSpace);

  const profileData1 = useAppSelector(
    (state: any) => state.customer.customerDetails
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  useEffect(() => {
    if (triggerCount) applySearch();
  }, [triggerCount]);

  // Handle source type modal status changes
  const handleChangeSouceType = () => {
    setSourceTypeModalStatus(false);
  };

  // Handle Filter subject modal status changes
  const handleChangeFilterSubject = () => {
    setFilterSubjectModalStatus(false);
  };

  // Handle Filter year modal status changes
  const handleChangeFilterYear = () => {
    setFilterYearModalStatus(false);
  };

  // Handle Filter category modal status changes
  const handleChangeFilterCategory = () => {
    setFilterCategoryModalStatus(false);
  };

  // Handle Filter Author modal status changes
  const handleChangeFilterAuthor = () => {
    setFilterAuthorModalStatus(false);
  };

  // Handle Filter speaker modal status changes
  const handleChangeFilterSpeaker = () => {
    setFilterSpeakerModalStatus(false);
  };

  // Handle Filter Research modal status changes
  const handleChangeFilterResearch = () => {
    setFilterResearchModalStatus(false);
  };

  const handleChangeResearchModal = () => {
    setResearchModal(false);
  };

  // Handle Filter journal modal status changes
  const handleChangeFilterJournal = () => {
    setFilterJournalModalStatus(false);
  };

  // Handle Filter ranking modal status changes
  const handleChangeFilterRanking = () => {
    setFilterRankingModalStatus(false);
  };

  // // Handle Filter source modal status changes
  // const handleChangeFilterSource = () => {
  //   setFilterSourceModalStatus(false);
  // };

  // Handle Filter country modal status changes
  const handleChangeFilterCountry = () => {
    setFilterCountryModalStatus(false);
  };

  // Handle Filter publisher modal status changes
  const handleChangeFilterPublisher = () => {
    setFilterPublisherModalStatus(false);
  };

  // Handle Filter univ modal status changes
  const handleChangeFilterUniv = () => {
    setFilterUnivModalStatus(false);
  };

  // Get filterd values
  const getSouceTypeValues = (data: string[]) => {
    dispatch(updateSourceType(data));

    let filteredDatatype = allAppliedFilter.filter(
      (obj: any) => obj?.key === "data_type"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "data_type",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredDatatype, "sourceType"));
  };

  // Get category values
  const getCategoryTypeValues = (data: string[]) => {
    console.log(data);
  };

  // Get subject values
  const getSubjectTypeValues = (data: string[]) => {
    dispatch(updateSubject(data));
    let filteredSubject = allAppliedFilter.filter(
      (obj: any) => obj?.key === "subject"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "subject",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredSubject, "subject"));
    logBasicSearchUsageData(56);
  };
  // Get Author values
  const getAuthorTypeValues = (data: string[]) => {
    dispatch(updateAuthor(data));
    let filteredArr = allAppliedFilter.filter((obj) => obj?.key === "author");

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "author",
          value: val,
        });
      }
      logBasicSearchUsageData(53);
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "author"));
  };
  // Get speaker values
  const getSpeakerTypeValues = (data: string[]) => {
    dispatch(updateSpeaker(data));

    let filteredArr = allAppliedFilter.filter((obj) => obj?.key === "speaker");

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "speaker",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "speaker"));
    logBasicSearchUsageData(54);
  };

  // Get research values
  const getResearchTypeValues = (data: string[]) => {
    dispatch(updateResearch(data));
    let filteredArr = allAppliedFilter.filter((obj) => obj?.key === "research");
    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "research",
          value: val,
        });
      }
    });
    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "research"));
    logBasicSearchUsageData(55);
  };

  const getResearcherTypeValues = (data: string[]) => {
    dispatch(updateResearcher(data));
    let filteredArr = allAppliedFilter.filter(
      (obj) => obj?.key === "researcher"
    );
    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "researcher",
          value: val,
        });
      }
    });
    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "researcher"));
    logBasicSearchUsageData(55);
  };

  // Get journal values
  const getJournalTypeValues = (data: string[]) => {
    dispatch(updateJournal(data));

    let filteredArr = allAppliedFilter.filter((obj) => obj?.key === "journal");

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "journal",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "journal"));
    logBasicSearchUsageData(59);
  };

  // Get ranking values
  const getRankingTypeValues = (data: string[]) => {
    dispatch(updateJournalRank(data));

    let filteredArr = allAppliedFilter.filter(
      (obj) => obj?.key === "journalRank"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "journalRank",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "journalRank"));
    logBasicSearchUsageData(52);
  };

  // Get cop values
  const getCopTypeValues = (data: string[]) => {
    dispatch(updateCountryOfPublication(data));

    let filteredArr = allAppliedFilter.filter(
      (obj) => obj?.key === "countryOfPublication"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "countryOfPublication",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "countryOfPublication"));
    logBasicSearchUsageData(58);
  };

  // Get cop values
  const getCopaTypeValues = (data: string[]) => {
    dispatch(updateCountryOfPublishingAuthor(data));

    let filteredArr = allAppliedFilter.filter(
      (obj) => obj?.key === "countryOfPublishingAuthor"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "countryOfPublishingAuthor",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "countryOfPublishingAuthor"));
  };

  // Get publisher values
  const getPublisherTypeValues = (data: string[]) => {
    dispatch(updatePublisher(data));

    let filteredArr = allAppliedFilter.filter(
      (obj) => obj?.key === "publisher"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "publisher",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "publisher"));
    logBasicSearchUsageData(51);
  };

  // Get univ values
  const getUnivTypeValues = (data: string[]) => {
    dispatch(updateInstitution(data));

    let filteredArr = allAppliedFilter.filter(
      (obj) => obj?.key === "institutions"
    );

    let appliedFilter: any[] = [];
    data.forEach((val) => {
      const isExist = checkDuplicateFilter(allAppliedFilter, val);
      if (!isExist) {
        appliedFilter.push({
          key: "institutions",
          value: val,
        });
      }
    });

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, "institutions"));
    logBasicSearchUsageData(57);
  };

  // Get year values
  const getYearTypeValues = (data: string[]) => {
    console.log(data);
  };

  // function apply filter
  async function applyFilter() {
    // if (!searchTerm) return;
    setTriggerCount((prev) => prev + 1);

    goToTop();
  }

  async function applySearch() {
    dispatch(search("", null, true));
  }

  // clear all filters
  function handleClearAllFilters() {
    logBasicSearchUsageData(61);

    dispatch(clearAllFilters());
    dispatch(removeAppliedFilters());

    setTriggerCount((prev) => prev + 1);
  }

  // Handle independent filter source index
  function handleSourceIndex() {
    logBasicSearchUsageData(65);

    dispatch(updateSourceIndex(!sourceIndex));
    setTriggerCount((prev) => prev + 1);
  }

  function handleOpenAccess() {
    logBasicSearchUsageData(62);
    dispatch(updateOpenAccess(!openAccess));
    setTriggerCount((prev) => prev + 1);
  }

  function handleRetractedArticles() {
    logBasicSearchUsageData(63);
    dispatch(updateRetractedArticle(!retractedArticle));
    setTriggerCount((prev) => prev + 1);
  }

  function handleDateSet() {
    logBasicSearchUsageData(64);

    dispatch(updateDateset(!datasetAvailability));
    setTriggerCount((prev) => prev + 1);
  }
  const shouldDisplayRetracted =
    jsonFacets?.is_retracted?.buckets?.filter((obj: any) => obj.val === true)
      .length > 0;
  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={[webStyle.flexBox]}>
          <Typography
            sx={{
              alignSelf: "center",
              fontFamily: "Lora",
              fontWeight: 700,
              fontSize: "1.125rem",
            }}
          >
            Filters
          </Typography>
          <Button onClick={handleClearAllFilters}>Clear All</Button>
        </Box>

        {Array.isArray(jsonFacets?.data_type?.buckets) &&
        jsonFacets?.data_type?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Document Type
              </Typography>
              <Button onClick={() => setSourceTypeModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {/* <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
          <Typography sx={{ fontSize: "0.875rem", alignSelf: "center" }}>
            Category
          </Typography>
          <Button onClick={() => setFilterCategoryModalStatus(true)}>
            <ControlPointIcon></ControlPointIcon>
          </Button>
        </Box>
        <Box>
          {categoryValue.length
            ? categoryValue?.map((data) => (
                <Stack direction={"row"} alignItems="center">
                  <Button
                    onClick={() =>
                      removeFilter(data, categoryValue, setCategoryValue)
                    }
                  >
                    <CloseIcon sx={{ color: "#6B7280" }} />
                  </Button>
                  <Typography>{data}</Typography>
                </Stack>
              ))
            : null}
        </Box>
        <Divider /> */}

        {Array.isArray(jsonFacets?.subjects_name_l3?.buckets) &&
        jsonFacets?.subjects_name_l3?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Subject
              </Typography>
              <Button onClick={() => setFilterSubjectModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.authors_tk?.buckets) &&
        jsonFacets?.authors_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Author
              </Typography>
              <Button onClick={() => setFilterAuthorModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.speakers?.buckets) &&
        jsonFacets?.speakers?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Speaker
              </Typography>
              <Button onClick={() => setFilterSpeakerModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.researcher_tk?.buckets) &&
        jsonFacets?.researcher_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Researcher
              </Typography>
              <Button onClick={() => setResearchModal(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.guide_name_tk?.buckets) &&
        jsonFacets?.guide_name_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                {/* Guides (Ph.D Theses) */}
                Research Guides
              </Typography>
              <Button onClick={() => setFilterResearchModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.journal_name?.buckets) &&
        jsonFacets?.journal_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Journal
              </Typography>
              <Button onClick={() => setFilterJournalModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.filter_metrix?.buckets) &&
        jsonFacets?.filter_metrix?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Journal Ranking
              </Typography>
              <Button onClick={() => setFilterRankingModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {/* <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
          <Typography sx={{ fontSize: "0.875rem", alignSelf: "center" }}>
            Year(3345)
          </Typography>
          <Button onClick={() => setFilterYearModalStatus(true)}>
            <ControlPointIcon></ControlPointIcon>
          </Button>
        </Box>
        <Box>
          {year.length
            ? year?.map((data) => (
                <Stack direction={"row"} alignItems="center">
                  <Button onClick={() => removeFilter(data, year, setYear)}>
                    <CloseIcon sx={{ color: "#6B7280" }} />
                  </Button>
                  <Typography>{data}</Typography>
                </Stack>
              ))
            : null}
        </Box>
        <Divider /> */}

        {Array.isArray(jsonFacets?.primary_publisher_country?.buckets) &&
        jsonFacets?.primary_publisher_country?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Publication Country
              </Typography>
              <Button onClick={() => setFilterCountryModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_country_name?.buckets) &&
        jsonFacets?.author_country_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                {/* Country of Publishing Author */}
                Author Country
              </Typography>
              <Button onClick={() => setCopaModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : (
          ""
        )}

        {Array.isArray(jsonFacets?.publisher_name?.buckets) &&
        jsonFacets?.publisher_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Publishers
              </Typography>
              <Button onClick={() => setFilterPublisherModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_address?.buckets) &&
        jsonFacets?.author_address?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Institution
              </Typography>
              <Button onClick={() => setFilterUnivModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {!isDSpace && (
          <>
            <Box>
              <Typography
                mt={2}
                mb={2}
                sx={{
                  fontSize: "0.875rem",
                  alignSelf: "center",
                  fontFamily: "Lora",
                  fontWeight: "600",
                }}
              >
                Independent Filters
              </Typography>
              <Divider />

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={openAccess} />}
                  label="Open Access"
                  onChange={handleOpenAccess}
                  componentsProps={{
                    typography: {
                      fontFamily: "Lora",
                      fontWeight: "600",
                      fontSize: "14px",
                    },
                  }}
                />
                {Array.isArray(jsonFacets?.is_retracted?.buckets) &&
                shouldDisplayRetracted ? (
                  <FormControlLabel
                    control={<Checkbox checked={retractedArticle} />}
                    onChange={handleRetractedArticles}
                    sx={{ fontFamily: "Lora", fontWeight: "600" }}
                    componentsProps={{
                      typography: {
                        fontFamily: "Lora",
                        fontWeight: "600",
                        fontSize: "14px",
                      },
                    }}
                    label={
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        Retracted Articles
                        <img src={NewIcon} style={{ width: "50px" }} />{" "}
                      </Box>
                    }
                  />
                ) : (
                  ""
                )}
                {Array.isArray(jsonFacets?.has_datasets?.buckets) &&
                jsonFacets?.has_datasets?.buckets.length ? (
                  <FormControlLabel
                    control={<Checkbox checked={datasetAvailability} />}
                    onChange={handleDateSet}
                    sx={{ fontFamily: "Lora", fontWeight: "600" }}
                    componentsProps={{
                      typography: {
                        fontFamily: "Lora",
                        fontWeight: "600",
                        fontSize: "14px",
                      },
                    }}
                    label={
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        Dataset Availability
                        <img src={NewIcon} style={{ width: "50px" }} />{" "}
                      </Box>
                    }
                  />
                ) : (
                  ""
                )}
              </FormGroup>
            </Box>
            <Divider sx={{ marginTop: "10px" }} />

            {Array.isArray(jsonFacets?.pubmed_article?.buckets) &&
            jsonFacets?.pubmed_article?.buckets.length ? (
              <Box>
                <Typography
                  mt={2}
                  mb={2}
                  sx={{
                    fontSize: "0.875rem",
                    alignSelf: "center",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  }}
                >
                  Source Index
                </Typography>
                <Divider />
                <FormGroup>
                  {/* {Array.isArray(jsonFacets?.pubmed_article?.buckets) &&
                  jsonFacets?.pubmed_article?.buckets.length ? ( */}
                  <FormControlLabel
                    onChange={handleSourceIndex}
                    control={<Checkbox checked={sourceIndex} />}
                    label="PubMed Articles"
                    sx={{ fontFamily: "Lora", fontWeight: "600" }}
                    componentsProps={{
                      typography: {
                        fontFamily: "Lora",
                        fontWeight: "600",
                        fontSize: "14px",
                      },
                    }}
                  />
                  {/* ) : (
                    ""
                  )} */}
                </FormGroup>
              </Box>
            ) : (
              ""
            )}
          </>
        )}
      </Box>

      {/* Filter popups */}
      <SourceType
        isOpen={sourceTypeModalStatus}
        handleClose={handleChangeSouceType}
        modalName="Filter Document Type"
        getFilterdValues={getSouceTypeValues}
        filterValues={jsonFacets?.data_type?.buckets}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.sourceType}
      />

      <FilterCategory
        isOpen={filterCategoryModalStatus}
        handleClose={handleChangeFilterCategory}
        modalName="Filter Category"
        getFilterdValues={getCategoryTypeValues}
        filterValues={jsonFacets?.openaccess_type?.buckets}
      />
      <FilterSubject
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject "
        getFilterdValues={getSubjectTypeValues}
        filterData={jsonFacets?.subjects_name_l3?.buckets}
        applyFilter={applyFilter}
        placeholderName="Subject name"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
      />
      <FilterYear
        isOpen={filterYearModalStatus}
        handleClose={handleChangeFilterYear}
        modalName="Filter Year`"
        getFilterdValues={getYearTypeValues}
        yearData={yearFromData}
      />
      <FilterAuthor
        isOpen={filterAuthorModalStatus}
        handleClose={handleChangeFilterAuthor}
        modalName="Filter Author"
        getFilterdValues={getAuthorTypeValues}
        filterData={jsonFacets?.authors_tk?.buckets}
        applyFilter={applyFilter}
        placeholderName="Author Last name only"
        appliedFilterValues={selectedFilters?.author}
        facetKey="authors_tk"
      />
      <FilterSpeaker
        isOpen={filterSpeakerModalStatus}
        handleClose={handleChangeFilterSpeaker}
        modalName="Filter Speaker"
        getFilterdValues={getSpeakerTypeValues}
        filterData={jsonFacets?.speakers?.buckets}
        applyFilter={applyFilter}
        placeholderName="Speaker Last name only"
        appliedFilterValues={selectedFilters?.speaker}
        facetKey="speakers"
      />
      <FilterResearchGuide
        isOpen={filterResearchModalStatus}
        handleClose={handleChangeFilterResearch}
        modalName="Filter Research Guide"
        getFilterdValues={getResearchTypeValues}
        filterData={jsonFacets?.guide_name_tk?.buckets}
        applyFilter={applyFilter}
        placeholderName="Research Guide Last name only"
        appliedFilterValues={selectedFilters?.research}
        facetKey="guide_name_tk"
      />

      <ResearchModal
        isOpen={reserchModal}
        handleClose={handleChangeResearchModal}
        modalName="Filter Researcher"
        getFilterdValues={getResearcherTypeValues}
        filterData={jsonFacets?.researcher_tk?.buckets}
        applyFilter={applyFilter}
        placeholderName="Researcher Last name only"
        appliedFilterValues={selectedFilters?.researcher}
        facetKey="researcher_tk"
      />

      <FilterJournal
        isOpen={filterJournalModalStatus}
        handleClose={handleChangeFilterJournal}
        modalName="Filter Journal"
        getFilterdValues={getJournalTypeValues}
        filterData={jsonFacets?.journal_name?.buckets}
        applyFilter={applyFilter}
        placeholderName="Journal name"
        appliedFilterValues={selectedFilters?.journal}
        facetKey="journal_name"
      />
      <FilterRanking
        isOpen={filterRankingModalStatus}
        handleClose={handleChangeFilterRanking}
        modalName="Filter Journal Ranking"
        getFilterdValues={getRankingTypeValues}
        filterValues={jsonFacets?.filter_metrix?.buckets}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.journalRank}
      />
      <FilterCountry
        isOpen={filterCountryModalStatus}
        handleClose={handleChangeFilterCountry}
        modalName="Filter Publication Country"
        getFilterdValues={getCopTypeValues}
        filterData={jsonFacets?.primary_publisher_country?.buckets}
        applyFilter={applyFilter}
        placeholderName="Publication Country name"
        appliedFilterValues={selectedFilters?.countryOfPublication}
        facetKey="primary_publisher_country"
      />

      <PublishingAuthor
        isOpen={copaModalStatus}
        handleClose={() => setCopaModalStatus(false)}
        modalName="Filter Author Country"
        getFilterdValues={getCopaTypeValues}
        filterData={jsonFacets?.author_country_name?.buckets}
        applyFilter={applyFilter}
        placeholderName="Author Country name"
        appliedFilterValues={selectedFilters?.countryOfPublishingAuthor}
        facetKey="author_country_name"
      />
      <FilterPublishers
        isOpen={filterPublisherModalStatus}
        handleClose={handleChangeFilterPublisher}
        modalName="Filter Publishers"
        getFilterdValues={getPublisherTypeValues}
        filterData={jsonFacets?.publisher_name?.buckets}
        applyFilter={applyFilter}
        placeholderName="Publishers name"
        appliedFilterValues={selectedFilters?.publisher}
        facetKey="publisher_name"
      />
      <FilterUniversity
        isOpen={filterUnivModalStatus}
        handleClose={handleChangeFilterUniv}
        modalName="Filter Institution"
        getFilterdValues={getUnivTypeValues}
        filterData={jsonFacets?.author_address?.buckets}
        applyFilter={applyFilter}
        placeholderName="Institution name"
        appliedFilterValues={selectedFilters?.institutions}
        facetKey="author_address"
      />
    </>
  );
};

export default SearchFilter;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
