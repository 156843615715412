import { useEffect, useState } from "react";
import { Stack, TextField, Button, Autocomplete, Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import Text from "@mui/material/Typography";
import { Switch } from "../../components/Switch";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllFilters as clearAuthorFilters,
  updateAuthorDataType,
  updateAuthorFirstName,
  updateAuthorLastName,
  updateAuthorName,
  updateAuthorPage,
  updateAuthorRow,
  updateAuthorSearchResults,
  updateAuthorSearchTerm,
  updateAuthorSubjectName,
  updateAuthorSubjectType,
} from "../../store/slice/authorFinderSlice";
import {
  authorFinderApi,
  getAuthorSuggestionList,
  getAuthorSuggestionListFirstName,
} from "../../api/authorFinderApi";
import { queryConstructor } from "../../api/formQuery";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  clearDataTypeFilters,
  clearSearchTerm,
  updateDataType,
  updateIsNavigatingBack,
} from "../../store/slice/searchSlice";
import {
  clearAllFilters,
  clearLimits,
  clearPublicationFilter,
} from "../../store/slice/filterSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "../../store/hooks";
import AuthorFinderHeader from "./AuthorFinderHeader";
import {
  GetCustomerByIDDatatype,
  GetCustomerByIDSubject,
} from "../../types/GetCustomerByID.types";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import { temporaryQueryConstructor } from "../AdvancedSearch/TemporaryQueryConstructor";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import { isPreferenceAdded, showIsProfileToast } from "../../utils/helper";
import { setModal } from "../../store/slice/modalSlice";
import { clearAllFiltersValue } from "../../store/slice/filterSlice";
import { usagelogApi } from "../../api/usageReportApi";
import "./author.css";
import { dataTypes } from "../../utils/constants";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { basicSearchMain } from "../../api/Search/BasicSearch.api";
import { updateBrowseJournalSearch } from "../../store/slice/browseJournal";
import { CustomTooltip } from "../MyLibrary/MyAlerts/Alerts.style";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { clearAllFilters as clearAllJournalFilters } from "../../store/slice/browseFilterSlice";

export interface State extends SnackbarOrigin {
  open: boolean;
}

const AuthorFinder = () => {
  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";

  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selector = useAppSelector((state) => state.login);
  const [allTags, setAllTags] = useState<GetCustomerByIDDatatype[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const [subjectData, setSubjectData] = useState<GetCustomerByIDSubject[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<number[]>([0]);
  const [selectedSubjectNames, setSelectedSubjectNames] = useState<string[]>(
    []
  );

  const [isAllSubjectSelected, updateSubjectSelected] = useState(false);

  const [isProfileUser, setIsProfileUser] = useState(false);

  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  //Get Personalized Data for Preference
  const { data: myPreference, refetch } = useGetMySearchPreference(
    selector.user_id,
    isPreference
  );

  const profileStatus = sessionStorage.getItem("isProfileUser");
  const isNavigatingBack = useAppSelector(
    (state) => state.searchReducer.isNavigatingBack
  );

  useEffect(() => {
    // clearAllFilter();
    refetch();
    dispatch(clearAllFilters());
    dispatch(clearDataTypeFilters());
    dispatch(clearAuthorFilters());
    dispatch(updateBrowseJournalSearch(false));
    dispatch(clearLimits());
    dispatch(clearAllJournalFilters());
    dispatch(clearPublicationFilter());
  }, []);
  useEffect(() => {
    // fetch mypreference data
    sessionStorage.getItem("isProfileUser") === "true" && refetch();
  }, [sessionStorage.getItem("isProfileUser")]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const userId = sessionStorage.getItem("isProfileUser");
      if (userId === "true") {
        setIsProfileUser(true);
      } else if (userId === "false") {
        setIsProfileUser(false);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const searchReducer = useAppSelector((state) => state.searchReducer);
  const autherReducer = useAppSelector((state) => state.authorReducer);

  const [valueLastName, setValueLastName] = useState<any>("");
  const [valueFirstName, setValueFirstName] = useState<any>("");
  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );
  const customerDataTypes: any = profileData1?.datatypes?.map((item: any) => {
    return item?.dat_resourcetype_id;
  });
  const onlyJournals =
    customerDataTypes?.length === 4 &&
    customerDataTypes.includes(1 && 2 && 5 && 24);

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };
  // useEffect(() => {
  //   showLoginModa();
  // }, [profileStatus]);

  useEffect(() => {
    showLoginModa();
  }, [customerData?.customer_id]);

  function showLoginModa() {
    if (!customerData?.allow_profile) return;
    if (sessionStorage.getItem("isLoginModalShowed") === "true") return;
    // if (profileStatus === "false" || customerData?.customer_id === 0) {
    if (customerData?.customer_id === 0) {
      dispatch(
        setModal({
          modalType: "SHOW_LOGIN",
          modalProps: {
            open: true,
            isProfileUser: profileStatus,
          },
        })
      );
      sessionStorage.setItem("isLoginModalShowed", "true");
    }
  }

  useEffect(() => {
    updateAllSubject();
  }, [selectedSubjects.length, customerData?.subject?.length]);

  useEffect(() => {
    selectAllTags();
  }, [selectedTags.length]);

  useEffect(() => {
    try {
      if (customerData) {
        let filteredSubjectData: GetCustomerByIDSubject[] = [];
        let filteredSubjectIds: number[] = [];
        let filteredSubjectNames: string[] = [];
        let filteredAllTags: GetCustomerByIDDatatype[] = [];
        let filteredSelectedTags: number[] = [];
        let isAllSelected: boolean = true;
        const selectedDocTypes =
          Array.isArray(searchReducer.dataType) &&
          searchReducer.dataType?.filter((x) => x !== 2);

        //Clear All Tag's Filters
        dispatch(clearDataTypeFilters());

        if (isPreference && myPreference) {
          //Get My Subject Data
          filteredSubjectData = myPreference.Subjects?.map(
            ({ subject2_details }) =>
              subject2_details.filter(({ is_favorite }) => is_favorite)
          )
            .flat()
            ?.map((l2) => {
              return {
                datsubjectmasterlevel1_id: -1, //Just for Integrity's Sake
                datsubjectmasterlevel2_id: l2.subject_id2,
                level2subject_name: l2.subject_name2,
              };
            });

          //Get My Subject Data IDs
          filteredSubjectIds = filteredSubjectData?.map(
            ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
          );
          //Get My Subject Data Names
          filteredSubjectNames = filteredSubjectData?.map(
            ({ level2subject_name }) => level2subject_name
          );

          //Get My Sources
          filteredAllTags = myPreference?.DataTypes?.map((source) => {
            return {
              dat_resourcetype_id: source.datatype_id,
              accesstype: source.datatype_name,
              image_small: source.datatype_name.toLocaleLowerCase(),
            };
          });

          //Get My Source ID's
          filteredSelectedTags = myPreference?.DataTypes.filter(
            ({ is_favorite }) => is_favorite
          )?.map(({ datatype_id }) => datatype_id);

          filteredAllTags = customerData?.datatypes?.filter(
            (x) => x.dat_resourcetype_id !== 2
          );

          filteredAllTags = filteredAllTags?.filter(
            (x) => x.dat_resourcetype_id !== 24
          );
          filteredAllTags = filteredAllTags.filter(
            (x) => x.dat_resourcetype_id !== 5
          );

          //Should Select All Tags if True
          isAllSelected =
            filteredSelectedTags.length === myPreference?.DataTypes.length;
        } else {
          //Get All Subject Data
          filteredSubjectData = customerData.subject;

          //Get All Subject Data IDs
          filteredSubjectIds = customerData.subject?.map(
            ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
          );

          //Get My Subject Data Names
          filteredSubjectNames = customerData.subject?.map(
            ({ level2subject_name }) => level2subject_name
          );

          //Get All Sources
          filteredAllTags = customerData?.datatypes?.filter(
            (x) => x.dat_resourcetype_id !== 2
          );
          filteredAllTags = filteredAllTags?.filter(
            (x) => x.dat_resourcetype_id !== 24
          );
          filteredAllTags = filteredAllTags.filter(
            (x) => x.dat_resourcetype_id !== 5
          );
        }

        //Update Subjects
        setSubjectData(() => filteredSubjectData);

        //Update Subject Ids
        dispatch(updateAuthorSubjectType(filteredSubjectIds));
        setSelectedSubjects(() => filteredSubjectIds);

        //Update Subject Names
        dispatch(updateAuthorSubjectName(filteredSubjectNames));
        setSelectedSubjectNames(() => filteredSubjectNames);

        let docArray: any = [];

        if (filteredAllTags) {
          docArray = [...filteredAllTags];
          if (!onlyJournals) {
            docArray.unshift({
              dat_resourcetype_id: 0, // Custom ID, 0 => All
              accesstype: "All",
              image_small: "all",
            });
          }
        }
        let selectedDoc = isAllSelected
          ? docArray?.map((x: any) => x?.dat_resourcetype_id)
          : filteredSelectedTags;

        //Update Tags
        if (selectedTags.length && !isPreference) {
          setSelectedTags(selectedTags);
        } else if (
          selectedDocTypes &&
          selectedDocTypes.length &&
          !isPreference
        ) {
          setSelectedTags(selectedDocTypes);
        } else {
          if (
            window.location.pathname === "/authorfinder" &&
            isNavigatingBack &&
            !isPreference
          ) {
            setSelectedTags(autherReducer?.dataType);
            dispatch(updateIsNavigatingBack(false));
          } else {
            setSelectedTags(selectedDoc);
          }
        }
        setAllTags(() => docArray);
        dispatch(updateDataType(selectedDoc));
      }
    } catch (err) {
      console.log("Unknown error occoured");
    }
  }, [customerData, dispatch, isPreference, myPreference]);
  function updateAllSubject() {
    let preferenceSubject =
      myPreference &&
      myPreference?.Subjects?.map(({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
      )
        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

    let isAllSelected = isPreference
      ? preferenceSubject?.length === selectedSubjects.length
      : customerData?.subject?.length === selectedSubjects.length;

    updateSubjectSelected(isAllSelected);
  }

  //Handle Getting names of selected subjects

  const handleTags = (id: number) => {
    if (onlyJournals) {
      return;
    }
    logBasicSearchUsageData(16);
    sessionStorage.setItem("preference", "false");
    setIsPreference(false);

    let allDocIds: any = customerData?.datatypes
      .filter((x) => x.dat_resourcetype_id !== 2)
      ?.map((obj) => obj?.dat_resourcetype_id);
    if (id === 0) {
      // if the preference is on and user select all the preference toggle should be turn off
      sessionStorage.setItem("preference", "false");
      setIsPreference(false);
      //"All" is Selected
      if (selectedTags.length === allDocIds?.length + 1) {
        setSelectedTags([]);
      } else {
        setSelectedTags(() => [...allDocIds, 0]);
      }
    } else {
      if (selectedTags.includes(id)) {
        //Remove
        const newArr = selectedTags
          .filter((item) => item !== id)
          .filter((x) => x !== 0);
        setSelectedTags(() => newArr);
      } else {
        //Add Current & deselct "All"
        let curr = selectedTags.filter((item) => item !== 0);
        setSelectedTags([...curr, id]);
      }
    }
  };

  function selectAllTags() {
    let allDocIds: any = customerData?.datatypes
      .filter((x) => x.dat_resourcetype_id !== 2)
      ?.map((obj) => obj?.dat_resourcetype_id);

    let selectedDocs = selectedTags.filter((str) => str !== 2);

    if (allDocIds?.length === selectedDocs.length) {
      setSelectedTags((prev) => [...prev, 0]);
      sessionStorage.setItem("preference", "false");
      setIsPreference(false);
    }
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  // Search functionality
  async function handleAuthorFinderSearch() {
    if (valueFirstName.length === 0 && valueLastName.length === 0) {
      setOpenAlert(true);
      setMessage("Enter First name or Last name");

      return;
    } else if (
      valueFirstName.length < 2 &&
      (!!valueLastName.trim().match(/^[a-zA-Z]+\.[a-zA-Z]+$/) ||
        !!valueLastName.trim().match(/^[a-zA-Z]+\.+$/))
    ) {
      setOpenAlert(true);
      setMessage("Enter last name as word");

      return;
    } else if (valueFirstName.length < 2 && valueLastName.length < 2) {
      setOpenAlert(true);
      setMessage("Enter valid name");

      return;
    } else if (
      valueFirstName.split(".").length >= 2 &&
      valueFirstName.match(/\./g).length < 3 &&
      valueLastName.length < 3
    ) {
      setOpenAlert(true);
      setMessage("Last name is mandatory");

      return;
    } else {
      dispatch(updateDataType(selectedTags));
      dispatch(updateAuthorDataType(selectedTags));

      const fullName = `${valueLastName} ${valueFirstName}`;

      setLoading(true);
      dispatch(clearAllFilters());
      dispatch(clearAllFiltersValue());
      dispatch(clearSearchTerm());
      dispatch(updateAuthorName(fullName));
      dispatch(updateAuthorSearchTerm(fullName));
      dispatch(updateAuthorFirstName(valueFirstName));
      dispatch(updateAuthorLastName(valueLastName));

      interface ObjType {
        [key: string]: any;
      }
      let dataTypeValue: any = [];
      let queryObj: ObjType = {};

      dispatch(updateDataType(selectedTags));

      if (searchReducer.dataType.includes(0)) {
        dataTypeValue = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";
      } else {
        dataTypeValue = searchReducer.dataType;
      }

      dispatch(updateAuthorRow(15));
      dispatch(updateAuthorPage(1));

      let encodedSubjects = "";
      if (selectedSubjects.length === 0) {
        encodedSubjects = `(${temporaryQueryConstructor(
          subjectData?.map((x) => x.datsubjectmasterlevel2_id)
        )})`;
      } else {
        encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
      }

      let encodedTags = "";
      let tags = [...selectedTags];
      if (tags.includes(1)) tags.push(2);
      if (tags.includes(1) && !tags.includes(5)) tags.push(5);
      if (tags.includes(1) && !tags.includes(24)) tags.push(24);
      if (!tags.includes(1) && tags.includes(2))
        tags = tags.filter((tag) => tag !== 2);
      if (!tags.includes(1) && tags.includes(5))
        tags = tags.filter((tag) => tag !== 5);
      if (!tags.includes(1) && tags.includes(24))
        tags = tags.filter((tag) => tag !== 24);

      // if (tags.length == 8 && tags.includes(0)) {
      if (tags.length == 8 && tags.includes(0)) {
        encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";
      } else {
        encodedTags = `(${temporaryQueryConstructor(tags)})`;
      }

      if (tags.length === 0) encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";

      dispatch(updateAuthorSubjectType(encodedSubjects));
      dispatch(updateDataType(encodedTags));

      const fullTextQuery = await queryConstructor({
        ...queryObj,
        // fq__fulltext: true,
        "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
      });
      const allQuery = await queryConstructor({
        ...queryObj,
      });

      dispatch(
        updateQuery({
          fullTextQuery: fullTextQuery,
          allTextQuery: allQuery,
        })
      );

      if (!valueFirstName) {
        queryObj.author_lastname_fz = `(${valueLastName
          ?.toString()
          ?.replaceAll('"', "")})`;
        // queryObj.fq__resourcetype_id = dataTypeValue;
        queryObj.fq__resourcetype_id = encodedTags;
        queryObj.fq__datsubjectmasterlevel2_id = encodedSubjects;
        queryObj.page = 1;

        //queryObj.sort = 'author_lastname asc'
        queryObj.sort = "author_firstname asc, author_lastname asc";

        // dispatch(updateDataType(encodedTags));
        // dispatch(updateAuthorDataType(encodedTags))
      } else if (!valueLastName) {
        // queryObj.author_firstname_fz = `("${valueFirstName
        //   ?.toString()
        //   ?.replaceAll('"', "")}")`;

        queryObj.author_firstname_fz = `(${valueFirstName
          ?.toString()
          ?.replaceAll('"', "")})`;

        // queryObj.fq__resourcetype_id = dataTypeValue;
        queryObj.fq__resourcetype_id = encodedTags;
        queryObj.fq__datsubjectmasterlevel2_id = encodedSubjects;
        queryObj.page = 1;
        //queryObj.sort = 'author_firstname_fz asc'
        queryObj.sort = "author_firstname asc, author_lastname asc";

        // dispatch(updateDataType(encodedTags));
        //  dispatch(updateAuthorDataType(encodedTags))
      } else {
        // queryObj.author_firstname_fz = `("${valueFirstName}")`;
        queryObj.author_firstname_fz = `(${valueFirstName})`;

        queryObj.author_lastname_fz = `(${valueLastName
          ?.toString()
          ?.replaceAll('"', "")})`;
        // queryObj.fq__resourcetype_id = dataTypeValue;
        queryObj.fq__resourcetype_id = encodedTags;
        queryObj.fq__datsubjectmasterlevel2_id = encodedSubjects;
        queryObj.page = 1;
        //queryObj.sort = 'author_firstname_fz asc author_lastname asc'
        queryObj.sort = "author_firstname asc, author_lastname asc";

        // dispatch(updateDataType(encodedTags));
      }

      // If no subject is selected, remove from query
      if (encodedSubjects === "()")
        delete queryObj.fq__datsubjectmasterlevel2_id;

      let query = await queryConstructor(queryObj);
      let data = await authorFinderApi(query);
      dispatch(updateAuthorSearchResults(data));
      // dispatch(updateSearchResults);
      setLoading(false);

      if (data?.grouped?.author_name_lf?.groups.length === 0) {
        setOpenAlert(true);
        setMessage("No result for the given name");
        setFirstName("");
        setLastName("");
      } else if (data?.grouped?.author_name_lf?.groups.length > 0) {
        // if (data?.docs) {
        navigate(
          `/authorlist?searchterm=${fullName}&firstname=${valueFirstName}&lastname=${valueLastName}&dataType=${encodedTags}&subjectType=${encodedSubjects}`
        );
      }
    }
  }

  // useEffect(() => {
  //   clearAllFilter()
  // }, [])

  // function clearAllFilter() {
  //   dispatch(clearAllFilters());
  //   dispatch(clearFilterState());
  // }

  const handlePreference = (event: React.ChangeEvent<HTMLInputElement>) => {
    logBasicSearchUsageData(112);
    if (isProfileUser) {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAdded(myPreference);
      }

      if (!isMyPrefAdded && event.target.checked) {
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      }
      if (!event.target.checked) {
        dispatch(clearDataTypeFilters());
        // On dissabling the preference select all doc types
        let allDocIds: any = customerData?.datatypes
          .filter((x) => x.dat_resourcetype_id !== 2)
          ?.map((obj) => obj?.dat_resourcetype_id);
        setSelectedTags(() => [...allDocIds, 0]);
      }

      setIsPreference(event.target.checked);
      // dispatch(updatePreferenceState(isPreference));
      sessionStorage.setItem("preference", event.target.checked.toString());
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  };

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);
    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }
  const [authorFName, setAuthorFName] = useState<any[]>([]);
  const [authorLName, setAuthorLName] = useState<any[]>([]);

  const handleSuggestionLastName = (event: any, newValueLName: any) => {
    let dataTypeValue: any = [];

    dispatch(updateDataType(selectedTags));

    if (searchReducer.dataType.includes(0)) {
      dataTypeValue = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";
    } else {
      dataTypeValue = searchReducer.dataType;
    }

    dispatch(updateAuthorRow(15));
    dispatch(updateAuthorPage(1));

    let encodedSubjects: string = "";
    if (selectedSubjects.length === 0) {
      encodedSubjects = `(${temporaryQueryConstructor(
        subjectData?.map((x) => x.datsubjectmasterlevel2_id)
      )})`;
    } else {
      encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
    }

    let encodedTags = "";
    let tags = [...selectedTags];
    if (tags.includes(1)) tags.push(2);
    if (tags.includes(1) && !tags.includes(5)) tags.push(5);
    if (tags.includes(1) && !tags.includes(24)) tags.push(24);
    if (!tags.includes(1) && tags.includes(2))
      tags = tags.filter((tag) => tag !== 2);
    if (!tags.includes(1) && tags.includes(5))
      tags = tags.filter((tag) => tag !== 5);
    if (!tags.includes(1) && tags.includes(24))
      tags = tags.filter((tag) => tag !== 24);

    // if (tags.length == 8 && tags.includes(0)) {
    if (tags.length == 8 && tags.includes(0)) {
      encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }

    if (tags.length === 0) encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";

    dispatch(updateAuthorSubjectType(encodedSubjects));
    dispatch(updateDataType(encodedTags));
    if (newValueLName.length < 1) {
      setValueLastName("");
      return;
    }

    setValueLastName(newValueLName);

    const loadUsersLastName = async () => {
      const facet_prefix = newValueLName;
      const apiResponse = await getAuthorSuggestionList(
        facet_prefix,
        encodedSubjects,
        dataTypeValue
      );
      let list: any = [];
      apiResponse?.data?.data?.grouped?.author_lastname?.groups?.map(
        // (ele: any) => list.push(Object.keys(ele)[0])
        (ele: any) => list.push(ele?.groupValue)
      );
      setAuthorLName(list);
    };
    loadUsersLastName();
  };

  const handleSuggestionFirstName = (event: any, newValueFName: any) => {
    let dataTypeValue: any = [];

    dispatch(updateDataType(selectedTags));

    if (searchReducer.dataType.includes(0)) {
      dataTypeValue = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";
    } else {
      dataTypeValue = searchReducer.dataType;
    }

    dispatch(updateAuthorRow(15));
    dispatch(updateAuthorPage(1));

    let encodedSubjects: string = "";
    if (selectedSubjects.length === 0) {
      encodedSubjects = `(${temporaryQueryConstructor(
        subjectData?.map((x) => x.datsubjectmasterlevel2_id)
      )})`;
    } else {
      encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
    }

    let encodedTags = "";
    let tags = [...selectedTags];
    if (tags.includes(1)) tags.push(2);
    if (tags.includes(1) && !tags.includes(5)) tags.push(5);
    if (tags.includes(1) && !tags.includes(24)) tags.push(24);
    if (!tags.includes(1) && tags.includes(2))
      tags = tags.filter((tag) => tag !== 2);
    if (!tags.includes(1) && tags.includes(5))
      tags = tags.filter((tag) => tag !== 5);
    if (!tags.includes(1) && tags.includes(24))
      tags = tags.filter((tag) => tag !== 24);

    // if (tags.length == 8 && tags.includes(0)) {
    if (tags.length == 8 && tags.includes(0)) {
      encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }

    if (tags.length === 0) encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";

    dispatch(updateAuthorSubjectType(encodedSubjects));
    dispatch(updateDataType(encodedTags));
    if (newValueFName.length < 1) {
      setValueFirstName("");
      return;
    }

    setValueFirstName(newValueFName);

    const loadUsersFirstName = async () => {
      const facet_prefix = newValueFName;
      const apiResponse = await getAuthorSuggestionListFirstName(
        facet_prefix,
        encodedSubjects,
        dataTypeValue
      );

      let list: any = [];
      apiResponse?.data?.data?.grouped?.author_firstname?.groups?.map(
        // (ele: any) => list.push(Object.keys(ele)[0])
        (ele: any) => ele?.groupValue !== null && list.push(ele?.groupValue)
      );
      setAuthorFName(list);
    };

    loadUsersFirstName();
  };

  function openPreferenceInfoModal() {
    dispatch(
      setModal({
        modalType: "PREFERENCE_INFO_MODEL",
        modalProps: {
          isOpen: true,
        },
      })
    );
  }
  return (
    <>
      <Box className="search-container">
        <Box>
          <AuthorFinderHeader />
        </Box>
        {message && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={openAlert}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
          </Stack>
        )}

        <Box sx={{ p: "0 66px" }}>
          <Stack
            direction={"row"}
            spacing={10}
            alignItems="center"
            paddingTop={{ xs: "2rem" }}
          >
            <Text variant="h5" fontFamily={"Helvetica Neue"} fontWeight={"600"}>
              Author Finder
            </Text>
            <Stack direction={"row"} spacing={1} alignItems="center">
              <Text fontFamily={"Inter"} fontWeight="500">
                Preferences
              </Text>
              <Switch
                sx={{ m: 1 }}
                checked={isPreference}
                onChange={handlePreference}
                inputProps={{ "aria-label": "Preferences Switch" }}
              />
              <Stack>
                <IconButton size="medium" onClick={openPreferenceInfoModal}>
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>

          {/* Tags */}

          <Stack direction={"row"} spacing={1.2} marginTop={2} flexWrap="wrap">
            {allTags?.map((data) => (
              <Chip
                key={data.dat_resourcetype_id}
                label={
                  data.accesstype === dataTypes.journals
                    ? dataTypes.journalArticle
                    : data?.accesstype
                }
                color="primary"
                variant={"outlined"}
                onClick={() => handleTags(data.dat_resourcetype_id)}
                sx={{
                  p: "4px",
                  borderRadius: "6px",
                  border: "1px solid",
                  borderColor: selectedTags.includes(data.dat_resourcetype_id)
                    ? "primary.main"
                    : "#DCD2EF",
                  background: selectedTags.includes(data.dat_resourcetype_id)
                    ? "#DCD2EF"
                    : "#FFF",
                  color: selectedTags.includes(data.dat_resourcetype_id)
                    ? "primary.main"
                    : "#71717A",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#DCD2EF",
                  },
                }}
              />
            ))}
          </Stack>

          {/* Search */}
          <Stack
            direction={"row"}
            marginTop={2}
            alignItems="flex-end"
            mb={3}
            sx={{
              flexDirection: { xs: "column", sm: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "flex-end" },
              gap: 2,
            }}
          >
            <Box>
              <Text fontWeight="600" fontSize={16} textAlign="left">
                Last Name
              </Text>

              <Autocomplete
                sx={{ width: "352px", backgroundColor: "#FFF" }}
                value={valueLastName}
                onInputChange={handleSuggestionLastName}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={authorLName}
                onChange={(event1, newValue1) => {
                  setLastName(newValue1);
                }}
                // options={top100Films?.map((option) => option.title)}
                PaperComponent={({ children }) => (
                  <Paper
                    className="author-name-xya"
                    elevation={8}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    value={lastName}
                    {...params}
                    placeholder="Author Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={{ width: "352px", backgroundColor: "#FFF" }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    onChange={(e: any) => setLastName(e.target.value)}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        if (!!selectedTags.length) {
                          handleAuthorFinderSearch();
                        }
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Text fontWeight="600" fontSize={16} textAlign="left">
                First Name
              </Text>

              <Autocomplete
                sx={{ width: "352px", backgroundColor: "#FFF" }}
                value={valueFirstName}
                onInputChange={handleSuggestionFirstName}
                onChange={(event2, newValue2) => {
                  setFirstName(newValue2);
                }}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={authorFName}
                PaperComponent={({ children }) => (
                  <Paper
                    className="author-name-xya"
                    elevation={8}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    value={firstName}
                    {...params}
                    placeholder="Author First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={{ width: "352px", backgroundColor: "#FFF" }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    onChange={(e: any) => setFirstName(e.target.value)}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        if (!!selectedTags.length) {
                          handleAuthorFinderSearch();
                        }
                      }
                    }}
                  />
                )}
              />
            </Box>

            <Box>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{
                  height: "56px",
                  width: "130px",
                  marginBottom: { xs: "32px", sm: 0 },
                }}
                onClick={handleAuthorFinderSearch}
                // disabled={!selectedSubjects.length || !selectedTags.length}
                disabled={!selectedTags.length}
              >
                {loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#fff",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                ) : (
                  "Search"
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default AuthorFinder;
