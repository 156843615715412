import { useAppSelector } from "../../store/hooks";
import { notify } from "../../utils/Notify";
import http from "../httpService";

let session_key = sessionStorage.getItem("session_key");

export const getAllJournals = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string,
  enableSearch: string,
  collectionData: string
) => {
  let params: any = {
    profileuserid: userId,
    page: page,
    sort: "resource_name_tk asc",
    rows: pageSize,
    fq__fulltext_ACL: collectionData,
  };

  if (enableSearch !== "") {
    params = {
      ...params,
      resource_name_fz: `"${search}"`,
      resource_shortname_fz: `OR(${search})`,
    };
  }
  return http.get("/api/indexing/solr/resourcecore", {
    params,
  });
};

export const getAllBooks = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string,
  enableSearch: string,
  collectionData: string,
  subscribedSubjects: any
) => {
  let session_key = sessionStorage.getItem("session_key");
  let params: any = {
    profileuserid: userId,
    page: page,
    sort: "book_name asc",
    fq__subjects_id_l2: `(${subscribedSubjects.join(" OR ")})`,
    rows: pageSize,
    // fq__fulltext_ACL:collectionData,
    sessionid: session_key,
    facet_fields:
      "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,book_name_initial",
    // facet_fields: "publisher_name,subjects_name_l3,resource_name_initial",

    fq__active_flag: true,
    fq__resource_type: 12,
  };

  if (enableSearch !== "") {
    params = {
      ...params,
      fq__book_name_tk: `"${search}"`,
    };
  }
  let apiBody = params + collectionData;
  return http.get("/api/indexing/solr/bookscore", {
    params,
  });
};

export const getAllManageResources = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string,
  enableSearch: string
) => {
  let params: any = {
    fq__profileuser_id: userId,
    fq__active_flag: true,
    // op_mode: "and",
    page: page,
    sort: "resource_name_tk asc",
    rows: pageSize,
  };

  if (enableSearch !== "") {
    params = {
      ...params,
      resource_name_fz: `"${search}"`,
      resource_shortname_fz: `OR(${search})`,
    };
  }

  return http.get("/api/indexing/solr/personalresourcecore", {
    params,
  });
};

export const getAllBookManageResources = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string,
  enableSearch: string
) => {
  let params: any = {
    fq__profileuser_id: userId,
    fq__active_flag: true,
    // op_mode: "and",
    page: page,
    rows: pageSize,
    sort: "book_name asc",
    // fq__subjects_id_l2: `(35 OR 34 OR 30 OR 21 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 31 OR 36 OR 38 OR 40 OR 28 OR 24 OR 25 OR 32 OR 29 )`,

    // fq__fulltext_ACL:collectionData,
    sessionid: session_key,
    facet_fields:
      "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,book_name_initial",
    // facet_fields: "publisher_name,subjects_name_l3,resource_name_initial",

    fq__resource_type: 12,
  };

  if (enableSearch !== "") {
    params = {
      ...params,
      resource_name_fz: `"${search}"`,
      resource_shortname_fz: `OR(${search})`,
    };
  }

  return http.get("/api/indexing/solr/personalbookscore", {
    params,
  });
};

export const getAlertSettings = async (
  userId: string,
  // resourcemaster_ids: string[]
  resourceMstarIds: any
) =>
  http.get(
    "/api/check_resource_alert_setting_status/check_resource_alert_setting_status",
    {
      params: {
        usermaster_id: userId,
        resourcemaster_ids: resourceMstarIds.join(","),
      },
    }
  );

export const getBookAlertSettings = async (
  userId: string,
  // resourcemaster_ids: string[]
  resourceMstarIds: any
) =>
  http.get(
    "/api/check_sub_resource_alert_status/check_sub_resource_alert_status",
    {
      params: {
        usermaster_id: userId,
        resourcemaster_ids: resourceMstarIds.join(","),
      },
    }
  );

export const getAlertStatus = async (
  userId: string,
  resourcemaster_id: string
) =>
  http.get("/api/get_resource_alert_status/get_resource_alert_status", {
    params: {
      usermaster_id: userId,
      resourcemaster_id: resourcemaster_id,
    },
  });

export const getMyLibResources = async (userId: string) =>
  http.get(
    "/api/get_personal_library_resource_list/get_personal_library_resource_list",
    {
      params: {
        usermaster_id: userId,
      },
    }
  );

export const updateFavorites = async (obj: { formData: FormData }) => {
  try {
    const res = await http.post(
      "/api/mark_resources_favorite/mark_resources_favorite",
      obj?.formData
    );
    console.log(res.data.data[0][0].message);
    if (res.data.data[0][0].message === "limit count exceed") {
      notify("error", res.data.data[0][0].message);
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateBooksFavorites = async (obj: { formData: FormData }) => {
  console.log("triggered");
  try {
    const res = await http.post(
      "/api/mark_sub_resources_favorite/mark_sub_resources_favorite",
      obj?.formData
    );
    console.log(res.data.data[0][0].message);
    if (res.data.data[0][0].message === "limit count exceed") {
      notify("error", res.data.data[0][0].message);
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateRSS = async (obj: { formData: FormData }) =>
  http.post(
    "/api/set_alert_for_resource/set_alert_for_resource",
    obj?.formData
  );
