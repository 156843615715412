import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import Mark from "mark.js";
import { MathJax } from "better-react-mathjax";

import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  Stack,
  Tooltip,
  Checkbox,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { Colors, dataTypes, authorRole } from "../../utils/constants";

import RssFeedIcon from "@mui/icons-material/RssFeed";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from "@mui/material/IconButton";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ScrollToTop from "../../hooks/scrollToTop";

import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import RightDrawer from "../../components/rightDrawer/rightDrawer";
import CardItem from "../../components/card";
import Pagination from "../../components/pagination";
import AuthorFinderTopbar from "./../../components/authorFinderTopbar/AuthorFinderTopbar";
import AnalyticsDropdown from "../../components/basicSearchTopBar/AnalyticsDropdown";
import Spinner from "../../components/spinner";

import {
  accessTypeButtons,
  getParamsByName,
  goToTop,
  isStringOfArray,
  showIsProfileToast,
  sortAuthor,
  sortAuthorDetail,
} from "../../utils/helper";

import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updataLibraryOpac,
  updateAllFilter,
  updateConfrence,
  updateConsortiaCollection,
  updateDateFrom,
  updateEarlyOnline,
  updateJgateCollection,
  updateMonthFrom,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
  updatePublisher,
  updateResearch,
  updateResourceId,
  updateSpeaker,
  updateUniversityName,
  updateYearFrom,
} from "../../store/slice/filterSlice";
import {
  clearAuthorSearchTerm,
  updateAuthorFullText,
  updateAuthorPage,
  updateAuthorRow,
  updateAuthorSearchTerm,
  updateIsAuthorAnalytics,
  updateAuthorDataType,
  updateAuthorSubjectType,
  updateIsAuthorSettled,
  updateButtonStatus,
  updateIsAuthorResult,
  updateTempTags,
  updateNoResult,
} from "../../store/slice/authorFinderSlice";

import { authorSearchForCounts } from "../../api/authorFinderApi";

import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";

// import onAuthorResult from "./onAuthorResult";

import IdIcon from "../../assets/img/id.png";
import DatasetIcon from "../../assets/img/database.svg";
import RetractedIcon from "../../assets/img/Retracted.svg";
import FindInLibraryIcon from "../../assets/img/findinlib.svg";
import PreprintIcon from "../../assets/img/preprint.svg";
import {
  updateMarkedArticleCount,
  updateMarkedArticleId,
  updateMarkedResult,
} from "../../store/slice/searchSlice";
import AuthorLeftbarFilters from "./AuthorLeftbarFilters";
import SortByRelevenceAuthor from "../../components/authorFinderTopbar/sortByRelevenceAuthor";
import RSSAlertSearch from "../../components/resultsPopups/RSSAlertSearch";
import { personalCoreCount } from "../../api/Search/CollectionApi";
// import search from "../AuthorFinder/onAuthorResult";
import metaData from "../../utils/metadata";
import AccessLock from "../basicSearchScreen/AccessLock";
import NoResultModal from "../../components/modals/NoResultFound";
import { updateBrowserPage } from "../../store/slice/browseJournal";
import LibraryCatalogueCard from "../../components/libraryCatalogue/Card";
import { notify } from "../../utils/Notify";
import { journalLock } from "../../api/browseJournal";
import objectToQueryString from "../browseJournal/objToQuery";
import { basicSearchAuthorEmail } from "../../api/Search/BasicSearch.api";
import { usagelogApi } from "../../api/usageReportApi";

type StateType = {
  state: {
    searchTerm: string;
    fullTextQuery: any;
    allQuery: any;
    // markInstance: Mark;
    allCount: string | number;
    fullCount: string | number;
    noRerender?: boolean;
  };
};

const AuthorFinderSearch: React.FC = () => {
  const [top, setTop] = useState(false);

  const { state }: StateType = useLocation();

  const allCount = typeof state?.allCount === "number" ? state?.allCount : 0;
  const fullCount = typeof state?.fullCount === "number" ? state?.fullCount : 0;

  const [textCount, setTextCount] = useState({
    all: allCount,
    fulltext: fullCount,
  });

  const markedArticlesid = useAppSelector(
    (state) => state.searchReducer.markedArticleId
  );

  const [markResult, setMarkResult] = useState(false);
  const [markedData, setMarkdeData] = useState<string[]>(markedArticlesid);
  const [noresultModal, setNoResultModal] = useState(false);
  const [coAuthorsList, setCoAuthorsList] = useState([]);

  const linkToDisplay = useAppSelector(
    (state) => state.authorReducer.linkToDisplay
  );

  const [dialogueData, setDialogueData] = useState({});
  const [authorEmailData, setAuthorEmailData] = useState([]);
  const isMounted = useRef(false);
  const triggerEvent = useRef(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const allAppliedQueries = useAppSelector(
    (state) => state.appliedQueries.appliedQueries
  );

  let customerId: any =
    useAppSelector((state) => state?.login?.informaticscustomer_id) ||
    sessionStorage.getItem("informaticscustomer_id");

  const authorReducer = useSelector((state: any) => state.authorReducer);

  const filterReducer = useAppSelector((state) => state.filterReducer);

  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);

  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  const markedArticleCount = useAppSelector(
    (state) => state.searchReducer.markedArticleCount
  );

  // const isSettled = useAppSelector((state) => state.searchReducer.isSettled);
  const isSettled = useAppSelector((state) => state.authorReducer.isSettled);

  let searchResults: any = useAppSelector(
    (state) => state.authorReducer.authorsearchResult
  );

  let currentPage = useAppSelector((state) => state?.authorReducer?.page);

  const subjectType = useAppSelector(
    (state) => state.searchReducer.subjectType
  );

  const rows = useAppSelector((state) => state.authorReducer.rows);
  const fulltext = useAppSelector((state) => state.authorReducer.fullText);
  const isLoading = useAppSelector((state) => state.filterReducer.loading);
  const searchTerm = useAppSelector((state) => state.authorReducer.searchTerm);

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const dataTypeParam = getParamsByName("dataType");
  const subjectTypeParam = getParamsByName("subjectType");

  const tags: any = getParamsByName("tags");
  const tagsArray = tags?.split(",");

  //Full Text and All Implementation

  const queryCount = useAppSelector((state) => state.queryForCount);

  const showAlertData = useAppSelector(
    (state) => state.searchReducer?.alertSettingData
  );

  const profileStatus = sessionStorage.getItem("isProfileUser");

  const alertTypeSetting = useAppSelector(
    (state) => state.authorReducer.alertSettingData
  );

  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const currentYear: number = new Date().getFullYear();

  //To get count of FullText and All - Will run on page refresh
  useEffect(() => {
    const getData = async () => {
      dispatch(updateTempTags(tagsArray));

      // dispatch(search());

      try {
        // dispatch(updateLoadingState(true));
        const isPersonalCore = filterReducer.myPersonalLibCollection;
        const full = queryCount.fullTextQuery;
        const all = queryCount.allTextQuery;

        if (full && all && !isPersonalCore) {
          const allText = await authorSearchForCounts(all);
          const fulltext = await authorSearchForCounts(full);
          setTextCount({
            all: allText.hits,
            fulltext: fulltext.hits,
          });
          if (fulltext?.hits == 0 && allText?.hits > 0) {
            dispatch(updateAuthorFullText(false));
            dispatch(search());
          }
          if (fulltext?.hits === 0 && allText?.hits === 0) {
            setNoResultModal(true);
          }
        } else if (isPersonalCore && full && all) {
          const allText = await personalCoreCount(all);
          const fulltext = await personalCoreCount(full);

          setTextCount({
            all: allText?.hits,
            fulltext: fulltext?.hits,
          });
          if (fulltext?.hits == 0) {
            dispatch(updateAuthorFullText(false));
            dispatch(search());
          }
          if (fulltext?.hits === 0 && allText?.hits === 0) {
            setNoResultModal(true);
          }
        }
        // dispatch(updateLoadingState(false));
      } catch (err) {
        console.log(err);
      }
    };

    if (state?.noRerender) return;

    getData();
  }, [queryCount.allTextQuery, queryCount.fullTextQuery]);

  const handleChange = async (
    _event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value === "fulltext") {
      if (textCount.fulltext == 0) setNoResultModal(true);
      dispatch(updateAuthorFullText(true));
      dispatch(updateAuthorPage(1));
      dispatch(updataLibraryOpac(false));
      logAuthorResultUsageData(29);
    } else if (value === "all") {
      if (textCount.all == 0) setNoResultModal(true);
      dispatch(updateAuthorFullText(false));
      dispatch(updateAuthorPage(1));
      dispatch(updataLibraryOpac(false));
      logAuthorResultUsageData(28);
    } else if (value === "opac") {
      dispatch(updateAuthorFullText(false));
      dispatch(updataLibraryOpac(true));
    }
    await dispatch(search());
    dispatch(updateIsAuthorSettled(true));
    // dispatch(updateIsSettled(true))
  };

  useEffect(() => {
    isSettled && isArticleBookmarked();
  }, [isSettled]);

  useEffect(() => {
    if (triggerEvent.current) {
      onPageChange();
    }
  }, [triggerEvent.current]);

  useEffect(() => {
    if (isMounted.current) {
      onPageChange();
    }
  }, [currentPage, rows]);

  useEffect(() => {
    onload();
  }, [subjectType.length]);

  async function onPageChange(fulltext = false) {
    if (filterReducer.libraryOpac) return;

    dispatch(search());

    goToTop();
  }
  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logAuthorResultUsageData = (usageActionID: any, data?: any) => {
    const articleArticle_Id = data?.article_id ? data?.article_id : null;
    const articleResourcemaster_id = data?.resourcemaster_id
      ? data?.resourcemaster_id
      : null;
    const articleResourceissue_id = data?.resourceissue_id
      ? data?.resourceissue_id
      : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      null,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  // Pagination
  let count = Math.ceil(searchResults?.hits / rows);

  async function handlePageChange(p: number) {
    dispatch(updateAuthorPage(p));
    setMarkResult(false);
    // setPage(p);
    isMounted.current = true;
    goToTop();
    await dispatch(search());
    // dispatch(updateIsSettled(true))
    dispatch(updateIsAuthorSettled(true));
  }

  async function getRowsPerPage(value: number) {
    let count = Math.ceil(searchResults?.hits / value);
    isMounted.current = true;
    dispatch(updateAuthorRow(value));
    //dispatch(updateAuthorPage(1));
    if (currentPage >= count) {
      dispatch(updateAuthorPage(count));
    }
    await dispatch(search());
    goToTop();
    // setRow(value);
  }

  const isAuthenticated = useAppSelector(
    (state) => state.login.informaticscustomer_id
  );

  //Marked Result Implementation
  useEffect(() => {
    markResultStatus();
  }, [markedData?.length, isSettled]);

  function markResultStatus() {
    try {
      if (searchResults?.docs?.length === 0) return;
      let dataArray = searchResults?.docs;

      if (!Array.isArray(dataArray)) return;

      let articlesIds = dataArray?.map((obj) => obj?.article_id);
      let allItemsPresent = articlesIds.every((id) =>
        markedArticlesid.includes(id)
      );
      if (
        markedData?.length === searchResults?.docs?.length &&
        allItemsPresent
      ) {
        setMarkResult(true);
      } else {
        setMarkResult(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getMarkedResult(res: any, id: string) {
    let data = structuredClone({
      ...res,
      searchTerm: searchTerm.toString(),
    });
    // Handle id
    if (markedData.includes(id)) {
      let filterIds = markedArticlesid.filter((data) => data !== id);
      let filterIcCount = markedArticleCount.filter((data) => data !== id);
      let filterData = markedResult.filter(
        (data: any) => data?.article_id !== id
      );
      dispatch(updateMarkedResult(filterData));
      dispatch(updateMarkedArticleId(filterIds));

      dispatch(updateMarkedArticleCount(filterIcCount));
      setMarkdeData((prev) => prev.filter((obj) => obj !== id));
    } else if (markedData?.length) {
      let articlesIds = [...markedArticlesid, id];
      let markedResultData = [...markedResult, data];
      let idCount = [...markedArticleCount, id];

      dispatch(updateMarkedResult(markedResultData));
      dispatch(updateMarkedArticleId(articlesIds));
      dispatch(updateMarkedArticleCount(idCount));

      setMarkdeData((prev) => [...prev, id]);
    } else {
      if (!markedArticlesid.length) {
        dispatch(updateMarkedArticleId([id]));
        dispatch(updateMarkedResult([data]));
        dispatch(updateMarkedArticleCount([id]));
      } else {
        let articlesIds = [...markedArticlesid, id];
        let markedResultData = [...markedResult, data];
        dispatch(updateMarkedResult(markedResultData));
        dispatch(updateMarkedArticleId(articlesIds));
        dispatch(updateMarkedArticleCount([id]));
      }

      setMarkdeData([id]);
    }
  }

  function selectAllArticles(e: any) {
    try {
      let dataArray = searchResults?.docs;
      dataArray = dataArray?.map((x: any) =>
        structuredClone({ ...x, searchTerm: searchTerm })
      );

      if (!Array.isArray(dataArray)) return;

      if (e?.target?.checked) {
        let filteredMarkedIds = [];
        let filteredMarkedData = [];
        let articlesIds = dataArray?.map((obj) => obj?.article_id);

        for (let id of articlesIds) {
          if (!markedArticlesid.includes(id)) filteredMarkedIds.push(id);
        }

        for (let obj of dataArray) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }

        dispatch(updateMarkedResult([...markedResult, ...filteredMarkedData]));
        dispatch(
          updateMarkedArticleId([...markedArticlesid, ...filteredMarkedIds])
        );
        dispatch(
          updateMarkedArticleCount([...markedArticlesid, ...filteredMarkedIds])
        );
        setMarkdeData(articlesIds);
      } else {
        setMarkdeData([]);
        // curr res ids
        let articlesIds = dataArray?.map((obj) => obj?.article_id);

        // check the current res id in marked articls ids and remove all
        let filteredMarkedIds = [];
        let filteredMarkedData = [];

        for (let id of markedArticlesid) {
          if (!articlesIds.includes(id)) filteredMarkedIds.push(id);
        }

        dispatch(updateMarkedArticleId(filteredMarkedIds));
        dispatch(updateMarkedArticleCount(filteredMarkedIds));

        // keep the data in mark result that matches  "filteredMarkedIds"
        for (let obj of markedResult) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }

        dispatch(updateMarkedResult(filteredMarkedData));
      }
    } catch (err) {
      console.log(err);
    }
  }

  const setCoAuthorsListCall = () => {
    let tempSearchterm: any[] = authorReducer.searchTerm;
    const authorList: any = [];
    const lowercaseWords = tempSearchterm?.map((word: any) =>
      word.toLowerCase()
    );
    lowercaseWords?.map((element: any, index: number) => {
      let key = index + 1;
      authorList[index] = {
        val: element,
        count: key,
      };
    });
    setCoAuthorsList(
      searchResults?.jsonfacets?.authors_tk?.buckets.filter(function (cv: any) {
        return !authorList?.find(function (e: any) {
          return e.val == cv.val;
        });
      })
    );
  };

  useEffect(() => {
    dispatch(updateIsAuthorAnalytics(true));
    dispatch(updateIsAuthorResult(true));
    setCoAuthorsListCall();
    return () => {
      console.log("click");
      dispatch(updateIsAuthorAnalytics(false));
      // dispatch(clearAllFilters());
      // dispatch(updateIsAuthorResult(false));
    };
  }, []);

  useEffect(() => {
    dispatch(updateButtonStatus(true));
    // dispatch(search())

    return () => {
      dispatch(updateButtonStatus(false));
      // dispatch(search())
    };
  }, []);

  // Function to trigger api after refresh - if data is empty in redux
  function onload() {
    if (searchResults?.docs) return;
    if (!subjectType.length) return;

    if (Object.keys(authorReducer.authorsearchResult).length === 0) {
      let searchTerm: any = getParamsByName("searchterm");
      let array = searchTerm?.split("OR");

      dispatch(updateAuthorDataType(dataTypeParam));
      dispatch(updateAuthorSubjectType(subjectTypeParam));

      dispatch(updateAuthorSearchTerm(array));

      // dispatch(search())
      triggerEvent.current = triggerEvent.current + 1;
    }
  }

  function handleAuthor(authorName: string, data: any) {
    dispatch(clearAuthorSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(clearAllFiltersValue());

    if (
      data.data_type === dataTypes.journalArticle ||
      data.data_type === dataTypes.journals ||
      data.data_type === dataTypes.conferenceProceedings ||
      data.data_type === dataTypes.magazine ||
      data.data_type === dataTypes.preprint ||
      data.data_type === dataTypes.bookSeries
    ) {
      logAuthorResultUsageData(19, data);
    }

    if (data.data_type === dataTypes.dissertationThesis) {
      logAuthorResultUsageData(19, data);
      logAuthorResultUsageData(20, data);
    }

    if (data.data_type === dataTypes.audioVideo) {
      logAuthorResultUsageData(22, data);
    }

    if (data?.authors?.length) {
      dispatch(updateAuthorSearchTerm([authorName]));

      let appliedFilter = {
        key: "author",
        value: authorName,
      };
      dispatch(updateAllFilter(appliedFilter));
      // dispatch(updateAuthor([authorName]));
    } else if (data?.speakers?.length) {
      dispatch(updateSpeaker([authorName]));
      let appliedFilter = {
        key: "speaker",
        value: authorName,
      };
      dispatch(updateAllFilter(appliedFilter));
    }
    // dispatch(updatePage(1));
    // dispatch(updateRow(15));
    else if (data?.guide_name === authorName) {
      dispatch(updateResearch([authorName]));
      dispatch(
        updateAllFilter({
          key: "research",
          value: authorName,
        })
      );
    }
    dispatch(updateAuthorPage(1));
    dispatch(updateAuthorRow(15));
    dispatch(search());

    // triggerEvent.current = triggerEvent.current + 1;
  }

  async function handleResource(data: any, i: number) {
    // Make new search, claar all filters
    // dispatch(clearAuthorSearchTerm());

    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(clearAllFiltersValue());
    if (
      data?.data_type === dataTypes.journalArticle ||
      data?.data_type === dataTypes.journals
    ) {
      const item = data;
      // Get acces lock type
      let accessLockType;
      if (item?.openaccess_type !== "Open Access" || !item?.openaccess_type) {
        const payloadData = {
          customer_id: customerId,
          resource_id: item?.resourcemaster_id,
          access_type: 1,
        };
        let journalLockRes = await journalLock(
          "?" + objectToQueryString(payloadData)
        );
        accessLockType = Array.isArray(journalLockRes?.data?.data)
          ? journalLockRes?.data?.data[0]
          : {};
      }
      dispatch(updateBrowserPage(1));
      const yearData = Array.isArray(data?.yearfrom)
        ? data?.yearfrom[0]
        : data?.yearfrom;
      if (currentYear === yearData) {
        logAuthorResultUsageData(90, data);
      } else {
        logAuthorResultUsageData(87, data);
      }
      logAuthorResultUsageData(91, data);
      logAuthorResultUsageData(92, data);
      navigate(`/BrowseJournalTableSearch/BrowseJournalSearch`, {
        state: {
          item: item,
          from: "basicSearch",
          alerts: showAlertData[i],
          lock: accessLockType,
        },
      });
    } else if (data?.data_type === dataTypes.conferenceProceedings) {
      dispatch(updateConfrence([data?.conference_name]));
      dispatch(
        updateAllFilter({
          key: "conference_name",
          value: data?.conference_name,
        })
      );
      logAuthorResultUsageData(115, data);
      dispatch(search());
    } else if (data?.data_type === dataTypes.dissertationThesis) {
      dispatch(updateUniversityName([data?.university_name]));
      dispatch(
        updateAllFilter({
          key: "university_name",
          value: data?.university_name,
        })
      );
      logAuthorResultUsageData(23, data);
      dispatch(search());
    } else if (data?.data_type === dataTypes.audioVideo) {
      dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      logAuthorResultUsageData(25, data);
      dispatch(search());
    } else if (data?.data_type === dataTypes.preprint) {
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      logAuthorResultUsageData(24, data);
      dispatch(search());
    } else if (data.data_type === dataTypes.magazine) {
      dispatch(updateResourceId([data?.resourcemaster_id]));

      dispatch(
        updateAllFilter({
          key: "magazine",
          value: data?.resource_name_tk,
        })
      );
      logAuthorResultUsageData(116, data);
    }

    else if(data.data_type === dataTypes.books){
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      dispatch(search());
      // logBasicSearchUsageData(24, data);
        }
    // go to top
    goToTop();
  }

  //Drawer Implementation
  const handleDawer = async (data: any) => {
    setTop(!top);
    setDialogueData(data);
    const authorData = await basicSearchAuthorEmail(data?.article_id);
    setAuthorEmailData(authorData);
    logAuthorResultUsageData(30, data);
  };

  function isArticleBookmarked() {
    try {
      let dataArray = searchResults?.docs;

      if (!Array.isArray(dataArray)) return;

      let articlesIds = dataArray?.map((obj) => obj?.article_id);
      if (articlesIds.length === 0) return;
      let id: string[] = [];

      let currentSearchTerm = markedResult?.filter(
        (obj: any) => obj?.searchTerm === searchTerm.toString()
      );

      for (let str of articlesIds) {
        if (markedArticlesid.includes(str)) id.push(str);
      }

      const payload = [...markedArticleCount, ...id];
      dispatch(updateMarkedArticleCount(payload));

      if (id.length) {
        setMarkdeData(() => id);
      } else if (!currentSearchTerm.length) {
        setMarkdeData([]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [openRSSViewModal, setOpenRSSViewModal] = useState<Boolean>(false);

  const [rssdata, setRssdata] = useState("");

  const handleOpenViewModal = (data: any) => {
    setOpenRSSViewModal(true);

    setRssdata(data);

    console.log("DATA OF DIALOG RSS", rssdata);
  };

  const handleCloseViewModal = () => setOpenRSSViewModal(false);

  function getButtonType(article_id: string) {
    try {
      if (!article_id) return;
      if (!linkToDisplay) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function closeNoResultModal() {
    try {
      setNoResultModal(false);
      let inputField = document.getElementById(
        "search-field"
      ) as HTMLInputElement;
      inputField?.focus();
      inputField?.select();
      if (textCount.fulltext === 0 && textCount.all > 0) {
        dispatch(updateAuthorFullText(false));
        dispatch(search());
      }

      if (textCount.fulltext === 0 && textCount.all === 0) {
        if (
          textCount.fulltext === 0 &&
          textCount.all === 0 &&
          authorReducer.noResult === false
        ) {
          dispatch(updateNoResult(true));
          // if (authorReducer.noResult === true) {
          navigate(-1);
        }

        if (!Array.isArray(allAppliedQueries)) return;
        let lastAppliedQuery = allAppliedQueries[allAppliedQueries.length - 1];

        if (authorReducer.isInitialResult === true) {
          navigate(-1);
        }

        if (lastAppliedQuery.key === "earlyOnline") {
          dispatch(updateNoResult(true));
          dispatch(updateEarlyOnline(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "lastOneWeek") {
          dispatch(updateNoResult(true));
          dispatch(updateDateFrom(""));
          dispatch(search());
        } else if (lastAppliedQuery.key === "lastOneMonth") {
          dispatch(updateNoResult(true));
          dispatch(updateMonthFrom(""));
          dispatch(search());
        } else if (lastAppliedQuery.key === "yearRange") {
          dispatch(updateNoResult(false));
          dispatch(updateYearFrom(""));
          dispatch(search());
        } else if (lastAppliedQuery.key === "jgateCollection") {
          dispatch(updateNoResult(true));
          dispatch(updateJgateCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "consortiaSubscriptions") {
          dispatch(updateNoResult(true));
          dispatch(updateConsortiaCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "myLibraryCollection") {
          dispatch(updateNoResult(true));
          dispatch(updateMyLibraryCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "onosCollection") {
          dispatch(updateNoResult(true));
          dispatch(updateOnosCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "myPersonalLibraryCollection") {
          dispatch(updateNoResult(true));
          dispatch(updatePersonalLibrary(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "opac") {
          dispatch(updateNoResult(true));
          dispatch(updataLibraryOpac(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "currentYear") {
          dispatch(updateNoResult(true));
          dispatch(updateYearFrom(""));
          dispatch(search());
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function toTitleCase(text: string) {
    const words = text?.split(" ");
    const titleCasedWords = words?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titleCasedWords.join(" ");
  }

  function handleAvailableinLib() {
    logAuthorResultUsageData(31);
    notify(
      "info",
      "Please visit the library to obtain the photocopy if required. Contact your library staff for any help."
    );
  }

  // To know if the alert is set to journal already
  function findAlertTypeSetting(
    resourcemaster_id: string | number
  ): boolean | null {
    try {
      if (!resourcemaster_id) return false;
      if (!Array.isArray(alertTypeSetting)) return null;

      const articleData = alertTypeSetting.filter(
        (obj) => obj?.resource_id === resourcemaster_id.toString()
      );
      if (
        articleData[0].alert_type === 1 ||
        articleData[0].alert_type === 2 ||
        articleData[0].alert_type === 3
      ) {
        return true;
      }

      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  const handleAbstractFullScreenClick = (data: any) => {
    navigate("/abstractFullScreen", {
      state: { data, linkToDisplay, searchTerm, resultFrom: "authorFinder" },
    });
    logAuthorResultUsageData(30, data);
  };

  const hnadleDataSet = (data: any) => {
    logAuthorResultUsageData(37, data);
  };


  function handleBoooks(data:any ,i :any){
    navigate(`/BookSearch?searchTerm=${data?.book_name}`)
  };


  return (
    <Box>
      {/* <Nav /> */}
      <AuthorFinderTopbar totalResult={searchResults?.hits} />
      <ScrollToTop />
      <Divider />
      <Box
        sx={{
          backgroundColor: "#F4F5F7",
          pointerEvents: isAuthenticated ? "auto" : "none",
        }}
      >
        <Box sx={{ paddingInline: "66px" }}>
          <Grid container>
            <AuthorLeftbarFilters
              searchResults={searchResults}
              // resultCount={textCount?.all.toLocaleString("en-US")}
              resultCount={
                filterReducer?.libraryOpac
                  ? searchResults?.hits
                  : textCount?.all.toLocaleString("en-US")
              }
            />

            <Grid item xs={9.5} id="main-search-node">
              {/* Header Div */}
              <Box
                sx={[
                  webStyle.flexBox,
                  { marginTop: "1%", height: 35, width: "100%" },
                ]}
              >
                <Box sx={{ display: "flex", width: "50%" }}>
                  {/* <Checkbox {...label}></Checkbox> */}
                  {!isOpac && (
                    <>
                      <Checkbox
                        sx={{ ml: "5px" }}
                        onChange={selectAllArticles}
                        checked={markResult}
                        inputProps={{ "aria-label": "controlled" }}
                      ></Checkbox>
                      <Typography
                        style={{
                          alignSelf: "center",
                          marginRight: "4%",
                          color: Colors.primary,
                          fontSize: "0.875rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!markedArticleCount.length) return;
                          navigate("/marked-result");
                        }}
                      >
                        {markedArticleCount?.length
                          ? `View Marked Results`
                          : "Mark Results"}
                        {markedArticleCount?.length > 0
                          ? "(" + markedArticleCount?.length + ")"
                          : ""}
                      </Typography>
                    </>
                  )}

                  {!isOpac && (
                    <ToggleButtonGroup
                      onChange={handleChange}
                      color="primary"
                      exclusive
                      aria-label="Platform"
                      size="small"
                    >
                      <ToggleButton
                        sx={[
                          fulltext && !isOpac
                            ? webStyle.checkedStyle
                            : webStyle.unCheckedStyle,
                          {
                            ":hover": {
                              background: fulltext ? Colors.primary : "#fff",
                              opacity: 0.85,
                            },
                          },
                        ]}
                        value={"fulltext"}
                      >
                        {/* Full Text{" "}
                      {textCount.fulltext} */}
                        Full Text{" "}
                        {textCount.fulltext >= 0
                          ? "(" +
                            textCount.fulltext.toLocaleString("en-US") +
                            ")"
                          : ""}
                      </ToggleButton>
                      <ToggleButton
                        value="all"
                        sx={[
                          !fulltext && !isOpac
                            ? webStyle.checkedStyle
                            : webStyle.unCheckedStyle,
                          {
                            ":hover": {
                              background: !fulltext ? Colors.primary : "#fff",
                              opacity: 0.85,
                            },
                          },
                        ]}
                      >
                        {/* All {textCount.all} */}
                        All{" "}
                        {textCount.all >= 0
                          ? "(" + textCount.all.toLocaleString("en-US") + ")"
                          : ""}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}

                  {isOpac && (
                    <ToggleButtonGroup
                      onChange={handleChange}
                      color="primary"
                      exclusive
                      aria-label="Platform"
                      size="small"
                    >
                      <ToggleButton
                        value="opac"
                        sx={[
                          isOpac
                            ? webStyle.checkedStyle
                            : webStyle.unCheckedStyle,
                          {
                            ":hover": {
                              background: isOpac ? Colors.primary : "#fff",
                              opacity: 0.85,
                            },
                          },
                        ]}
                      >
                        Library Catalogue{" "}
                        {searchResults?.hits
                          ? "(" +
                            searchResults?.hits.toLocaleString("en-US") +
                            ")"
                          : ""}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Box>
                {!isOpac && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <AnalyticsDropdown
                      facets={searchResults?.jsonfacets}
                      disable={textCount?.all === 0}
                      isAuthorFinder={true}
                      CoAuthorList={coAuthorsList}
                    />
                    <SortByRelevenceAuthor
                      disable={textCount?.all === 0}
                      sortData={
                        searchResults?.jsonfacets?.filter_metrix?.buckets
                      }
                    />
                  </Box>
                )}
              </Box>

              {/* BODY (ARTICLE CARD) */}

              {Array.isArray(searchResults?.docs) && !isOpac ? (
                searchResults?.docs?.map((data: any, i: number) => {
                  return (
                    <Box key={i}>
                      <CardItem
                        data={data}
                        getMarkedResult={getMarkedResult}
                        markedArticlesid={markedData}
                        searchTerm={searchTerm.toString()}
                        resultFrom="authorFinder"
                      >
                        {/***********************************************************************************************************************************************
                          Title Section
                        ***********************************************************************************************************************************************/}

                        <CardContent sx={{ padding: "0px 14px" }}>
                          {data?.title && (
                            <Typography
                              onClick={() =>
                                handleAbstractFullScreenClick(data)
                              }
                              fontWeight={600}
                              fontSize={"1.125rem"}
                              fontFamily={"Lora"}
                              sx={{
                                textAlign: "left",
                                color: "#1A1A1A",
                                cursor: "pointer",
                              }}
                              component={"div"}
                            >
                              <MathJax>
                                <div
                                  id="title"
                                  dangerouslySetInnerHTML={{
                                    __html: data.title,
                                  }}
                                />
                              </MathJax>
                            </Typography>
                          )}
                        </CardContent>

                        {/***********************************************************************************************************************************************
                          Author Details Section
                        ***********************************************************************************************************************************************/}
                        {data?.data_type == dataTypes.journalArticle ||
                        data?.data_type == dataTypes.journals ||
                        data?.data_type === dataTypes.bookSeries ||
                        data?.data_type == dataTypes.dissertationThesis ||
                        data?.data_type == dataTypes.preprint ||
                        data?.data_type == dataTypes.conferenceProceedings ||
                        data?.data_type == dataTypes.magazine || 
                        data?.data_type == dataTypes.books ||
                        isOpac ||
                        data?.data_type == dataTypes.audioVideo ? (
                          <CardContent
                            sx={{ display: "flex", padding: "0 14px" }}
                          >
                            {Array.isArray(data?.data_type === dataTypes.books
                                ? data?.authors_tk
                                : data?.author_details) &&
                              !isStringOfArray(data?.data_type === dataTypes.books
                                ? data?.authors_tk
                                : data?.author_details) &&
                              sortAuthorDetail(data?.data_type === dataTypes.books
                                ? data?.authors_tk
                                : data?.author_details)?.map(
                                (obj: any, i) => {
                                  let name =
                                    obj?.author_fname + " " + obj?.author_lname;
                                  // let reversedName = obj?.author_lname
                                  //   ? obj?.author_lname + ", " + obj?.author_fname
                                  //   : obj?.author_fname;
                                  const firstName = obj?.author_fname
                                    ? obj?.author_fname
                                    : "";
                                  const lastName = obj?.author_lname
                                    ? obj?.author_lname
                                    : "";

                                  const reversedName = lastName
                                    ? lastName + ", " + firstName
                                    : firstName;
                                  const displayName = lastName
                                    ? lastName.replace(/,/g, " ") +
                                      ", " +
                                      firstName.replace(/,/g, " ")
                                    : firstName.replace(/,/g, " ");
                                  if (i > 3) return null;
                                  return (
                                    <Box
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      {i < 3 ? (
                                        <>
                                          <Tooltip
                                            title={
                                              authorRole[obj?.datauthorrole_id]
                                            }
                                          >
                                            <Button
                                              onClick={(e) => {
                                                handleAuthor(
                                                  reversedName,
                                                  data
                                                );
                                              }}
                                              sx={{
                                                //backgroundColor: !authorReducer.searchTerm ? authorReducer.searchTerm?.includes(name) ? '' : '#E4DCFE' : '',
                                                "&:hover": {
                                                  background:
                                                    authorReducer.searchTerm
                                                      ? !authorReducer.searchTerm?.includes(
                                                          reversedName
                                                        )
                                                        ? "transparent"
                                                        : "#E4DCFE"
                                                      : "transparent",
                                                  textDecoration: "underline",
                                                },
                                                background:
                                                  authorReducer.searchTerm
                                                    ? !authorReducer.searchTerm?.includes(
                                                        reversedName
                                                      )
                                                      ? ""
                                                      : "#E4DCFE"
                                                    : "",
                                                fontFamily: "Helvetica Neue",
                                                fontSize: "14px",
                                                textDecoration: "underline",
                                                padding:
                                                  i === 0
                                                    ? "8px 0px 6px 0px"
                                                    : "6px 8px",
                                              }}
                                            >
                                              {/* {name} */}

                                              {displayName}
                                            </Button>
                                          </Tooltip>
                                          {obj?.orc_id ? (
                                            <a
                                              href={`https://orcid.org/${obj?.orc_id}`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                src={IdIcon}
                                                style={{
                                                  paddingLeft: "5px",
                                                  marginTop: "8px",
                                                  paddingRight: "6px",
                                                }}
                                                alt="IdIcon"
                                              />
                                            </a>
                                          ) : null}

                                          <Divider
                                            orientation="vertical"
                                            style={{
                                              height: "60%",
                                              alignSelf: "center",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <Button
                                          sx={{
                                            color: "#6B7280",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => handleDawer(data)}
                                        >
                                          +{data.authors?.length - 3} More
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                }
                              )}

                            {Array.isArray(data?.data_type === dataTypes.books
                                ? data?.authors_tk
                                : data?.author_details) &&
                              isStringOfArray(data?.data_type === dataTypes.books
                                ? data?.authors_tk
                                : data?.author_details) &&
                              sortAuthor(data?.data_type === dataTypes.books
                                ? data?.authors_tk
                                : data?.author_details)?.map(
                                (name: string, i: number) => {
                                  if (i > 3) return null;
                                  return (
                                    <Box
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                    >
                                      {i < 3 ? (
                                        <>
                                          {name?.length ? (
                                            <Button
                                              onClick={() =>
                                                handleAuthor(name, data)
                                              }
                                              sx={{
                                                "&:hover": {
                                                  background:
                                                    authorReducer.searchTerm
                                                      ? !authorReducer.searchTerm?.includes(
                                                          name
                                                        )
                                                        ? "transparent"
                                                        : "#E4DCFE"
                                                      : "transparent",
                                                  textDecoration: "underline",
                                                },
                                                background:
                                                  authorReducer.searchTerm
                                                    ? !authorReducer.searchTerm?.includes(
                                                        name
                                                      )
                                                      ? ""
                                                      : "#E4DCFE"
                                                    : "",
                                                fontFamily: "Helvetica Neue",
                                                fontSize: "14px",
                                                textDecoration: "underline",
                                                padding:
                                                  i === 0
                                                    ? "8px 8px 6px 0px"
                                                    : "6px 8px",
                                              }}
                                            >
                                              {name.length
                                                ? toTitleCase(
                                                    name
                                                      .trim()
                                                      .replace(/^\,+|\,+$/g, "")
                                                      .trim()
                                                  )
                                                : ""}{" "}
                                              {/* {name.length ? name.trim().replace(/^\,+|\,+$/g, '').trim() : ""}{" "} */}
                                              {/* <img src={IdIcon} style={{ paddingLeft: "5px" }} alt="IdIcon" /> */}
                                            </Button>
                                          ) : null}

                                          <Divider
                                            orientation="vertical"
                                            style={{
                                              height: "60%",
                                              alignSelf: "center",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <Button
                                          sx={{
                                            color: "#6B7280",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => handleDawer(data)}
                                        >
                                          {/* +{data.authors?.length - 3} More */}
                                          +
                                          {data.authors
                                            ? data.authors?.length - 3
                                            : data.author_details?.length -
                                              3}{" "}
                                          More
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                }
                              )}
                          </CardContent>
                        ) : null}
              {data?.has_chapter && (
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "4px 14px",
                            }}
                          >
                            {" "}
                            <Typography
                              component={"div"}
                              sx={{
                                textAlign: "left",
                                fontSize: "16px",
                                color: Colors.primary,
                                fontStyle: "italic",
                                cursor: "pointer",
                              }}
                              onClick={() => {handleBoooks(data,i)}}
                            >
                              {data?.book_name}
                            </Typography>
                          </CardContent>
                        )}
                        {/***********************************************************************************************************************************************
                          Journal name, Data, Year  Section
                        ***********************************************************************************************************************************************/}
                        {data?.data_type == dataTypes.journalArticle ||
                        data?.data_type == dataTypes.journals ||
                        data?.data_type === dataTypes.bookSeries ||
                        data?.data_type == dataTypes.dissertationThesis ||
                        data?.data_type == dataTypes.preprint ||
                        data?.data_type == dataTypes.conferenceProceedings ||
                        data?.data_type == dataTypes.magazine ||
                        data?.data_type === dataTypes.books ||
                        data?.data_type == dataTypes.audioVideo ? (
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "4px 14px",
                            }}
                          >
                            {/* {data?.data_type === "Thesis" ||
                              data?.data_type === "Conference proceedings" ? null : (
                              <Box pr={"0.8rem"} >
                                <Tooltip title="Set Alert" onClick={() => handleOpenViewModal(data)}>
                                  <RssFeedIcon fontSize="small"></RssFeedIcon>
                                </Tooltip>
                              </Box>
                            )} */}

                            {data?.data_type === dataTypes.dissertationThesis ||
                            data?.data_type === dataTypes.preprint ||
                            data?.data_type ===
                              dataTypes.conferenceProceedings || data?.data_type === dataTypes.books  ? null : (
                              <Box pr={"0.8rem"}>
                                <Tooltip
                                  title={
                                    findAlertTypeSetting(
                                      data?.resourcemaster_id
                                    )
                                      ? "Modify Alert"
                                      : "Set Alert"
                                  }
                                  onClick={() => {
                                    if (profileStatus === "true") {
                                      handleOpenViewModal(data);
                                    } else {
                                      showIsProfileToast(
                                        "Please login as profile user to set alert"
                                      );
                                    }
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      "&:hover": {
                                        color: "#F48120",
                                      },
                                      color: findAlertTypeSetting(
                                        data?.resourcemaster_id
                                      )
                                        ? "#F48120"
                                        : "",
                                      padding: 0,
                                    }}
                                  >
                                    <RssFeedIcon fontSize="small"></RssFeedIcon>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}

                            <Typography
                              component={"div"}
                              sx={{
                                textAlign: "left",
                                fontSize: "16px",
                                color: Colors.primary,
                                fontStyle: "italic",
                                cursor: "pointer",
                                // paddingLeft: "0.85rem",
                              }}
                              onClick={() => handleResource(data, i)}
                            >
                              {metaData(data)}
                            </Typography>
                          </CardContent>
                        ) : null}

                        {/***********************************************************************************************************************************************
                          Footer Section
                        ***********************************************************************************************************************************************/}
                        <CardActions
                          disableSpacing
                          style={{ justifyContent: "space-between" }}
                          sx={{ padding: "4px 14px" }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Stack direction={"row"} gap={1}>
                              <Button
                                onClick={() => handleDawer(data)}
                                size="small"
                                sx={{
                                  textDecoration: "underline",
                                  color: "#000",
                                }}
                              >
                                Read More
                              </Button>

                              {data?.has_datasets ? (
                                <IconButton
                                  sx={{ padding: 0 }}
                                  href={
                                    data?.dataset_link && data?.dataset_link[0]
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={DatasetIcon}
                                    alt="DatasetIcon"
                                    onClick={() => hnadleDataSet(data)}
                                  />
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {/* is_retracted */}

                              {data?.is_retracted ? (
                                <img src={RetractedIcon} alt="RetractedIcon" />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </Stack>
                          <Box display={"flex"} gap={1}>
                            {accessTypeButtons(data, customerId) !==
                              "GreenLock" &&
                              data?.has_preprint && (
                                <Tooltip title="View Pre-Print">
                                  <Button
                                    href={data?.preprint_link}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    sx={{ minWidth: 0 }}
                                    onClick={() =>
                                      logAuthorResultUsageData(35, data)
                                    }
                                  >
                                    <img
                                      src={PreprintIcon}
                                      alt="PreprintIcon"
                                    />
                                  </Button>
                                </Tooltip>
                              )}

                            {!data?.fulltext &&
                              getButtonType(data?.article_id)?.includes(
                                "request_article"
                              ) && (
                                <Button
                                  variant="outlined"
                                  onClick={async () => {
                                    navigate("DdrScreen", {
                                      state: {
                                        data,
                                        resultFrom: "authorFinder",
                                      },
                                    });
                                  }}
                                >
                                  Request Article
                                </Button>
                              )}

                            {!data?.fulltext &&
                              getButtonType(data?.article_id)?.includes(
                                "available_in_library"
                              ) && (
                                <Button
                                  size="small"
                                  onClick={handleAvailableinLib}
                                  sx={{
                                    textDecoration: "underline",
                                    color: "#000",
                                  }}
                                >
                                  Available in library
                                </Button>
                              )}

                            {getButtonType(data?.article_id)?.includes(
                              "find_in_library"
                            ) &&
                              !data?.fulltext &&
                              (data?.data_type === dataTypes.journalArticle ||
                                data.data_type ===
                                  dataTypes.conferenceProceedings ||
                                data.data_type === dataTypes.journals) && (
                                <Tooltip title="Find in Library">
                                  <Button
                                    sx={{ minWidth: 0 }}
                                    onClick={async () => {
                                      logAuthorResultUsageData(38, data);
                                      navigate("findlibrary", {
                                        state: { data },
                                      });
                                    }}
                                  >
                                    <img
                                      src={FindInLibraryIcon}
                                      alt="FindInLibraryIcon"
                                    />
                                  </Button>
                                </Tooltip>
                              )}
                            <AccessLock
                              data={data}
                              linkToDisplay={linkToDisplay && linkToDisplay}
                              resultFrom={"authorFinder"}
                            />

                            {isOpac && data?.details_url ? (
                              <Button
                                href={data?.details_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Details
                              </Button>
                            ) : null}
                          </Box>
                        </CardActions>
                      </CardItem>
                    </Box>
                  );
                })
              ) : (
                <>
                  {Array.isArray(searchResults?.docs) &&
                    isOpac &&
                    searchResults?.docs?.map((data: any) => (
                      <LibraryCatalogueCard
                        data={data}
                        searchTerm={searchTerm}
                      />
                    ))}
                </>
              )}

              <RSSAlertSearch
                openViewModal={openRSSViewModal}
                handleCloseViewModal={handleCloseViewModal}
                maxWidth={false}
                resourceData={rssdata}
                resourcetype={rssdata}
                alertTypeSetting={alertTypeSetting}
              />

              <NoResultModal
                isOpen={noresultModal}
                handleClose={closeNoResultModal}
              />

              <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                  count={count}
                  handlePageChange={handlePageChange}
                  rowsPerResult={rows}
                  getRowsPerPage={getRowsPerPage}
                  currentPage={currentPage}
                />
                <Spinner isLoading={isLoading} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <IconButton
        aria-label="Scroll to Top"
        sx={{
          position: "fixed",
          bottom: "70px",
          right: "1vw",
          transform: "translateY(-50%)",
          border: "1px solid #D1D5DB",
          backgroundColor: "#FFF",
          color: "primary.main",
          "&:hover": {
            border: "1px solid #D1D5DB",
            backgroundColor: "#FFF",
            color: "primary.main",
            boxShadow: 2,
          },
        }}
        onClick={() => goToTop()}
      >
        <ArrowUpwardIcon />
      </IconButton>

      <RightDrawer
        dialogueData={dialogueData}
        handleDawer={handleDawer}
        state={top}
        linkToDisplay={linkToDisplay}
        authorEmailData={authorEmailData}
        setTop={setTop}
        resultFrom={"authorFinder"}
      />
    </Box>
  );
};

export default AuthorFinderSearch;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "78%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
  checkedStyle: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    minWidth: "100px",
  },
  unCheckedStyle: {
    minWidth: "100px",
    color: "#7852FB",
    backgroundColor: "#FFFFFF",
  },
};
