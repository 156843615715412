import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import {
  Box,
  Button,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Switch } from "../../components/Switch";
import Pagination from "../../components/pagination";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import Checkbox from "@mui/material/Checkbox";
// import Pagination from '@mui/material/Pagination';
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  AuthorFinderObj,
  ReducerObj,
  SaverArticlesType,
  SearchResultType,
} from "../../types/interface";
import Filter from "../../components/authorFilter/Filter";
import { queryConstructor } from "../../api/formQuery";
import { basicSearchMain } from "../../api/Search/BasicSearch.api";
import {
  getParamsByName,
  goToTop,
  isPreferenceAdded,
  showIsProfileToast,
} from "./../../utils/helper";
import {
  clearAuthorSearchTerm,
  removeAuthorSearchTerm,
  updateAuthorPage,
  updateAuthorSearchResults,
  updateAuthorSearchTerm,
  updateAuthorRow,
  updateAuthorFullText,
  updateAuthorDataType,
  updateAuthorSubjectType,
  updateAuthorFirstName,
  updateAuthorLastName,
  updateAuthorSort,
  updateAuthorSelectedTag,
  updateAbastractAuthor,
  updateAuthorInitial,
  updateAuthorSavedArticles,
  updateTempSearchTerm,
  updateIsAuthorResult,
  updateIsInitialResult,
  updateAuthorAlertSettingData,
  updateAuthorLinkToDisplay,
} from "../../store/slice/authorFinderSlice";

import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";

// import search from "../AuthorFinder/onAuthorSearch";
import { useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  authorFinderApi,
  authorSearchForCounts,
} from "../../api/authorFinderApi";
import { updateLoadingState } from "../../store/slice/filterSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import onPageData from "./onPageData";
import Alert from "@mui/material/Alert";

import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import AlphabeticalFilterForAuthor from "../../components/alphabeticalFilter/AlphabeticalFilterForAuthor";
import { setModal } from "../../store/slice/modalSlice";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { getAlertSettings } from "../../api/MyLibrary/TOCAlerts.api";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import { usagelogApi } from "../../api/usageReportApi";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { CustomTooltip } from "../MyLibrary/MyAlerts/Alerts.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Spinner from "../../components/spinner";
import { updateIsNavigatingBack } from "../../store/slice/searchSlice";

export interface State extends SnackbarOrigin {
  open: boolean;
}

const AuthorList = () => {
  const [searchkeyTerm, setSearchKeyTerm] = useState<any[]>([]);
  const [checkedState, setChekcedState] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [data_type, setData_type] = useState<any[]>([]);

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const [selectedLetterTags, setSelectedLetterTags] = React.useState(
    Array(28).fill(false)
  );
  // const [selectedLetterTags, setSelectedLetterTags] = React.useState<any[]>([]);
  const [selectedTag, setSelectedTag] = React.useState<String>("");

  const [enabledTags, setEnabledTags] = useState<any[]>([]);

  const isMounted = useRef(false);
  const triggerEvent = useRef(0);

  const isLoading = useAppSelector((state) => state.filterReducer.loading);

  const sessionId = useAppSelector((state) => state.login.session_key);
  const userId = useAppSelector((state) => state.login.user_id);
  const customerId = sessionStorage.getItem("informaticscustomer_id");

  const authorsearchReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  let authorResults: any = useSelector(
    (state: AuthorFinderObj) => state?.authorReducer?.authorsearchResult
  ) satisfies SearchResultType;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const trigger = useRef(0);

  const rows = useAppSelector((state) => state.authorReducer.rows);

  const searchReducer: any = useSelector(
    (state: ReducerObj) => state.searchReducer
  );

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const dataTypeParam = getParamsByName("dataType");
  const subjectTypeParam = getParamsByName("subjectType");

  const firstname_param = getParamsByName("firstname");
  const lastname_param = getParamsByName("lastname");

  // Function to trigger api after refresh - if data is empty in redux
  function onload() {
    if (Object.keys(authorReducer.authorsearchResult).length === 0) {
      if (firstname_param === "") {
        dispatch(updateAuthorSearchTerm(authorReducer.tempSearchTerm));
        dispatch(updateAuthorLastName(lastname_param));
        // dispatch(updateAuthorInitial("*"));
        dispatch(updateAuthorDataType(dataTypeParam));
        dispatch(updateAuthorSubjectType(subjectTypeParam));
        setChekcedState(authorReducer.tempSearchTerm);
        // setChekcedState(authorReducer.searchTerm)

        interface ObjType {
          [key: string]: any;
        }
        let queryObj: ObjType = {};

        queryObj.author_lastname = `("${lastname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = authorReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_name_initial = authorReducer.authorInitial;
        queryObj.page = 1;
        queryObj.rows = 15;
        queryObj.sort = "author_firstname asc, author_lastname asc";

        let onLoadQuery = queryConstructor(queryObj);

        let dataOnload = authorFinderApi(onLoadQuery);

        dispatch(updateAuthorSearchResults(dataOnload));

        triggerEvent.current = triggerEvent.current + 1;
      } else if (lastname_param === "") {
        dispatch(updateAuthorSearchTerm(authorReducer.tempSearchTerm));
        dispatch(updateAuthorFirstName(firstname_param));
        // dispatch(updateAuthorInitial("*"));
        dispatch(updateAuthorDataType(dataTypeParam));
        dispatch(updateAuthorSubjectType(subjectTypeParam));

        setChekcedState(authorReducer.tempSearchTerm);

        // setChekcedState(authorReducer.searchTerm)

        interface ObjType {
          [key: string]: any;
        }
        let queryObj: ObjType = {};

        queryObj.author_firstname_fz = `("${firstname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = authorReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_name_initial = authorReducer.authorInitial;
        queryObj.page = 1;
        queryObj.rows = 15;
        queryObj.sort = "author_firstname asc, author_lastname asc";

        let onLoadQuery = queryConstructor(queryObj);

        let dataOnload = authorFinderApi(onLoadQuery);

        dispatch(updateAuthorSearchResults(dataOnload));

        triggerEvent.current = triggerEvent.current + 1;
      } else {
        dispatch(updateAuthorSearchTerm(authorReducer.tempSearchTerm));
        dispatch(updateAuthorFirstName(firstname_param));
        dispatch(updateAuthorLastName(lastname_param));
        // dispatch(updateAuthorInitial("*"));
        dispatch(updateAuthorDataType(dataTypeParam));
        dispatch(updateAuthorSubjectType(subjectTypeParam));
        setChekcedState(authorReducer.tempSearchTerm);

        // setChekcedState(authorReducer.searchTerm)

        interface ObjType {
          [key: string]: any;
        }
        let queryObj: ObjType = {};

        queryObj.author_firstname_fz = `("${firstname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.author_lastname = `("${lastname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = authorReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_name_initial = authorReducer.authorInitial;
        queryObj.page = 1;
        queryObj.rows = 15;
        queryObj.sort = "author_firstname asc, author_lastname asc";

        let onLoadQuery = queryConstructor(queryObj);

        let dataOnload = authorFinderApi(onLoadQuery);

        dispatch(updateAuthorSearchResults(dataOnload));

        triggerEvent.current = triggerEvent.current + 1;
      }
    }
  }

  async function onload_Back() {
    if (authorResults?.grouped?.author_name_lf?.groups) return;

    if (Object.keys(authorReducer.authorsearchResult).length > 0) {
      console.log("Page refresh function Authorlist onLoad() -Step1");

      if (firstname_param === "") {
        dispatch(updateAuthorSearchTerm(authorReducer.tempSearchTerm));
        dispatch(updateAuthorLastName(lastname_param));
        // dispatch(updateAuthorInitial("*"));
        dispatch(updateAuthorDataType(dataTypeParam));
        dispatch(updateAuthorSubjectType(subjectTypeParam));
        // dispatch(updateAuthorSelectedTag("*"))
        setChekcedState(authorReducer.tempSearchTerm);
        // setChekcedState(authorReducer.searchTerm)
        // setChekcedState(authorReducer.selectAllAuthor)

        interface ObjType {
          [key: string]: any;
        }
        let queryObj: ObjType = {};

        queryObj.author_lastname = `("${lastname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = authorReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_name_initial = authorReducer.authorInitial;
        queryObj.page = 1;
        queryObj.rows = 15;
        queryObj.sort = "author_firstname asc, author_lastname asc";

        let onLoadQuery = await queryConstructor(queryObj);
        let dataOnload = await authorFinderApi(onLoadQuery);
        dispatch(updateAuthorSearchResults(dataOnload));

        // dispatch(search());

        triggerEvent.current = triggerEvent.current + 1;
      } else if (lastname_param === "") {
        dispatch(updateAuthorSearchTerm(authorReducer.tempSearchTerm));
        dispatch(updateAuthorFirstName(firstname_param));
        // dispatch(updateAuthorInitial("*"));
        dispatch(updateAuthorDataType(dataTypeParam));
        dispatch(updateAuthorSubjectType(subjectTypeParam));
        // dispatch(updateAuthorSelectedTag("*"))
        setChekcedState(authorReducer.tempSearchTerm);
        // setChekcedState(authorReducer.searchTerm)
        // setChekcedState(authorReducer.selectAllAuthor)

        // if (authorReducer.selectAllAuthor === true) {
        //   setSelectAll(authorReducer.selectAllAuthor)
        // }

        interface ObjType {
          [key: string]: any;
        }
        let queryObj: ObjType = {};

        queryObj.author_firstname_fz = `("${firstname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = authorReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_name_initial = authorReducer.authorInitial;
        queryObj.page = 1;
        queryObj.rows = 15;
        queryObj.sort = "author_firstname asc, author_lastname asc";

        let onLoadQuery = await queryConstructor(queryObj);
        console.log("onloadquery", onLoadQuery);

        let dataOnload = await authorFinderApi(onLoadQuery);
        console.log("data on load", dataOnload);

        dispatch(updateAuthorSearchResults(dataOnload));
        // dispatch(search());
        triggerEvent.current = triggerEvent.current + 1;
      } else {
        dispatch(updateAuthorSearchTerm(authorReducer.tempSearchTerm));
        dispatch(updateAuthorFirstName(firstname_param));
        dispatch(updateAuthorLastName(lastname_param));
        // dispatch(updateAuthorInitial("*"));
        dispatch(updateAuthorDataType(dataTypeParam));
        dispatch(updateAuthorSubjectType(subjectTypeParam));
        //  dispatch(updateAuthorSelectedTag("*"))
        setChekcedState(authorReducer.tempSearchTerm);
        // setChekcedState(authorReducer.searchTerm)
        // setChekcedState(authorReducer.selectAllAuthor)

        interface ObjType {
          [key: string]: any;
        }
        let queryObj: ObjType = {};

        queryObj.author_firstname_fz = `("${firstname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.author_lastname = `("${lastname_param
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = authorReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_name_initial = authorReducer.authorInitial;
        queryObj.page = 1;
        queryObj.rows = 15;
        queryObj.sort = "author_firstname asc, author_lastname asc";

        let onLoadQuery = queryConstructor(queryObj);

        let dataOnload = authorFinderApi(onLoadQuery);

        dispatch(updateAuthorSearchResults(dataOnload));
        // dispatch(search())
        triggerEvent.current = triggerEvent.current + 1;
      }
    }
  }

  useEffect(() => {
    if (authorReducer.buttonStatus === false) {
      onload_Back();
    } else {
      return () => {
        window.onbeforeunload = function () {
          console.log("Reload Button Clicked");

          onload();
          return true;
        };
      };
    }
  }, [
    authorReducer.buttonStatus,
    firstname_param,
    lastname_param,
    subjectTypeParam,
    dataTypeParam,
    enabledTags,
  ]);

  useEffect(() => {
    onload();
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function onRemove(value: string) {
    dispatch(removeAuthorSearchTerm(value));
    trigger.current = trigger.current + 1;
    setChekcedState((oldState: any) =>
      oldState.filter((item: any) => item !== value)
    );
  }

  function clearAll() {
    dispatch(clearAuthorSearchTerm());

    trigger.current = trigger.current + 1;
    setChekcedState([]);
    dispatch(updateAuthorSearchTerm([]));
  }

  //preferences
  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";

  const user_id = useAppSelector((state) => state.login.user_id);
  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);

  //const selector = useAppSelector((state) => state.login);
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  //Get Personalized Data for Preference
  const { data: myPreference, refetch } = useGetMySearchPreference(
    user_id,
    isPreference
  );
  const [isProfileUser, setIsProfileUser] = useState(false);

  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    console.log("USERUSER", userId);
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  useEffect(() => {
    isPreference && applyPreference();
  }, [isPreference, myPreference]);

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;
    const filteredSubjectData = myPreference.Subjects?.map(
      ({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
    ).flat();

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);
    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }

  function handlePreference(event: React.ChangeEvent<HTMLInputElement>) {
    logBasicSearchUsageData(112);
    if (isProfileUser) {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAdded(myPreference);
      }

      if (!isMyPrefAdded && event.target.checked) {
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      }
      setIsPreference(event.target.checked);
      sessionStorage.setItem("preference", event.target.checked.toString());
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  }

  async function applyPreference() {
    if (!user_id || !customerData) return;
    if (!myPreference) return;

    //dispatch(clearAuthorDataTypeFilters());

    if (isPreference && myPreference) {
      // Update preference data type
      const preferedSources = myPreference.DataTypes.filter(
        (type) => type.is_favorite
      )?.map((source) => source.datatype_id);

      console.log("Preferred Resources", preferedSources);

      dispatch(updateAuthorDataType(preferedSources));

      // update subject ids
      const preferedSubjects = myPreference.Subjects?.map((l1) =>
        l1.subject2_details.filter((l2) => l2.is_favorite)
      )

        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Unused, Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

      const subjectIds = preferedSubjects?.map(
        (x) => x.datsubjectmasterlevel2_id
      );

      dispatch(updateAuthorSubjectType(subjectIds));
    } else {
      const subjectIds = customerData.subject?.map(
        (x: any) => x?.datsubjectmasterlevel2_id
      );
      const dataTypeIds = ["1", "2", "3", "4", "8", "24"];
      // update datatype ids
      dispatch(updateAuthorDataType(dataTypeIds));

      //Update Subject Ids
      dispatch(updateAuthorSubjectType(subjectIds));
    }
  }

  //Checkbox Implementation
  function handleCheckBox(e: any) {
    let { checked, name } = e?.target;

    console.log(checked, name);

    if (checkedState.includes(name)) {
      let filterArr = checkedState.filter((data) => data != name);
      setChekcedState(filterArr);

      dispatch(updateAuthorSearchTerm(filterArr));

      dispatch(updateTempSearchTerm(filterArr));
    } else if (checkedState.length) {
      setChekcedState((prev) => [...prev, name]);

      dispatch(updateAuthorSearchTerm([...checkedState, name]));

      dispatch(updateTempSearchTerm([...checkedState, name]));
    } else {
      console.log("filter else case");
      setChekcedState([name]);
      console.log("filter ARR3", checkedState);

      dispatch(updateAuthorSearchTerm(name));

      dispatch(updateTempSearchTerm(name));
    }
    dispatch(updateTempSearchTerm([...checkedState, name]));
  }

  let currentPage = useSelector(
    (state: AuthorFinderObj) => state?.authorReducer?.page
  );

  useEffect(() => {
    if (triggerEvent.current) {
      console.log("Sort Status", sortStatus);
      if (selectedTag) {
        console.log("triggerEvent.current", triggerEvent.current);
        handleSort();
      } else {
        onPageChange();
      }
      // onPageChange();
    }
  }, [triggerEvent.current]);

  useEffect(() => {
    if (isMounted.current) {
      console.log("Sort Status", sortStatus);

      if (selectedTag) {
        console.log("isMounted.current", isMounted.current);

        handleSort();
      } else {
        console.log("Useeffect-onPageChange");
        onPageChange();
      }
    }
    // console.log('isMounted.current', isMounted.current)
    // onPageChange();
  }, [currentPage, rows, selectedTag]);

  useEffect(() => {
    clearAll();

    // dispatch(updateAuthorName(first_name));
  }, []);

  // useEffect(() => {
  //   if(authorReducer.is)
  // }, []);

  async function onPageChange() {
    console.log("on page change triggered");

    //    dispatch(updateAuthorSort(authorReducer.sort))

    let data = await onPageData(authorReducer);

    dispatch(updateLoadingState(false));

    dispatch(updateAuthorSearchResults(data));

    goToTop();
  }

  const selector = useAppSelector((state) => state.login);

  // to check the article exixtance in personal folders
  async function checkArticleExistance(dataArray: any) {
    if (!Array.isArray(dataArray)) return;
    const articleData = new FormData();

    const articleIds =
      Array.isArray(dataArray) && dataArray?.map((x: any) => x?.article_id);
    articleData.append("profile_id", selector.user_id);
    articleData.append("article_ids", articleIds.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateAuthorSavedArticles(filter_is_saved));
  }

  // Pagination

  let count = Math.ceil(authorResults?.grouped?.author_name_lf?.ngroups / rows);

  function handlePageChange(p: number) {
    dispatch(updateAuthorPage(p));

    isMounted.current = true;

    goToTop();
  }

  function getRowsPerPage(value: number) {
    isMounted.current = true;
    dispatch(updateAuthorPage(1));

    dispatch(updateAuthorRow(value));
    // setRow(value);
  }
  useEffect(() => {
    let dataTypeVal = searchReducer.dataType;
    setData_type(dataTypeVal);
    console.log("set Data Type Val", data_type);
  }, [setData_type]);

  const [handlePageTrigger, setHandlePageTrigger] = useState(false);

  const handlePage = async () => {
    setHandlePageTrigger(true);

    if (checkedState.length === 0) {
      setOpen(true);
      setMessage("Select Author names for View results");
      return;
    } else if (checkedState.length > 0) {
      logBasicSearchUsageData(17);
      setOpen(false);
      setMessage("");
      setSearchKeyTerm(checkedState);

      if (!checkedState) return;
      setLoading(true);

      dispatch(updateAbastractAuthor(true));
      dispatch(updateIsAuthorResult(true));

      let queryObj: any = {};

      queryObj.fq__authors_tk = checkedState;
      //queryObj.author_all_fz = checkedState;
      // queryObj.fq__subjects_id_l2 = authorReducer.subjectFilter;
      // queryObj.author_address = authorReducer.institutions;
      queryObj.fq__resource_type = data_type;
      queryObj.fq__subjects_id_l2 = authorReducer.subjectType;
      queryObj.sort = "dateofpublication desc";

      if (authorReducer.subjectFilter.length) {
        queryObj.subjects_name_l3 = authorReducer.subjectFilter;
      }

      if (authorReducer.institutions.length) {
        queryObj.author_address = authorReducer.institutions;
        // queryObj.author_affiliation = authorReducer.institutions;
      }

      const isOnlyConsortiaAccess = customerData?.product_type === "7";
      if (isOnlyConsortiaAccess) {
        queryObj.fq__acl_group = customerData.consortia_filter;
      }

      const fullTextQuery = await queryConstructor({
        ...queryObj,
        // fq__fulltext: true,
        "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
      });
      const allQuery = await queryConstructor({
        ...queryObj,
      });

      dispatch(
        updateQuery({
          fullTextQuery: fullTextQuery,
          allTextQuery: allQuery,
        })
      );

      // To Register the current new search
      const registerNewSearch = await queryConstructor({
        ...queryObj,
        logsearchhistory: true,
        datsearchtype_id: 3,
        sessionid: sessionId,
        profileuserid: userId,
        customerid: customerId,
      });
      await basicSearchMain(registerNewSearch);

      const fullTextData = await basicSearchMain(fullTextQuery);
      const allText = await authorSearchForCounts(allQuery);
      const fulltext = await authorSearchForCounts(fullTextQuery);

      await checkArticleExistance(fullTextData?.docs);
      await findAlertTypeSetting(fullTextData?.docs);
      await linkToDisplay(fullTextData?.docs);

      dispatch(updateAuthorSearchResults(fullTextData));

      dispatch(updateAuthorFullText(true));
      setLoading(false);

      if (fullTextData?.docs) {
        // navigate("/authorfindersearch?searchterm=" + `${checkedState}`, {
        navigate(
          // `/authorfindersearch?searchterm= ${checkedState.join(" OR ")}&dataType=${dataTypeParam}&subjectType=${subjectTypeParam}`,
          `/authorfindersearch?searchterm= ${checkedState.join(
            " OR "
          )}&dataType=${dataTypeParam}&subjectType=${subjectTypeParam}&tags=${enabledTags}`,
          {
            state: {
              searchTerm: `${checkedState}`,
              fullTextQuery: fullTextQuery,
              allQuery: allQuery,
              allCount: allText?.hits,
              fullCount: fulltext?.hits,
            },
          }
        );

        dispatch(updateAuthorPage(1));
        dispatch(updateAuthorRow(15));
        dispatch(updateIsInitialResult(true));
      }
    }
  };

  const [handleSortTrigger, setHandleSortTrigger] = useState(false);

  const handleSort = async () => {
    setHandleSortTrigger(true);
    dispatch(updateLoadingState(true));
    interface ObjType {
      [key: string]: any;
    }

    if (selectedTag === "ALL") {
      console.log("All case triggered");

      dispatch(updateAuthorSelectedTag("ALL"));
      dispatch(updateAuthorInitial("*"));

      let res_ALL: any = await onPageData(authorReducer);
      dispatch(updateAuthorSearchResults(res_ALL));
    } else if (selectedTag === "0-9") {
      console.log("0-9 case triggered");
      dispatch(updateAuthorSelectedTag("0-9"));
      dispatch(updateAuthorInitial("*"));

      let res_Nine: any = await onPageData(authorReducer);
      dispatch(updateAuthorSearchResults(res_Nine));
    } else if (selectedTag === "Deselected") {
      console.log("Tag Deselected");

      dispatch(updateAuthorSelectedTag("*"));
      dispatch(updateAuthorInitial("*"));
      let res_Deselect: any = await onPageData(authorReducer);
      console.log("res_Deselect", res_Deselect);
      dispatch(updateAuthorSearchResults(res_Deselect));
    } else {
      dispatch(updateAuthorSelectedTag(selectedTag));
      dispatch(updateAuthorInitial(selectedTag));

      let res_Tag: any = await onPageData(authorReducer);
      dispatch(updateAuthorSearchResults(res_Tag));
    }
    dispatch(updateLoadingState(false));
  };

  useEffect(() => {
    if (selectedTag) {
      handleSort();
    }
  }, [selectedTag]);

  useEffect(() => {
    let enabledTagsList: any[] = ["ALL"];

    let enabledTaglistArray =
      authorReducer.authorsearchResult?.jsonfacets?.author_name_initial
        ?.buckets;
    if (enabledTaglistArray != undefined) {
      enabledTaglistArray =
        authorReducer.authorsearchResult?.jsonfacets?.author_name_initial
          ?.buckets;
      Array.isArray(enabledTaglistArray) &&
        enabledTaglistArray?.map((ele: any) =>
          ele?.val?.charAt(0)?.toUpperCase().match(/[a-z]/i)
            ? enabledTagsList.push(ele?.val?.charAt(0)?.toUpperCase())
            : null
        );
      setEnabledTags(enabledTagsList);
    } else if (enabledTaglistArray === undefined) {
      enabledTaglistArray = authorReducer.tempTags;
      Array.isArray(enabledTaglistArray) && setEnabledTags(enabledTaglistArray);
    }
  }, []);

  const updateSelectedTags = (element: any, index: any) => {
    console.log("ELEMENT", element);

    let temp = Array(28).fill(false);
    dispatch(updateAuthorPage(1));

    if (selectedLetterTags[element]) {
      console.log("------->");
      setSelectedLetterTags(temp);
      setSelectedTag("Deselected");
      dispatch(updateAuthorSelectedTag("*"));
      dispatch(updateAuthorPage(1));
      dispatch(updateAuthorInitial("*"));
    } else {
      if (element == 0) {
        console.log("ALL TRIGGERED");
        temp[0] = true;
        setSelectedLetterTags(temp);
        // setSelectedTag("");
        setSelectedTag("ALL");
        dispatch(updateAuthorSelectedTag("ALL"));
        dispatch(updateAuthorPage(1));
      } else if (element == 1) {
        console.log("0-9 TRIGGERED");
        temp[1] = true;
        setSelectedLetterTags(temp);
        // setSelectedTag("");
        setSelectedTag("0-9");
        dispatch(updateAuthorSelectedTag("0-9"));
        dispatch(updateAuthorPage(1));
      } else {
        temp[element] = true;
        setSelectedLetterTags(temp);
        setSelectedTag(String.fromCharCode(element + 63));

        dispatch(updateAuthorSelectedTag(String.fromCharCode(element + 63)));
        dispatch(updateAuthorPage(1));
      }
    }
  };

  function selectAllAuthor(e: any) {
    let dataArr = authorResults?.grouped?.author_name_lf?.groups;

    if (!Array.isArray(dataArr)) return;

    console.log("e?.target.checked", e?.target.checked);
    if (e?.target.checked) {
      let filteredAuthor = dataArr?.map(
        (obj: any) => obj?.doclist.docs[0].author_name_lf
      );

      let arr = checkedState;
      let values = filteredAuthor;

      arr = arr.filter((item) => !values.includes(item));
      console.log("filter", arr);

      dispatch(updateAuthorSearchTerm([...filteredAuthor, ...arr]));

      dispatch(updateTempSearchTerm([...filteredAuthor, ...arr]));

      setChekcedState([...filteredAuthor, ...arr]);

      // dispatch(updateSelectAllAuthor(true))
    } else {
      let filteredAuthor = dataArr?.map(
        (obj: any) => obj?.doclist.docs[0].author_name_lf
      );

      let arr = checkedState;
      let values = filteredAuthor;
      arr = arr.filter((item) => !values.includes(item));
      setChekcedState(arr);
      dispatch(updateAuthorSearchTerm(arr));

      dispatch(updateTempSearchTerm(arr));
    }
  }

  //Table Sort
  // let arrayData: any[] = authorResults?.grouped?.author_name_lf?.groups;
  let arrayData: any[] = authorResults?.grouped?.author_name_lf?.groups;
  // console.log("ARRAY DATA", arrayData);
  const [sortDataTable, setsortDataTable] = useState<any>([]);

  useEffect(() => {
    setsortDataTable(arrayData);
  }, [arrayData]);

  useEffect(() => {
    let sortData = authorReducer.sort;
    setOrder(sortData);
  }, []);

  const [order, setOrder] = useState("asc");

  useEffect(() => {
    handleSearchTermTag();
  }, []);

  const handleSearchTermTag = () => {
    let firstnameTag = authorReducer.firstname;
    let lastnameTag = authorReducer.lastname;
    if (firstnameTag == "") {
      return (
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            endIcon={<CloseIcon />}
            sx={authorFinderStyle.searchTextFont}
          >
            Last Name:{authorReducer.lastname}
          </Button>
        </Stack>
      );
    } else if (lastnameTag == "") {
      return (
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            endIcon={<CloseIcon />}
            sx={authorFinderStyle.searchTextFont}
          >
            First Name:{authorReducer.lastname}
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            endIcon={<CloseIcon />}
            sx={authorFinderStyle.searchTextFont}
          >
            First Name:{authorReducer.firstname}&nbsp; Last Name:
            {authorReducer.lastname}
          </Button>
        </Stack>
      );
    }
  };

  const [sortStatus, setSortStatus] = useState(false);

  useEffect(() => {
    if (triggerEvent.current) {
      sort();
    }
  }, [triggerEvent.current]);

  const sort = async () => {
    let data_SORT = await onPageData(authorReducer);

    console.log("data_Sort-desc", data_SORT);
    //const dataPage = onPageChange()
    dispatch(updateAuthorSearchResults(data_SORT));
  };

  const handleSortData = async (col: any) => {
    if (col === "author_name_lf") {
      console.log("author name col triggered");
      let tempVal = authorReducer.sort;
      console.log("tempval", tempVal);
      // setSortOrder(tempVal)
      setSortStatus(true);

      if (tempVal === "desc") {
        console.log("author name col triggered desc");
        dispatch(updateAuthorSort("desc"));

        dispatch(updateAuthorSort("asc"));
        console.log("Sorted Data-Desc", authorReducer.authorsearchResult);
        //dispatch(updateAuthorSearchResults(dataPage))
        triggerEvent.current = triggerEvent.current + 1;
      }

      // else if (tempVal === 'asc') {
      else {
        console.log("author name col triggered asc");
        dispatch(updateAuthorSort("asc"));

        dispatch(updateAuthorSort("desc"));

        console.log("Sorted Data-Asc", authorReducer.authorsearchResult);
        triggerEvent.current = triggerEvent.current + 1;
        //dispatch(updateAuthorSearchResults(dataPage))
      }
    }
  };

  function toTitleCase(text: string) {
    const words = text?.split(" ");
    const titleCasedWords = words?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titleCasedWords?.join(" ");
  }

  async function linkToDisplay(dataArray: any) {
    if (!Array.isArray(dataArray)) return;
    let formData = new FormData();

    let data = dataArray?.map((obj: any) => {
      let object: any = {
        customer_id: customerData?.informaticscustomer_id,
        article_id: obj?.article_id,
        consortia_virtualid: customerData?.consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      return object;
    });

    let formApiData = {
      data,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      dispatch(updateAuthorLinkToDisplay(response?.data));
    }
  }

  async function findAlertTypeSetting(dataArray: any) {
    try {
      const resourcemaster_ids =
        Array.isArray(dataArray) &&
        dataArray?.map((x: any) => x?.resourcemaster_id);
      if (Array.isArray(resourcemaster_ids)) {
        const alertSettingData = await getAlertSettings(
          selector.user_id,
          resourcemaster_ids.join(",").replace(/,,/g, ",").split(",")
        );
        let alertDataRes = alertSettingData.data.data[0][0];
        dispatch(updateAuthorAlertSettingData(alertDataRes));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function openPreferenceInfoModal() {
    dispatch(
      setModal({
        modalType: "PREFERENCE_INFO_MODEL",
        modalProps: {
          isOpen: true,
        },
      })
    );
  }

  return (
    <>
      {/* <Nav /> */}
      <Box bgcolor={Colors.white} py={"1%"}>
        {message && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
          </Stack>
        )}
        <Box sx={{ paddingInline: "66px" }}>
          <Box sx={authorFinderStyle.flexRow}>
            <Box sx={authorFinderStyle.flexRow}>
              {/* <IconButton onClick={() => navigate("/authorfinder")}> */}
              <IconButton
                onClick={() => {
                  dispatch(updateIsNavigatingBack(true));
                  navigate(-1);
                }}
              >
                <ArrowBackIosIcon
                  sx={{ alignSelf: "center" }}
                  fontSize="small"
                />
              </IconButton>
              <Typography sx={authorFinderStyle.textResult} variant="h5">
                Author Finder
              </Typography>
            </Box>
            <Box sx={[authorFinderStyle.flexRow, { alignItems: "center" }]}>
              <Typography sx={[authorFinderStyle.toogleText]}>
                Preferences
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                marginLeft={"6%"}
              >
                <Switch
                  sx={{ m: 1 }}
                  checked={isPreference}
                  onChange={handlePreference}
                  inputProps={{ "aria-label": "Preferences Switch" }}
                />
              </Stack>
              <Stack>
                <IconButton size="medium" onClick={openPreferenceInfoModal}>
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Box>
          </Box>

          {/* Input box */}

          <Box
            sx={[
              {
                marginTop: "2%",
                display: "flex",
              },
            ]}
          >
            <Box
              display={"flex"}
              sx={{
                flexGrow: "1",
                flexShrink: "1",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <AlphabeticalFilterForAuthor
                selectedTags={selectedLetterTags}
                selectOnClick={updateSelectedTags}
                enabledTags={enabledTags}
              />

              <Button
                variant="outlined"
                sx={authorFinderStyle.button}
                onClick={handlePage}
              >
                {loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#7852FB",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                ) : (
                  "View Results"
                )}
              </Button>
            </Box>
          </Box>

          {/* bottom text */}
          {/* @ts-ignore */}
          <Box
            sx={[
              authorFinderStyle.flexRow,
              {
                marginTop: "1%",
                marginBottom: "1%",
                flexWrap: "wrap",
                gap: 1,
                alignItems: "center",
              },
            ]}
          >
            <Button sx={authorFinderStyle.clearallText} onClick={clearAll}>
              Clear All
            </Button>

            {authorReducer.firstname && authorReducer.lastname === "" && (
              <Stack direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={authorFinderStyle.searchTextFont}
                >
                  Search term:&nbsp;First name: {authorReducer.firstname}
                  {/* Search term:&nbsp;First name: {(authorReducer.firstname).charAt(0).toUpperCase() + authorReducer.firstname.slice(1)} */}
                </Button>
              </Stack>
            )}

            {authorReducer.lastname && authorReducer.firstname === "" && (
              <Stack direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={authorFinderStyle.searchTextFont}
                >
                  Search term:&nbsp;Last name: {authorReducer.lastname}
                  {/* Search term:&nbsp;Last name: {(authorReducer.lastname).charAt(0).toUpperCase() + authorReducer.lastname.slice(1)} */}
                </Button>
              </Stack>
            )}

            {authorReducer.lastname && authorReducer.firstname && (
              <Stack direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={authorFinderStyle.searchTextFont}
                >
                  Search term:&nbsp;Last name: {authorReducer.lastname}, First
                  name: {authorReducer.firstname}
                  {/* Search term:&nbsp;Last name: {(authorReducer.lastname).charAt(0).toUpperCase() + authorReducer.lastname.slice(1)}, First name: {(authorReducer.firstname).charAt(0).toUpperCase() + authorReducer.firstname.slice(1)} */}
                </Button>
              </Stack>
            )}

            {/* {Array.isArray(searchReducer.searchTerm) && */}
            {Array.isArray(authorsearchReducer.searchTerm) &&
              authorsearchReducer.searchTerm?.map((val: string, i: number) => {
                return (
                  <Stack direction="row" spacing={2}>
                    <Button
                      size="small"
                      variant="outlined"
                      endIcon={<CloseIcon />}
                      sx={authorFinderStyle.searchTextFont}
                      onClick={() => {
                        onRemove(val);
                      }}
                    >
                      {toTitleCase(
                        val?.split(",").reverse().join(" ").replace(",", "")
                      )}
                    </Button>
                  </Stack>
                );
              })}
          </Box>
        </Box>
      </Box>

      <Box sx={{ backgroundColor: Colors.coolGray100 }}>
        <Box ml={"5%"} mx={"5%"}>
          <Grid container>
            <Grid item xs={3}>
              <Typography
                fontFamily={"Helvetica Neue"}
                fontSize={"20px"}
                fontWeight={700}
                paddingLeft={"5%"}
                mt={"2%"}
              >
                {/* Results {authorResults?.grouped?.author_name_lf?.matches.toLocaleString("en-US")} */}
                Results{" "}
                {authorResults?.grouped?.author_name_lf?.ngroups.toLocaleString(
                  "en-US"
                )}
                {/* {searchResults.hits} */}
              </Typography>

              <Filter
                yearFromData={authorResults?.jsonfacets?.yearfrom?.buckets}
                jsonFacets={authorResults?.jsonfacets}
              />
            </Grid>
            <Grid item xs={9} sx={{ height: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2, marginTop: "4%" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead
                      sx={{
                        height: "40%",
                        background: "#F9FAFB",
                        border: " 1px solid #D1D5DB",
                        borderRadius: "8px 8px 0px 0px",
                      }}
                    >
                      <TableRow style={{ backgroundColor: "#F9FAFB" }}>
                        <TableCell
                          padding="checkbox"
                          sx={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          <Tooltip title="Select All">
                            <Checkbox
                              onChange={selectAllAuthor}
                              checked={authorResults?.grouped?.author_name_lf?.groups.every(
                                (ele: any) =>
                                  checkedState.includes(
                                    ele.doclist.docs[0].author_name_lf
                                  )
                              )}
                              name={authorResults?.grouped?.author_name_lf?.groups.every(
                                (ele: any) =>
                                  checkedState.includes(
                                    ele.doclist.docs[0].author_name_lf
                                  )
                              )}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          onClick={() => handleSortData("author_name_lf")}
                          //onClick={handleSortData}

                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            width: "350px",
                            cursor: "pointer",
                          }}
                        >
                          Author Name &nbsp;
                          <IconButton>
                            <FilterListIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 500, fontSize: "16px" }}
                          //onClick={() => handleDocCount("numFound")}
                        >
                          {/* <TableSortLabel
                            hideSortIcon={true}
                            active={false}
                            direction={"asc" ? "desc" : "asc"}
                          // onClick={() => handleDocCount("numFound")}
                          > */}
                          Document Count &nbsp;
                          {/* <FilterListIcon />
                          </TableSortLabel> */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!authorResults?.grouped?.author_name_lf?.groups
                        .length && (
                        <TableRow
                          sx={{
                            background: "#FFFFFF",
                            border: " 1px solid #D1D5DB",
                            height: "65px",
                          }}
                        >
                          <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                            No records to display
                          </TableCell>
                        </TableRow>
                      )}

                      {authorResults?.grouped?.author_name_lf?.groups?.map(
                        (data: any, i: any) => {
                          {
                            /* {Array.isArray(sortDataTable) && sortDataTable?.map((data: any, i: any) => { */
                          }
                          return (
                            <TableRow
                              key={`${data.doclist.docs[0].id} `}
                              sx={{
                                background: "#FFFFFF",
                                border: " 1px solid #D1D5DB",
                                height: "65px",
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="checkbox"
                              >
                                <Checkbox
                                  onChange={handleCheckBox}
                                  checked={checkedState.includes(
                                    data?.doclist.docs[0].author_name_lf
                                  )}
                                  //checked={checkedState.includes(`(${ data.doclist.docs[0].author_firstname } ${ data.doclist.docs[0].author_lastname }`).toString()}
                                  name={data?.doclist.docs[0].author_name_lf}
                                />
                              </TableCell>
                              <TableCell>
                                {/* {data.doclist.docs[0].author_firstname
                                  .split(",")
                                  // .reverse()
                                  .join(" ")
                                  .replace(",", "")}
                                {data.doclist.docs[0].author_lastname === ""
                                  ? ""
                                  : ","}{" "}
                                {data.doclist.docs[0].author_lastname
                                  .split(",")
                                  // .reverse()
                                  .join(" ")
                                  .replace(",", "")} */}
                                {/* {name.length
                                  ? toTitleCase(name
                                    .trim()
                                    .replace(/^\,+|\,+$/g, "")
                                    .trim())
                                  : ""}{" "} */}
                                {toTitleCase(
                                  data.doclist.docs[0].author_firstname
                                    ?.split(",")
                                    ?.join(" ")
                                    ?.replace(",", "")
                                )}{" "}
                                {toTitleCase(
                                  data.doclist.docs[0].author_lastname
                                    ?.split(",")
                                    .join(" ")
                                    .replace(",", "")
                                )}
                              </TableCell>

                              <TableCell>{data.doclist.numFound}</TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
            <Pagination
              count={count}
              handlePageChange={handlePageChange}
              rowsPerResult={rows}
              getRowsPerPage={getRowsPerPage}
              currentPage={currentPage}
            />
            <Spinner isLoading={isLoading} />
          </Box>
        </Box>
        <IconButton
          aria-label="Scroll to Top"
          sx={{
            position: "fixed",
            bottom: "70px",
            right: "1vw",
            transform: "translateY(-50%)",
            border: "1px solid #D1D5DB",
            backgroundColor: "#FFF",
            color: "primary.main",
            "&:hover": {
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              boxShadow: 2,
            },
          }}
          onClick={() => goToTop()}
        >
          <ArrowUpwardIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default AuthorList;

const authorFinderStyle = {
  searchText: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "none",
    padding: "1px",
    height: "40px",
    width: "150px",
  },

  button: {
    padding: "14px 24px",
    background: "#FFFFFF",
    borderRadius: "8px",
    height: "51px",
    minWidth: "150px",
    // width: '250px',
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'center',
    justifyContent: "space-between",
    marginLeft: "1%",
    border: "1px solid #7852FB",
    fontSize: "16px",
    // alignItems: "center"
  },

  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "78%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },

  searchTextFont: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
    height: 22,
  },
  clearallText: {
    height: 22,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    // fontSize: 24,
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
  },
  toogleText: {
    fontFamily: "Inter",
    fontWeight: "500",
    marginLeft: "20%",
  },
};
