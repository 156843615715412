import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Divider,
  Typography,
  Grid,
  Stack,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Colors } from "../utils/constants";
import DropDownComponent from "../components/browserJournalblocks/dropDownComponent";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  browseJournalSearchResult,
  setSearchKeyword,
  tabSwitchBrowserJournal,
  updateBrowseJournalSearch,
} from "../store/slice/browseJournal";

import {
  clearAllFilters,
  updateBrowserPage,
  updateBrowserRow,
  updateCurrentUpdate,
  updatecollection,
  updateselectAlphabet,
} from "../store/slice/browseFilterSlice";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { alertsAndFav, setKeyword } from "../store/slice/browseJournal";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AlphabeticalFilterForAuthor from "../components/alphabeticalFilter/AlphabeticalFilterForAuthor";
import SearchFilterComponentOfBook from "../components/leftBarComponent/serachFilterOFBook";
import Books from "../screens/browseJournal/books";
import { useAppSelector } from "../store/hooks";
import { bookPublisherAutoSuggestion, booksAutoSuggestion, publisherAutoSuggestion } from "../api/browseJournal";
import objectToQueryString from "../screens/browseJournal/objToQuery";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { goToTop } from "../utils/helper";
import { useSearchParams } from "react-router-dom";
import TabView from "../components/browserJournalblocks/TabView";
import Publisher from "../components/browserJournalblocks/Publisher";
import { updateBrowserSearch_term } from "../store/slice/browseFilterSlicePackage";
import { updatepublishercollection, updateselectAlphabetPublisher } from "../store/slice/browserFilterSlicePublisher";
import {
  updateBrowserPage as updateBrowserPagePublisher,
} from "../store/slice/browserFilterSlicePublisher";
import { setSearchKeywordPublisher } from "../store/slice/browserPublisher";
import BookPublisher from "./BookPublisher";
import AlphabeticalFilter from "../components/alphabeticalFilter/AlphabeticalFilter";

const text = ["Title A-Z", "By Publisher"];

export const BookSearch: React.FC = () => {
  const conditions = ["Starting With", "Containing"];
  const [autoSearch, setAutoSearch] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(true); // To control the opening and closing of Autocomplete
  const query = new URLSearchParams(window.location.search);
  const [keyWord, setKeyWord] = React.useState(query.get("searchTerm") || "");
  const [keyWords, setKeyWords] = React.useState<any[]>([]);
  const [handleEnter, setHandleEnter] = React.useState(false);
  const [message, setMessage] = useState({ messages: "", open: false });
  const [selectedLetterTags, setSelectedLetterTags] = React.useState(
    Array(28).fill(false)
  );
  const [selectedLetterTagsPublisher, setSelectedLetterTagsPublisher] =
  React.useState(Array(28).fill(false));
  const [selectedTag, setSelectedTag] = React.useState<String>("");
  const [filters, setFilters] = React.useState<any>("");
  const [myPersonalLibrary, setMyPersonalLibrary] = useState(false);
  const [selectFilters, setSelectFilters] = useState(false);
  const [atoz, setatoz] = useState(false);
  const [atozPublisher, setatozPublisher] = useState(false);
  const [collectionFilter, setCollectionFilter] = useState("");
  const [tabIndex, setTabIndex] = useState<any>(0);


  const [keyWordPublisher, setKeyWordPublisher] = React.useState("");
  const [keyWordsPublisher, setKeyWordsPublisher] = React.useState<any[]>([]);
  const [keyWordPackage, setKeyWordPackage] = React.useState("");
  const [autoSearchPublisher, setAutoSearchPublisher] = useState<any>([]);
  const [myPersonalLibraryPublisher, setMyPersonalLibraryPublisher] =
    useState(false);
   // To control the opening and closing of Autocomplete
  const [isOpenPublisher, setIsOpenPublisher] = useState(true); // To control the opening and closing of Autocomplete
  const [myLibrary, setMyLibrary] = useState(false);

  const searchContainOrStartWiths = useSelector((state: any) => state);

  const dispatch = useDispatch();

  const searchContainOrStartWith = useSelector(
    (state: any) => state.browseJournalSearch.containStartingWith
  );
  const enabledTags = useSelector(
    (state: any) => state.browseJournalSearch.knownAlphasearch
  );
  const bookData: any = useAppSelector(
    (state: any) => state.browseJournalSearch.bookResult
  );

 
  let [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = new URLSearchParams();
   
    if (keyWord) {
      params.set('searchTerm', keyWord);
    } else {
      params.delete('searchTerm');
    }
    setSearchParams(params, {
      replace: true,
    });
    
  }, [keyWord,useSearchParams]);

  const search  = async ()=>{
    clearAllFilters();
    dispatch(
      setSearchKeyword({
        searchType: searchContainOrStartWith,
        words: keyWord,
      })
    );
    setKeyWords([
      ...keyWords,
      { searchType: searchContainOrStartWith, words: keyWord },
    ]);

    dispatch(updateselectAlphabet(""));
    dispatch(updateBrowserPage(1));
  }

  useEffect(() => {
    if (keyWord !== "") search()
    goToTop();
  
  }, []);
  useEffect(()=>{updateSelectedTagsPublisher(0,1);},[tabIndex]);

  // FOR TITLE A-Z
  const updateSelectedTags = (element: any, index: any) => {
    let temp = Array(28).fill(false);
    dispatch(updateBrowserPage(1));
    // usageReport("86");
    // logBasicSearchUsageData(86);

    if (selectedLetterTags[element]) {
      setSelectedLetterTags(temp);
      setSelectedTag("");
      dispatch(updateselectAlphabet(""));
    } else {
      if (element == 0) {
        temp[0] = true;
        setKeyWord("");
        setSelectedLetterTags(temp);
        setSelectedTag("");
        dispatch(
          setSearchKeyword({
            searchType: "",
            words: "",
          })
        );

        dispatch(updateselectAlphabet(""));
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTags(temp);
        dispatch(updateselectAlphabet("1"));
        setSelectedTag("");
      } else {
        temp[element] = true;
        setSelectedLetterTags(temp);
        setSelectedTag(String.fromCharCode(element + 63));
        dispatch(updateselectAlphabet(String.fromCharCode(element + 63)));
      }
    }
  };

  const updateSelectedTagsPublisher = (element: any, index: any) => {
    // logBasicSearchUsageData(86);

    let temp = Array(28).fill(false);
    dispatch(updateBrowserPagePublisher(1));
    if (selectedLetterTagsPublisher[element]) {
      setSelectedLetterTagsPublisher(temp);
      // setSelectedTagPublisher("");
    } else {
      if (element == 0) {
        setKeyWordPublisher("");
        dispatch(setSearchKeywordPublisher([]));
        temp[0] = true;
        setSelectedLetterTagsPublisher(temp);
        // setSelectedTagPublisher("");
        dispatch(updateselectAlphabetPublisher(""));
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTagsPublisher(temp);
        dispatch(updateselectAlphabetPublisher("1"));
        // setSelectedTagPublisher("");
      } else {
        temp[element] = true;
        setSelectedLetterTagsPublisher(temp);
        // setSelectedTagPublisher(String.fromCharCode(element + 63));
        dispatch(
          updateselectAlphabetPublisher(String.fromCharCode(element + 63))
        );
      }
    }
  };

  const onChangeKeyword_Books = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWord(e.target.value);
    let searchedLetters: any = "";
    if (e.target.value.length >= 3) {
      // @ts-ignore
      let searchedArrayLetters = e.target.value.split(" ");
      searchedArrayLetters?.map((element: string) => {
        searchedLetters += `${element}\\ of\\ `;
      });

      let apiJournalData = {
        resource_name_tk: `${searchedLetters.slice(0, -6)}*`,
      };

      let response = await booksAutoSuggestion(
        objectToQueryString(apiJournalData)
      );

      setAutoSearch(response?.data.data.jsonfacets.resource_name_tk.buckets);
    }
    dispatch(setKeyword(e.target.value));
  };
  const handleChange = (event: React.SyntheticEvent) => {
    dispatch(clearAllFilters());
    setFilters("");
    // setFiltersPublisher("");
    // setKeyWordPublisher("");
    // dispatch(clearAllFiltersPackage());
    // dispatch(clearAllFiltersPublisher());
    dispatch(setSearchKeyword([]));
    dispatch(setSearchKeywordPublisher([]));
    // setKeyWordsPackage([]);
    dispatch(updateBrowserSearch_term([]));
    // setKeyWordPackage("");
    // dispatch(tabSwitchBrowserJournal(newValue));
    // dispatch(updateCurrentUpdate(!openAccess));
    
  };

  const onChangeKeywordPublisher = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWordPublisher(e.target.value);
    if (e.target.value.length >= 0) {
      // @ts-ignore
      let targetVal: string = e.target.value?.split(" ")?.join("\\ ");
      let apiData = {
        page: 1,
        rows: 15,
        profileuserid: searchContainOrStartWiths.login.user_id,
        fq__resource_type: `(${searchContainOrStartWiths.searchReducer.dataType
          .join(" OR ")
          .replace(/[()]/g, "")})`,
        publisher_name_tk: encodeURIComponent(`${targetVal}*`),
        fq__active_flag: true,
        facet_fields: "publisher_name",
        sort: "publisher_name_tk asc",
        op_mode: "and",
        fl: "publisher_name_tk, primary_publisher_country,publisher_id",
        group_field: "publisher_name_tk",
        group_ngroups: true,
      };
      let res = await bookPublisherAutoSuggestion(objectToQueryString(apiData));
      setAutoSearchPublisher(
        res?.data?.data?.facets?.facet_fields?.publisher_name
      );
    }
  };

  return (
    <React.Fragment>
      <Typography sx={webStyles.textResult} variant="h5">
        Book Finder
      </Typography>
      <TabView
        text={text}
        value={tabIndex}
        handleChange={(e)=>{
          if(tabIndex===0){setTabIndex(1)}
          else {setTabIndex(0)}
          handleChange(e)
        }}
      ></TabView>
    {tabIndex === 0 ? (<> <Box
        mr="3%"
        ml="3%"
        mt={"1%"}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <DropDownComponent items={conditions}></DropDownComponent>
        <Box style={{ flex: 1, alignSelf: "center" }}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={autoSearch?.map((option: any) => option.val) || []}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={keyWord}
            sx={{ width: 300 }}
            // @ts-ignore
            onChange={(event: any, newValue: string) => {
              setAutoSearch([]);
              setKeyWord(newValue);
              setKeyWords([
                ...keyWords,
                { searchType: searchContainOrStartWith, words: keyWord },
              ]);
              setIsOpen(false);
            }}
            // value={keyWord}
            size="small"
            // @ts-ignore
            style={{ width: "100%" }}
            onKeyDown={(e) => {
              //logBasicSearchUsageData(88);
              if (e.key === "Enter" && keyWord) {
                //usageReport("88");
                // logBasicSearchUsageData(88);
                setKeyWords([
                  ...keyWords,
                  { searchType: searchContainOrStartWith, words: keyWord },
                ]);
                dispatch(
                  setSearchKeyword({
                    searchType: searchContainOrStartWith,
                    words: keyWord,
                  })
                );
                dispatch(updateselectAlphabet(""));

                // setHandleEnter(true);
                setIsOpen(false);
              }
            }}
            renderInput={(params) => (
              <TextField
                placeholder="Title"
                onChange={onChangeKeyword_Books}
                sx={[
                  webStyles.textInputText,
                  { width: "100%", marginRight: "0.5%" },
                ]}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                  endAdornment: (
                    <InputAdornment position="end">
                      {keyWord ? (
                        <IconButton
                          onClick={() => {
                            dispatch(setKeyword(""));
                            setKeyWord("");
                            dispatch(
                              setSearchKeyword({
                                searchType: searchContainOrStartWith,
                                words: "",
                              })
                            );
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Button
          variant="contained"
          sx={webStyles.button}
          onClick={async () => {
            let res;
            //usageReport("88");
            // logBasicSearchUsageData(88);
            if (keyWord == "") {
              setMessage({
                messages: "Try for some appropriate keywords / terms",
                open: true,
              });
              return;
            }

          search();
          }}
        >
          Search
        </Button>
      </Box>
      <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
        <AlphabeticalFilter
              selectedTags={selectedLetterTags}
              selectOnClick={updateSelectedTags}
            />
      </Box> </>) : (
        <>
          <Box
            mr="3%"
            ml="3%"
            mt={"1%"}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <DropDownComponent items={conditions}></DropDownComponent>
            <Box style={{ flex: 1, alignSelf: "center" }}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={autoSearchPublisher?.map(
                (option: any) => Object.keys(option) || []
              )}
              open={isOpenPublisher}
              onOpen={() => setIsOpenPublisher(true)}
              onClose={() => setIsOpenPublisher(false)}
              value={keyWordPublisher}
              // @ts-ignore
              onChange={(event: any, newVal: string) => {
                setAutoSearchPublisher([]);
                setKeyWordPublisher(newVal[0]);
                setKeyWordsPublisher([
                  ...keyWordsPublisher,
                  {
                    searchType: searchContainOrStartWith,
                    words: keyWordPublisher,
                  },
                ]);
              }}
              sx={{ width: 300 }}
              //  onChange={() => {}}
              size="small"
              // @ts-ignore
              style={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && keyWordPublisher) {
                  setKeyWordsPublisher([
                    ...keyWordsPublisher,
                    {
                      searchType: searchContainOrStartWith,
                      words: keyWordPublisher,
                    },
                  ]);
                  dispatch(
                    setSearchKeywordPublisher({
                      searchType: searchContainOrStartWith,
                      words: keyWordPublisher,
                    })
                  );
                  setHandleEnter(true);
                }
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Search by Publisher Name"
                  onChange={onChangeKeywordPublisher}
                  sx={[
                    webStyles.textInputText,
                    { width: "100%", marginRight: "0.5%" },
                  ]}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {keyWordPublisher ? (
                          <IconButton
                            onClick={() => {
                              setKeyWordPublisher("");
                              dispatch(
                                setSearchKeywordPublisher({
                                  searchType: searchContainOrStartWith,
                                  words: "",
                                })
                              );
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            /></Box>

            <Button
              variant="contained"
              sx={webStyles.button}
              onClick={async () => {
                let res;

                if (keyWordPublisher == "") {
                  setMessage({
                    messages: "Try for some appropriate keywords / terms",
                    open: true,
                  });
                  return;
                }
                clearAllFilters();

                setKeyWordsPublisher([
                  ...keyWordsPublisher,
                  {
                    searchType: searchContainOrStartWith,
                    words: keyWordPublisher,
                  },
                ]);
                dispatch(
                  setSearchKeywordPublisher({
                    searchType: searchContainOrStartWith,
                    words: keyWordPublisher,
                  })
                );

                setatozPublisher(true);
              }}
            >
              Search
            </Button>
          </Box>
          <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
          <AlphabeticalFilter
              selectedTags={selectedLetterTagsPublisher}
              selectOnClick={updateSelectedTagsPublisher}
              enabledTags={enabledTags}
            />
          </Box>{" "}
        </>
      )}
      <Box sx={webStyles.mainContainer}>
        <Box ml={"3%"} mr={"3%"}>
          <Grid container mt={"2%"}>
            <Grid item xs={3}>
              <Box sx={{ paddingBlock: 2 }}>
                <Typography
                  fontWeight={"700"}
                  fontSize={"20px"}
                  lineHeight={"24px"}
                  style={webStyles.resulteText}
                >
                  Results{" "}
                  {bookData?.hits ? bookData?.hits.toLocaleString("en-US") : ""}
                </Typography>
              </Box>
              {/* <CollectionsComponentOfBrowser
                  setMyPersonalLibrary={setMyPersonalLibrary}
                  setFilters={setFilters}
                  setCollectionFilter={setCollectionFilter}
                ></CollectionsComponentOfBrowser> */}

              <SearchFilterComponentOfBook
                setFilters={setFilters}
              ></SearchFilterComponentOfBook>
            </Grid>

            <Grid item xs={9}>
              {/* Header Div */}
           { tabIndex === 0 ?  <Books
                myPersonalLibrary={myPersonalLibrary}
                handleEnter={handleEnter}
                setHandleEnter={setHandleEnter}
                selectedTag={selectedTag}
                setSelectFilters={setSelectFilters}
                filters={filters}
                selectFilters={selectFilters}
                setatoz={setatoz}
                atoz={atoz}
                keyWords={keyWords}
                searchContainOrStartWith={searchContainOrStartWith}
                setSelectedTag={setSelectedTag}
                collectionFilter={collectionFilter}
                setMyPersonalLibrary={setMyPersonalLibrary}
              ></Books> : 
              <BookPublisher
                  // myPersonalLibraryPublisher={myPersonalLibraryPublisher}
                  // keyWords={keyWordsPublisher}
                  // filters={filtersPublisher}
                  selectedTagPublisher={selectedLetterTagsPublisher}
                  // atozPublisher={atozPublisher}
                  // searchContainOrStartWith={searchContainOrStartWith}
                  // publisher={publisher}
                  // setpublisher={setPublisher}
                  // setFilters={setFiltersPublisher}
                  // setMyPersonalLibraryPublisher={setMyPersonalLibraryPublisher}
                ></BookPublisher>}
            </Grid>
          </Grid>
          <IconButton
            aria-label="Scroll to Top"
            sx={{
              position: "fixed",
              top: "63vh",
              right: "2vw",
              transform: "translateY(-50%)",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              "&:hover": {
                border: "1px solid #D1D5DB",
                backgroundColor: "#FFF",
                color: "primary.main",
                boxShadow: 2,
              },
            }}
            onClick={() => goToTop()}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const webStyles = {
  button: {
    margin: "0.5%",
    // height: 47,
    paddingLeft: "4%",
    paddingRight: "4%",
    borderRadius: 2,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    backgroundColor: "#f5f5f5",
    flex: 1,
  },
  headerText: {
    fontFamily: "Lora",
    color: Colors.primary,
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  checkBox: {
    fontWeight: 400,
    fontSize: "0.875rem",
    color: Colors.primary,
    fontFmaily: "Helvetica Neue",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
    margin: "1% 3.5% 0% 3.5%",
  },
  textInputText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
  },
  resulteText: {
    fontFamily: "Helvetica Neue",
    fontsSyle: "normal",
    fontWeight: 700,
  },
};
