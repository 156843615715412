import dayjs from "dayjs";
import { Colors, dataTypes } from "./constants";
import { getDate } from "./date";
import { MathJax } from "better-react-mathjax";
import highlightKeyword from "./highlightKeyword";

export default function metaData(data: any) {
  try {
    if (!data) return;

    if (
      data?.data_type === dataTypes.journals ||
      data?.data_type === dataTypes.journalArticle ||
      data?.data_type === dataTypes.magazine ||
      data?.data_type === dataTypes.bookSeries ||
      data?.data_type === dataTypes.dspaceThesis ||
      data?.data_type === dataTypes.dspaceBookChapter ||
      data?.data_type === dataTypes.dspaceBook ||
      data?.data_type === dataTypes.dspaceDataTypeNull 
    ) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <MathJax>
            <div
              id="title"
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  data?.resource_name_tk
                    ? data?.resource_name_tk
                    : data?.publisher_name
                    ? data?.publisher_name
                    : "",
                  []
                ),
              }}
            />
          </MathJax>
          <span style={{ color: Colors.gray_700 }}>
            &nbsp;
            {data?.volumefrom ? ": VOL. " + data?.volumefrom : ""}
            {data?.volumefrom && data?.issuenumberfrom ? ", " : ""}{" "}
            {data?.issuenumberfrom ? "No. " + data?.issuenumberfrom : null}
            {data?.volumefrom &&
            getDate(data?.yearfrom, data?.monthfrom, data?.datefrom) &&
            !data?.issuenumberfrom
              ? ", "
              : ""}
            {data?.issuenumberfrom &&
            getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              ? ", "
              : null}
            {getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              ? getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              : ""}
            {`${
              data?.startpage?.length 
              // && !isNaN(data?.startpage) 
              ? ", PP:" : ""
            } ${
              data?.startpage?.length
              //  && !isNaN(data?.startpage)
                ? data?.startpage
                : ""
            } ${
              data?.endpage?.length 
              // && !isNaN(data?.endpage)
                ? "-" + data?.endpage
                : ""
            }`}
          </span>
        </div>
      );
    } else if (data?.data_type === dataTypes.dissertationThesis) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <MathJax>
            <div
              id="title"
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  data?.university_name ? data?.university_name : "",
                  []
                ),
              }}
            />
          </MathJax>
          {data?.university_name && data?.dateofpublication ? "," : ""}
          &nbsp;
          <span style={{ color: Colors.gray_700 }}>
            {data?.dateofpublication
              ? dayjs(data?.dateofpublication).format("MMM DD YYYY")
              : ""}
          </span>
        </div>
      );
    } else if (data?.data_type === dataTypes.conferenceProceedings) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <MathJax>
            <div
              id="title"
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  data?.resource_name ? data?.resource_name : "",
                  []
                ),
              }}
            />
          </MathJax>
          <span style={{ color: Colors.gray_700 }}>
            {data?.volumefrom ? ": VOL. " + data?.volumefrom : ""}
            {data?.volumefrom && data?.issuenumberfrom ? ", " : ""}{" "}
            {data?.issuenumberfrom ? "No. " + data?.issuenumberfrom : null}
            &nbsp;
            {data?.volumefrom &&
            getDate(data?.yearfrom, data?.monthfrom, data?.datefrom) &&
            !data?.issuenumberfrom
              ? ", "
              : ""}
            {data?.issuenumberfrom &&
            getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              ? ", "
              : null}
            {getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              ? getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              : ""}
            {`${
              data?.startpage?.length
                ? // && !isNaN(data?.startpage)
                  ", PP:"
                : ""
            } ${
              data?.startpage?.length
                ? //  && !isNaN(data?.startpage)
                  data?.startpage
                : ""
            } ${
              data?.endpage?.length
                ? //  && !isNaN(data?.endpage)
                  "-" + data?.endpage
                : ""
            }`}
          </span>
        </div>
      );
    } else if (data?.data_type === dataTypes.audioVideo) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <MathJax>
            <div
              id="title"
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  data?.publisher_name?.map((name: string) => name),
                  []
                ),
              }}
            />
          </MathJax>
          {data?.publisher_name?.length > 0 &&
          dayjs(data?.dateofpublication).format("MMM DD YYYY")
            ? ", "
            : ""}
          &nbsp;
          <span style={{ color: Colors.gray_700 }}>
            {data?.dateofpublication
              ? dayjs(data?.dateofpublication).format("MMM DD YYYY")
              : getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)}
          </span>
        </div>
      );
    } else if (data?.data_type === dataTypes.preprint || data?.data_type === dataTypes.patents) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <MathJax>
            <div
              id="title"
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  data?.publisher_name?.map((name: string) => name),
                  []
                ),
              }}
            />
          </MathJax>
          {data?.publisher_name?.length > 0 &&
          getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
            ? ", "
            : ""}
          &nbsp;
          <span style={{ color: Colors.gray_700 }}>
            {getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              ? getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
              : ""}
          </span>
        </div>
      );
    }
    else if (data?.data_type === dataTypes.dataset ||
      data?.data_type === dataTypes.books) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
            <MathJax>
            <div
              id="title"
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  data?.resource_name_tk
                    ? data?.resource_name_tk
                    : data?.publisher_name
                    ? data?.publisher_name
                    : "",
                  []
                ),
              }}
            />
          </MathJax>
          <span style={{ color: Colors.gray_700 }}>
            &nbsp;
            {data?.volumefrom ? ": VOL. " + data?.volumefrom : ""}
            {data?.volumefrom && data?.issuenumberfrom ? ", " : ""}{" "}
            {data?.issuenumberfrom ? "No. " + data?.issuenumberfrom : null}
            {data?.dateofpublication
              ? dayjs(data?.dateofpublication).format("MMM DD YYYY")
              : getDate(data?.yearfrom, data?.monthfrom, data?.datefrom)
    }
     {`${
              data?.startpage?.length 
              // && !isNaN(data?.startpage) 
              ? ", PP:" : ""
            } ${
              data?.startpage?.length
              //  && !isNaN(data?.startpage)
                ? data?.startpage
                : ""
            } ${
              data?.endpage?.length 
              // && !isNaN(data?.endpage)
                ? "-" + data?.endpage
                : ""
            }`}
          </span>
        </div>
      );
    }
  } catch (err) {
    console.log(err);
  }
}
