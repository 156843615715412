// import * as React from "react";
import React, { useEffect, useRef, useState } from "react";
import Tabs from "@mui/material/Tabs";
// import { Tab } from 'react-tabs';
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Colors } from "../utils/constants";
import api from "../api/endpoints";

const aboutUsData = [
  {
    title: "Truly e-journalportal:",
    description: "J-Gate excludes journals that are not available online..",
  },
  {
    title: "Portal with largest number of e-journals:",
    description:
      "As an e-journal portal, J-Gate presently hosts content from 64,747 e-journals.",
  },

  {
    title: "33,194 open-access journals:",
    description:
      "Captures and indexes articles from more than 33,194 open-access e-journals and maintains links to them.",
  },
  {
    title: "Links to 22,650,751 open-access articles:",
    description: "Provides links to over 22,650,751 open-access articles.",
  },
  {
    title: "Full-text link to largest number of publisher sites:",
    description: "Presently links to e-journals from 14,207 publishers.",
  },
  {
    title: "Union List linkage:",
    description:
      "This useful feature enables a J-Gate subscriber to check the availability of journals in local libraries.",
  },
  {
    title: "Customization features:",
    description:
      "Customization of the product refers to configuring the Product for a set of pre-defined features and functionalities that are provided in the Product.All customizations are configurable directly by the Library for its needs.",
  },
  {
    title: "Constant updating:",
    description:
      "The J-Gate site is updated every day, ensuring the freshness and reliability of content.",
  },
  {
    title: "Comprehensive journal classification:",
    description:
      "Three level hierarchical classification of journals is available.",
  },
  {
    title: "Easy-to-use search functionalities:",
    description: `OC and Database allows various search options for the user's convenience. The subscriber can choose to search by Title, Author, Author's Address/Institution, Keywords, etc.`,
  },
];

const aboutUsDetails = [
  {
    title: "J-Gate TOC (Table of Contents):",
    description: [
      "• 64,747 e-journals.",
      "• Browse TOC by Journals,Publishers and Subject Categories.",
      "• TOC for all journals is available in an uniform browsable format.",
      "• Basic bibliographic data with abstracts (where available).",
      "• Author address and e - mail where available.",
      "• Author Address and e-mail where available.",
      "• Link to full - text(both open - access and subscription).",
      "• Link to Union List for finding availability.",
      "• Daily updating.",
    ],
  },

  {
    title: "J-Gate Database:",
    description: [
      "• J-Gate Database consists of more extensive and comprehensive content than TOC.",
      "• b.Classification on three levels of subject category.",
      "• Subject - wise indexing.",
      "• Search by Title, Author, Subject Categories, Keywords, Year and any combination of these.",
      "• Provides basic bibliographic data with abstracts(where available).",
      "• Author Address and e- mail where available.",
      "• Link to full - text(both open - access and subscription).",
      "• Link to Union List for finding availability.",
      "• Daily updating with progressive accumulation.",
    ],
  },
  {
    title: "J-Gate@Consortia:",
    description: [
      "• J-Gate@Consortia acts as a search platform plus resource-sharing facility for the members of the consortium.",
      "• The product is customized for journals coverage limited to the Consortium subscribed journals as well as the member library subscribed journals.",
      "• This is a customized service developed and delivered to a library consortium for the exclusive access and use of its registered closed- user - group members only, who desire to share resources and infrastructure through a mutual agreement.",
    ],
  },
  {
    title: "J-Gate Publisher Partners",
    description: [
      "Scholarly and Technical publishers, as well as specific, mutual agreement driven partnerships with key publishers have been a major support and strength for J-Gate. Click here to see our current publisher partners actively participating in J-Gate.",
    ],
  },
  {
    title: "J-Gate@Consortia:",
    description: [
      "• J-Gate@Consortia acts as a search platform plus resource-sharing facility for the members of the consortium.",
      "• The product is customized for journals coverage limited to the Consortium subscribed journals as well as the member library subscribed journals.",
      "• This is a customized service developed and delivered to a library consortium for the exclusive access and use of its registered closed-user-group members only, who desire to share resources and infrastructure through a mutual agreement.",
    ],
  },
  {
    title: "Customization features:",
    description: [
      "Customization of the product refers to configuring the Product for a set of pre-defined features and functionalities that are provided in the Product.All customizations are configurable directly by the Library for its needs.",
    ],
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const AboutUsDetails = () => {
  const [value, setValue] = React.useState(0);

  const [isOpenAccessJournalsCount, setIsOpenAccessJournalsCount] = useState(0);
  const [isPeriodicJournalsCount, setIsPeriodicJournalsCount] = useState(0);
  const [isTotalArticleCount, setIsTotalArticleCount] = useState(0);
  const [isOpenAccessArticleCount, setIsOpenAccessArticleCount] = useState(0);
  const [isTotalPublishersCount, setIsTotalPublishersCount] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const hasopenAccessJournals = useRef(false);
  const hasPeriodicJournals = useRef(false);
  const hasTotalArticles = useRef(false);
  const hasOpenAccessArticles = useRef(false);
  const hasTotalPublishersCount = useRef(false);
  useEffect(() => {
    const fetchOpenAccessJournals = async () => {
      try {
        if (hasopenAccessJournals.current) return;
        hasopenAccessJournals.current = true;
        const response = await fetch(
          `${api.baseApi}/api/indexing/solr/resourcecore?fq__active_flag=true&fq__subjects_id_l2=(35 OR 34 OR 30 OR 21 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 31 OR 36 OR 38 OR 40 OR 28 OR 24 OR 25 OR 32 OR 29 )&fq__resource_type=(1 OR 2)&sort=resource_name_tk asc&fq__fulltext_ACL=(0)`
        );

        const data = await response.json();
        setIsOpenAccessJournalsCount(data?.data?.hits);
      } catch (error) {
        console.error("Error fetching Open Access Journals:", error);
      }
    };

    fetchOpenAccessJournals();
  }, []);

  useEffect(() => {
    const fetchTotalPeriodicJournals = async () => {
      try {
        if (hasPeriodicJournals.current) return;
        hasPeriodicJournals.current = true;
        const response = await fetch(
          `${api.baseApi}/api/indexing/solr/resourcecore?fq__active_flag=true&fq__subjects_id_l2=(35 OR 34 OR 30 OR 21 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 31 OR 36 OR 38 OR 40 OR 28 OR 24 OR 25 OR 32 OR 29 )&fq__resource_type=(1 OR 2)&sort=resource_name_tk asc`
        );

        const data = await response.json();
        setIsPeriodicJournalsCount(data?.data?.hits);
      } catch (error) {
        console.error("Error fetching Open Access Journals:", error);
      }
    };

    fetchTotalPeriodicJournals();
  }, []);

  useEffect(() => {
    const fetchOpenAccessArticles = async () => {
      try {
        if (hasOpenAccessArticles.current) return;
        hasOpenAccessArticles.current = true;
        const response = await fetch(
          `${api.baseApi}/api/indexing/solr/articlecore2?fq__fulltext=true&fq__active_flag= true`
        );

        const data = await response.json();
        setIsOpenAccessArticleCount(data?.data?.hits);
      } catch (error) {
        console.error("Error fetching Open Access Journals:", error);
      }
    };

    fetchOpenAccessArticles();
  }, []);

  useEffect(() => {
    const fetchTotalArticles = async () => {
      try {
        if (hasTotalArticles.current) return;
        hasTotalArticles.current = true;
        const response = await fetch(
          `${api.baseApi}/api/indexing/solr/articlecore2?fq__active_flag= true`
        );

        const data = await response.json();
        setIsTotalArticleCount(data?.data?.hits);
      } catch (error) {
        console.error("Error fetching Open Access Journals:", error);
      }
    };

    fetchTotalArticles();
  }, []);

  useEffect(() => {
    const fetchTotalPublishersCount = async () => {
      try {
        if (hasTotalPublishersCount.current) return;
        hasTotalPublishersCount.current = true;
        const response = await fetch(
          `${api.baseApi}/api/indexing/solr/resourcecore?page=1&rows=1&sort=publisher_name_tk asc&op_mode=and&fl=publisher_name_tk&group_field=publisher_name_tk&group_ngroups=true&fq__subjects_id_l2=(35 OR 34 OR 30 OR 21 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 31 OR 36 OR 38 OR 40 OR 28 OR 24 OR 25 OR 32 OR 29 )&fq__resource_type=(1 OR 2 )&fq__active_flag=true`
        );

        const data = await response.json();
        setIsTotalPublishersCount(
          data?.data?.grouped?.publisher_name_tk?.ngroups
        );
      } catch (error) {
        console.error("Error fetching Open Access Journals:", error);
      }
    };

    fetchTotalPublishersCount();
  }, []);

  const formatNumber = (number: any) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          // height: "380px",
          // height: "43%"
        }}
      >
        <Tabs
          orientation="vertical"
          centered={false}
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            ".MuiTabs-indicator": {
              left: 0,
            },
            borderLeft: 1,
            borderColor: "divider",
            alignItems: " self-start",
            justifyContent: " flex-start",
            // width: "514px",
            width: "32%",
            marginLeft: "2%",
            marginTop: "2%",
            // marginLeft: "70px"
          }}
        >
          <Tab sx={textStyle.tabs} label="What is J-Gate?" {...a11yProps(0)} />
          <Tab
            sx={textStyle.tabs}
            label="J-Gate Features and Benefits"
            {...a11yProps(1)}
          />
          <Tab
            sx={textStyle.tabs}
            label="J-Gate Products and Services"
            {...a11yProps(2)}
          />
          <Tab
            sx={textStyle.tabs}
            // label="J-Gate Publisher Partners"
            label="J-Gate Collaborations"
            {...a11yProps(3)}
          />
          <Tab
            sx={textStyle.tabs}
            label="J-Gate Infrastructure"
            {...a11yProps(4)}
          />
          <Tab sx={textStyle.tabs} label="Trial Access" {...a11yProps(5)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            // width={"680px"}
            width={"45%"}
          >
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              What is J-Gate?
            </Typography>
            <Typography width={726} sx={textStyle.subText}>
              J-Gate functions as a electronic gateway access to a vast array of
              e-journal content worldwide. Initially introduced in 2001 by
              Informatics India Limited, this platform grants users seamless
              {/* entry to millions of online journal articles sourced from 15,294 */}
              entry to millions of online journal articles sourced from{" "}
              {formatNumber(isTotalPublishersCount && isTotalPublishersCount)}{" "}
              publishers. Presently, J-Gate boasts an extensive database
              {/* comprising literature from 67,487 periodical publications */}
              comprising literature from{" "}
              {formatNumber(
                isPeriodicJournalsCount && isPeriodicJournalsCount
              )}{" "}
              periodical publications
              {/* e-journals, Conference-Proceedings, Book series) each with direct */}
              e-journals, Book series) each with direct links to full-text
              articles hosted on publisher websites. Additionally, J-Gate aims
              to provide supplementary services including online journal
              subscription, electronic document delivery, archival facilities,
              and more. Key features of J-Gate include:
            </Typography>
            <Typography width={726} sx={textStyle.subText}>
              <ol
                style={{ marginLeft: "50px" }}
                // style={{
                //   listStyle: "none",
                //   color: Colors.coolGray700,
                //   padding: "0px",
                // }}
              >
                <li>
                  Enhanced Content Coverage: Access a wider range of content
                  than ever before, ensuring you have all the information you
                  need at your fingertips.
                </li>
                <li>
                  {/* Table of Contents (TOC) - Covering 67, 487 periodical */}
                  Table of Contents (TOC) - Covering{" "}
                  {formatNumber(
                    isPeriodicJournalsCount && isPeriodicJournalsCount
                  )}{" "}
                  periodical publications.
                </li>
                <li>
                  Database - Offering a comprehensive searchable database
                  {/* housing a staggering 83,565,438 articles, with over 40,000 new */}
                  housing a staggering{" "}
                  {formatNumber(isTotalArticleCount && isTotalArticleCount)}{" "}
                  articles, with over 60,000 new articles incorporated daily.
                </li>
                <li>
                  New Search & Browse Experience: Our latest technology provides
                  a seamless and intuitive search and browse experience, making
                  it easier than ever to find what you're looking for.
                </li>
                <li>
                  More Full text Coverage: Dive deeper into content with
                  expanded full-text coverage, allowing you to explore topics
                  comprehensively.
                </li>
                <li>
                  Mobile Applications (Android & iOS): Take your experience on
                  the go with our mobile applications, available for both
                  Android and iOS devices. Access your favorite features
                  anytime, anywhere.
                </li>
              </ol>
            </Typography>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography fontWeight={700} fontSize={28} sx={textStyle.headercolor}>
            J-Gate Features and Benefits
          </Typography>
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            // width={"680px"}
            width={"45%"}
          >
            {/* {aboutUsData?.map((data: any, index) => (
              <>
                <Box
                  key={index}
                  width={"680px"}
                  // width={"45%"}
                >
                  <Typography sx={textStyle.headingText}>
                    {data.title}
                  </Typography>
                  <Typography sx={textStyle.subText}>
                    {data.description}
                  </Typography>
                </Box>
              </>
            ))} */}

            <Typography width={726} sx={textStyle.subText}>
              <ol style={{ marginLeft: "20px" }}>
                <li>
                  Dedicated e-Journal Portal: J-Gate exclusively encompasses
                  journals accessible online, ensuring a truly digital
                  repository
                </li>
                <li>
                  Largest Repository of e-Journals: As an e-journal platform,
                  {/* J-Gate currently hosts content from an impressive 67,487 */}
                  J-Gate currently hosts content from an impressive{" "}
                  {formatNumber(
                    isPeriodicJournalsCount && isPeriodicJournalsCount
                  )}{" "}
                  periodic publications
                </li>
                <li>
                  Open-Access Journals: Curates and indexes articles from over
                  {/* 35,479 open-access e-journals, maintaining direct links to */}{" "}
                  {formatNumber(
                    isOpenAccessJournalsCount && isOpenAccessJournalsCount
                  )}{" "}
                  open-access e-journals, maintaining direct links to them.
                </li>
                <li>
                  Access to Millions of Open-Access Articles: Provides access to
                  {/* a vast collection of over 27,194,042 open-access articles. */}
                  a vast collection of over{" "}
                  {formatNumber(
                    isOpenAccessArticleCount && isOpenAccessArticleCount
                  )}{" "}
                  open-access articles.
                </li>
                <li>
                  Extensive Publisher Connectivity: Offers full-text links to
                  {/* documents from a broad network of 15,294 publishers. */}
                  documents from a broad network of{" "}
                  {formatNumber(
                    isTotalPublishersCount && isTotalPublishersCount
                  )}{" "}
                  publishers.
                </li>
                <li>
                  Union List Integration: The convenient Union List feature
                  allows J-Gate subscribers to verify journal availability in
                  local libraries.
                </li>
                <li>
                  Tailored Customization Options: Customization capabilities
                  enable libraries to configure the product according to their
                  specific needs, utilizing a range of predefined features and
                  functionalities.
                  <ol type="i" style={{ marginLeft: "50px" }}>
                    <li>
                      Configure Journal subscriptions of the Institute to get
                      the customization of separate collection named “My Library
                      Collection”
                    </li>
                    <li>
                      Search & Browse only within the Library collection to
                      ensure to get Full Text of all the documents subscribed by
                      the Institute
                    </li>
                    <li>
                      Search & Browse only within Open access collection of
                      J-Gate for the subscribed package of J-Gate
                    </li>
                    <li>
                      Mark and collect your favourite journals / documents in
                      your personal library and search and browse within the
                      personal library
                    </li>
                    <li>
                      Mark for alert and get the regular updates for your
                      interested topic / journals
                    </li>
                    <li>
                      Save your search queries and receive updated results for
                      future reference
                    </li>
                  </ol>
                </li>
                <li>
                  Explore customized author searches tailored to your
                  preferences
                </li>
                <li>
                  Utilize the search builder to conduct criteria-based searches
                  for precise analytics and refined outcomes
                </li>
                <li>
                  Visualize filters through graphical representations for a
                  rapid overview of search outcomes.
                </li>
              </ol>
            </Typography>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Typography fontWeight={700} fontSize={28} sx={textStyle.headercolor}>
            J-Gate Products and Services
          </Typography>
          <Typography sx={textStyle.headingText}>J-Gate Database</Typography>
          <Typography width={726} sx={textStyle.subText}>
            <ol style={{ marginLeft: "20px" }}>
              <li>
                Provides an extensive range of content encompassing diverse
                document types
              </li>
              <li>Classifies subjects into three levels.</li>
              <li>Provides subject-wise indexing.</li>
              <li>
                Searchable by Title, Author, Subject Categories, Keywords, Year,
                Journal, Institutions, DOI etc.
              </li>
              <li>
                Retrieve the ORCID information of the author and access further
                details about the author from their ORCID profile
              </li>
              <li>Includes basic bibliographic data with abstracts.</li>
              <li>Author addresses and emails provided where available.</li>
              <li>
                Direct links to full-text articles (both open-access and
                subscription).
              </li>
              <li>
                Union List link for availability checks in the form of “Find in
                Library”.
              </li>
              <li>
                Access to alternative full-text versions from preprint sources
                for articles behind paywalls
              </li>
              <li>
                Retrieve information on retracted articles for the obtained
                results.
              </li>
              <li>
                Retrieve information about articles mapping datasets for the
                obtained results.
              </li>
              <li>
                Implement a filter on the acquired results to specifically
                showcase early online articles, if they are accessible for the
                provided search term
              </li>
              <li>Daily updates with progressive accumulation.</li>
              <li>
                Browse and apply filters to the results to specifically view
                articles from journals indexed in Web of Science, Scopus, DOAJ
                etc
              </li>
              <li>Tailor the collection to your preferences by </li>
              <ol type="i" style={{ marginLeft: "50px" }}>
                <li>
                  configuring subscriptions under 'My Library Collections'
                </li>
                <li>
                  Personalize your collection by bookmarking your favorite
                  journals or searched documents into your personal library for
                  future search and browsing endeavors.
                </li>
              </ol>
            </ol>
          </Typography>
          <Typography sx={textStyle.headingText}>J-Gate@Consortia</Typography>
          <Typography width={726} sx={textStyle.subText}>
            <ol style={{ marginLeft: "20px" }}>
              <li>
                Serves as a search platform and resource-sharing hub for
                consortium members.
              </li>
              <li>
                Customized coverage restricted to Consortium and member library
                subscribed journals.
              </li>
              <li>
                Exclusive service tailored for registered closed-user-group
                members of the consortium.
              </li>
              <li>
                Designed for resource and infrastructure sharing among members
                through a mutual agreement.
              </li>
            </ol>
          </Typography>

          {/* <Box alignItems={"center"} justifyContent={"center"} width={"680px"}>
            {aboutUsDetails?.map((data, index) => (
              <Box key={index}>
                <Typography sx={textStyle.headingText}>{data.title}</Typography>
                {data.description?.map((item, i) => (
                  <ul key={i} style={{ padding: "0px" }}>
                    <li style={textStyle.subText}>{item}</li>
                  </ul>
                ))}
              </Box>
            ))}
          </Box> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box alignItems={"center"} justifyContent={"center"} width={"680px"}>
            {/* <Typography fontWeight={700} fontSize={28}>
              J-Gate Publisher Partners
            </Typography>
            <Typography sx={textStyle.subText}>
              Scholarly and Technical publishers, as well as specific, mutual
              agreement driven partnerships with key publishers have been a
              major support and strength for J-Gate. Click here to see our
              current publisher partners actively participating in J-Gate.
            </Typography> */}
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              J-Gate Collaborations
            </Typography>
            <Typography width={726} sx={textStyle.subText}>
              J-Gate owes much of its success and robustness to its partnerships
              with scholarly, technical, and key publishers and third party
              aggregators. These collaborations, often established through
              mutual agreements, form a cornerstone of J-Gate's strength. Visit
              our page to explore our current publisher partners who actively
              contribute to J-Gate.
            </Typography>
            <br />
            <Typography sx={textStyle.subText}>
              <a
                href="https://jgateplus.com/search/footer-html/PublisherPartners.jsp"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "5px", color: "#7852fb" }}
              >
                Click here
              </a>
              <Typography component="span" width={726} sx={textStyle.subText}>
                to see our current collaborating partners actively participating
                in J-Gate.
              </Typography>
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box alignItems={"center"} justifyContent={"center"} width={"680px"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              J-Gate Infrastructure
            </Typography>
            <Typography sx={textStyle.subText}>
              {/* <ul style={{ paddingLeft: "20px" }}>
                <li>7-days/24 hours access.</li>
                <li>High-speed in-house Internet infrastructure.</li>
                <li>
                  Content capturing and conversion system with high automation
                  ensures high quality content aggregation.
                </li>
                <li>In-house software development team.</li>
                <li>
                  Safe and reliable high-capacity storage system for content
                  storage.
                </li>
              </ul> */}
              <ol style={{ marginLeft: "20px" }}>
                <li>Accessible 24/7, 7 days a week.</li>
                <li>Utilizes high-speed, in-house internet infrastructure.</li>
                <li>
                  Employs a content capturing and conversion system with high
                  automation for top-quality content aggregation.
                </li>
                <li>Features an in-house software development team.</li>
                <li>
                  Relies on a secure and dependable high-capacity storage system
                  for content storage.
                </li>
              </ol>
            </Typography>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <Box alignItems={"center"} justifyContent={"center"} width={"680px"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              Trial Access
            </Typography>
            <Typography sx={textStyle.subText}>
              Informatics will be happy to set up trial access to J-Gate and
              present the benefits of J-Gate customized services to your users
              and decision makers in your campus. Please{" "}
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUMjdYVUFKRVM3NFU5U0FJVU1ENzhOT0lZNy4u"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#7852fb" }}
              >
                Click here
              </a>{" "}
              to request a trial.
            </Typography>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};

export default AboutUsDetails;

const textStyle = {
  headingText: {
    fontWeight: 700,
    fontSize: "18px",
    marginTop: "18px",
  },

  subText: {
    fontWeight: 500,
    fontSize: "18px",
    marginTop: "2px",
    color: Colors.coolGray700,
    listStyle: "none",
  },

  tabs: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Helvetica Neue",
    textAlign: "left",
    alignItems: "flex-start",
  },
  headercolor: {
    color: "#7852fb",
  },
};
