import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../utils/constants";
import IconButton from "@mui/material/IconButton";
import RSSIcon from "@mui/icons-material/RssFeed";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import { searchStyles as style, CustomTooltip } from "./Search.style";
import { useGetMySavedSearch } from "../../../api/MyLibrary/SavedSearch.api";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useEffect, useState } from "react";
import RSSAlertModal from "../MyAlerts/RssAlert.modal";
import { GetMySavedSearchData } from "../../../types/GetSavedSearch.types";
import SavedSearchMenu from "./SavedSearchMenu";
import QueryBuilder from "./QueryBuilder";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { stringSanitizer } from "../../SearchHistory/SearchHistory";
import {
  clearSearchTerm,
  updateAdvSearchQuery,
  updateAll,
  updateDataType,
  updateFullText,
  updateNewSearchStatus,
  updatePage,
  updateRow,
  updateSearchResults,
  updateSearchTerm,
} from "../../../store/slice/searchSlice";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateAuthor,
  updateConsortiaCollection,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateEarlyOnline,
  updateInstitution,
  updateJournal,
  updateJournalRank,
  updateMonthFrom,
  updateMyLibraryCollection,
  updateOpenAccess,
  updatePersonalLibrary,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateSort,
  updateSourceType,
  updateSpeaker,
  updateSubject,
  updateYearFrom,
} from "../../../store/slice/filterSlice";
import { queryConstructor } from "../../../api/formQuery";
import {
  basicSearchForCounts,
  basicSearchMain,
} from "../../../api/Search/BasicSearch.api";
import { updateQuery } from "../../../store/slice/queryForCountSlice";
import { getQueryParams } from "../../../utils/helper";
import {
  checkArticleExistance,
  findAlertTypeSetting,
  linkToDisplay,
} from "../../SearchHistory/HistoryTable";
import { clearAllSetSearch } from "../../../store/slice/setSearch";
import dayjs from "dayjs";
import { updateQueries } from "../../../store/slice/appliedQueries";
import {
  clearAuthorSearchTerm,
  updateAuthorSearchResults,
  updateAuthorSort,
  clearAllFilters as clearAllAuthorFilters,
} from "../../../store/slice/authorFinderSlice";
import { usagelogApi } from "../../../api/usageReportApi";
import {
  getPersonalLibraryCollection,
  personalCoreCount,
} from "../../../api/Search/CollectionApi";

const SavedSearch = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);
  const customerData = useAppSelector(
    ({ customer }) => customer.customerDetails
  );
  let informaticscustomerId = sessionStorage.getItem("informaticscustomer_id");

  const [selectedRow, updateSelectedRow] = useState<GetMySavedSearchData>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRss, setOpenRss] = useState(false);
  const [rssData, setRssData] = useState();
  const [isSearching, setIsSearching] = useState(false);

  const open = Boolean(anchorEl);

  const { data: mySavedSearch, refetch } = useGetMySavedSearch(userId, true);

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const closeMenu = () => {
    setAnchorEl(null);
  };

  function openMenu(event: React.MouseEvent<HTMLElement>, row: any) {
    setAnchorEl(event.currentTarget);
    updateSelectedRow(row);
  }

  function openRssMenu(row: any) {
    setOpenRss(true);
    setRssData(row);
  }

  function closeRssModal() {
    setOpenRss(false);
  }

  useEffect(() => {
    dispatch(clearAllSetSearch());
    dispatch(clearAllFilters());
    dispatch(clearAuthorSearchTerm());
    dispatch(clearAllAuthorFilters());
  }, []);

  const logBasicSearchUsageData = (usageActionID: any, SearchTypeId?: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      SearchTypeId,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };
  const handleKeywordClick = async (
    dirtyString: string,
    searchURL: string,
    searchType: string
  ) => {
    if (isSearching) return;
    const searchTerm = stringSanitizer(dirtyString);

    if (!searchTerm) {
      dispatch(clearSearchTerm());
      setIsSearching(false);
      return;
    }

    const advanceSearchTerms = [
      "resourcemaster_id",
      "title_fz",
      "yearfrom",
      "keywords_fz",
      "author_address_fz",
      "issn",
      "articledoi",
      "author_all_fz",
      "resource_name_tk",
    ];

    let isAdvanceSearchTerm = advanceSearchTerms.some((term) =>
      new RegExp(`\\b${term}\\b`).test(dirtyString)
    );

    const anotherSetOfAdvSearchTerms = [
      "(resourcemaster_id",
      "(title_fz",
      "(yearfrom",
      "(keywords_fz",
      "(author_address_fz",
      "(issn",
      "(articledoi",
      "(author_all_fz",
      "(resource_name_tk",
      "(titleKeywordsAbs",
    ];

    try {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearAllFiltersValue());
      dispatch(clearPublicationFilter());

      const missingQueryObj = {
        rows: 15,
        op_mode: "and",
      };

      const fullTextQuery = await queryConstructor({
        "fq__(fulltext": `true OR acl_group=(${informaticscustomerId}))`,
        ...missingQueryObj,
        sort: "dateofpublication desc",
      });
      const allTextQuery = await queryConstructor({
        ...missingQueryObj,
        sort: "dateofpublication desc",
      });

      // replace the "&" with "~_~"
      let replacedQuery = searchURL.replace(/ & /g, ` ~_~ `);
      let fqProfileQuery = `&fq__profileuser_id=${profileData.user_id}`;

      let actualFullTextQuery = fullTextQuery + "&" + replacedQuery;
      let actualAllextQuery =
        replacedQuery.replace(
          `&fq__(fulltext=true OR acl_group=(${customerData?.informaticscustomer_id}))`,
          ""
        ) +
        "&" +
        allTextQuery;

      const queryParams = getQueryParams(actualAllextQuery);
      let countApiCall, recordApiCall;

      if (queryParams?.fq__profileuser_id) {
        countApiCall = personalCoreCount;
        recordApiCall = getPersonalLibraryCollection;
      } else {
        countApiCall = basicSearchForCounts;
        recordApiCall = basicSearchMain;
      }

      actualFullTextQuery = actualFullTextQuery.replace(fqProfileQuery, "");
      actualAllextQuery = actualAllextQuery.replace(fqProfileQuery, "");

      const fullTextData = await recordApiCall(actualFullTextQuery);
      const allText = await countApiCall(actualAllextQuery);
      const fulltext = await countApiCall(actualFullTextQuery);

      await checkArticleExistance(fullTextData?.docs, userId, dispatch);
      await findAlertTypeSetting(fullTextData?.docs, userId, dispatch);
      await linkToDisplay(fullTextData?.docs, customerData, dispatch);

      dispatch(
        updateQuery({
          fullTextQuery: actualFullTextQuery,
          allTextQuery: actualAllextQuery,
        })
      );

      const queryParamsKeys = Object.keys(queryParams);
      let isAdv = anotherSetOfAdvSearchTerms.some((term) =>
        queryParamsKeys.includes(term)
      );
      isAdvanceSearchTerm = isAdvanceSearchTerm || isAdv;

      updateQueryParamsToRedux(queryParams, dirtyString, isAdvanceSearchTerm);

      dispatch(updateSearchResults(fullTextData));
      dispatch(updateFullText(true));
      dispatch(updateAll(false));
      dispatch(updateRow(15));
      dispatch(updatePage(1));
      dispatch(updateSort("dateofpublication desc"));
      dispatch(updateNewSearchStatus(true));

      if (isAdvanceSearchTerm) {
        let advSearchObj: any = {};
        let arr = [...advanceSearchTerms, ...anotherSetOfAdvSearchTerms];

        arr.forEach((term) => {
          if (queryParams[term]) {
            advSearchObj[term] = queryParams[term];
          }
        });

        const advQuery = await queryConstructor(advSearchObj);
        let decodedQuery = decodeURIComponent(advQuery);
        dispatch(updateAdvSearchQuery(decodedQuery));
      }

      setIsSearching(false);
      const titleKeywordsAbs = queryParams?.titleKeywordsAbs;
      let x = dirtyString.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      if (searchType === "Author Search" && fullTextData?.docs) {
        dispatch(updateAuthorSearchResults(fullTextData));
        navigate("/authorfindersearch?searchterm=" + searchTerm);
        dispatch(updateAuthorSort("dateofpublication desc"));
        return;
      }

      if (fullTextData?.docs) {
        navigate("/basicSearchScreen?searchterm=" + searchTerm, {
          state: {
            searchTerm: x,
            fullTextQuery: fullTextQuery,
            allQuery: searchURL,
            allCount: allText?.hits,
            fullCount: fulltext?.hits,
          },
        });
      }
    } catch (error) {
      setIsSearching(false);
    }
  };

  const updateQueryParamsToRedux = (
    queryParams: any,
    dirtyString: any,
    isAdvTerm?: boolean
  ) => {
    // Updating search term
    const titleKeywordsAbs = queryParams?.titleKeywordsAbs;

    titleKeywordsAbs && dispatch(updateSearchTerm(titleKeywordsAbs));
    if (
      queryParams?.author_address_fz ||
      queryParams?.title_fz ||
      queryParams?.keywords_fz ||
      queryParams?.author_all_fz ||
      queryParams?.issn ||
      queryParams?.articledoi ||
      queryParams?.resourcemaster_id ||
      queryParams?.yearfrom ||
      queryParams?.resource_name_tk ||
      isAdvTerm
    ) {
      let x = dirtyString.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      dispatch(
        updateAllFilter({
          key: "search-term",
          value: titleKeywordsAbs,
        })
      );
    } else {
      dispatch(
        updateAllFilter({
          key: "search-term",
          value: titleKeywordsAbs,
        })
      );
    }
    dispatch(
      updateQueries({
        key: "searchTerm",
        value: titleKeywordsAbs,
      })
    );

    if (queryParams?.fq__fulltext) {
      dispatch(updateOpenAccess(true));
    }

    // Updating publication date filter
    if (queryParams?.fq__early_online) {
      dispatch(updateEarlyOnline(true));
      dispatch(
        updateQueries({
          key: "earlyOnline",
          value: true,
        })
      );
    }

    if (queryParams?.fq__yearfrom) {
      dispatch(updateYearFrom(queryParams.fq__yearfrom));
      dispatch(
        updateQueries({
          key: "currentYear",
          value: queryParams.fq__yearfrom,
        })
      );
    }

    if (queryParams?.fq__dateofpublication) {
      dispatch(updateMonthFrom(queryParams.fq__dateofpublication));
    }

    // Updating Collection filter
    if (queryParams?.fq__acl_group) {
      if (queryParams.fq__acl_group === customerData?.my_library_filter) {
        dispatch(updateMyLibraryCollection(true));
        dispatch(
          updateQueries({
            key: "myLibraryCollection",
            value: true,
          })
        );
      } else if (queryParams.fq__acl_group === customerData?.consortia_filter) {
        dispatch(updateConsortiaCollection(true));
        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }

    if (queryParams?.fq__profileuser_id) {
      dispatch(updatePersonalLibrary(true));
      dispatch(
        updateQueries({
          key: "myPersonalLibraryCollection",
          value: true,
        })
      );
    }

    // Updating resource type
    if (queryParams?.fq__resource_type) {
      let arr = queryParams?.fq__resource_type
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .split(" OR ")
        ?.map((str: string) => Number(str));

      dispatch(updateDataType(arr));
    }

    // Updating Filters
    if (queryParams?.fq__data_type) {
      // Document type filter
      const dataType = queryParams?.fq__data_type
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateSourceType(dataType));
      dispatch(
        updateQueries({
          key: "data_type",
          value: queryParams?.fq__data_type,
        })
      );
      dispatch(
        updateAllFilter({
          key: "data_type",
          value: queryParams?.fq__data_type,
        })
      );
    }

    if (queryParams?.fq__subjects_name_l3) {
      let subjectQuery = queryParams?.fq__subjects_name_l3
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });

      // Update subject filter
      dispatch(updateSubject(subjectQuery));
      dispatch(
        updateQueries({
          key: "subject",
          value: queryParams?.fq__subjects_name_l3,
        })
      );
      dispatch(
        updateAllFilter({
          key: "subject",
          value: queryParams?.fq__subjects_name_l3,
        })
      );
    }

    if (queryParams?.fq__authors_tk) {
      const authorParam = queryParams?.fq__authors_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateAuthor(authorParam));
      dispatch(
        updateQueries({
          key: "author",
          value: queryParams?.fq__authors_tk,
        })
      );
      dispatch(
        updateAllFilter({
          key: "author",
          value: queryParams?.fq__authors_tk,
        })
      );
    }

    if (queryParams?.fq__speakers) {
      const speakerParam = queryParams?.fq__speakers
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateSpeaker(speakerParam));
      dispatch(
        updateQueries({
          key: "speaker",
          value: queryParams?.fq__speakers,
        })
      );
      dispatch(
        updateAllFilter({
          key: "speaker",
          value: queryParams?.fq__speakers,
        })
      );
    }

    if (queryParams?.fq__guide_name_tk) {
      const guideParam = queryParams?.fq__guide_name_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearch(guideParam));
      dispatch(
        updateQueries({
          key: "research",
          value: queryParams?.fq__guide_name_tk,
        })
      );
      dispatch(
        updateAllFilter({
          key: "research",
          value: queryParams?.fq__guide_name_tk,
        })
      );
    }

    if (queryParams?.fq__researcher_tk) {
      const researcherParam = queryParams?.fq__researcher_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearcher(researcherParam));
      dispatch(
        updateQueries({
          key: "researcher",
          value: queryParams?.fq__researcher_tk,
        })
      );
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: queryParams?.fq__researcher_tk,
        })
      );
    }

    if (queryParams?.fq__journal_name) {
      const journalParam = queryParams?.fq__journal_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournal(journalParam));
      dispatch(
        updateQueries({
          key: "journal",
          value: queryParams?.fq__journal_name,
        })
      );
      dispatch(
        updateAllFilter({
          key: "journal",
          value: queryParams?.fq__journal_name,
        })
      );
    }

    if (queryParams?.fq__primary_publisher_country) {
      const primaryPublisherParam = queryParams?.fq__primary_publisher_country
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublication(primaryPublisherParam));
      dispatch(
        updateQueries({
          key: "countryOfPublication",
          value: queryParams?.fq__primary_publisher_country,
        })
      );
      dispatch(
        updateAllFilter({
          key: "countryOfPublication",
          value: queryParams?.fq__primary_publisher_country,
        })
      );
    }

    if (queryParams?.fq__publisher_name) {
      const publisherNameParam = queryParams?.fq__publisher_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updatePublisher(publisherNameParam));
      dispatch(
        updateQueries({
          key: "publisher",
          value: queryParams?.fq__publisher_name,
        })
      );
      dispatch(
        updateAllFilter({
          key: "publisher",
          value: queryParams?.fq__publisher_name,
        })
      );
    }

    if (queryParams?.fq__author_address) {
      const authorAddressParam = queryParams?.fq__author_address
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateInstitution(authorAddressParam));
      dispatch(
        updateQueries({
          key: "institutions",
          value: queryParams?.fq__author_address,
        })
      );
      dispatch(
        updateAllFilter({
          key: "institutions",
          value: queryParams?.fq__author_address,
        })
      );
    }

    if (queryParams?.fq__filter_metrix) {
      const journalRankParam = queryParams?.fq__filter_metrix
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournalRank(journalRankParam));
      dispatch(
        updateQueries({
          key: "journalRank",
          value: queryParams?.fq__filter_metrix,
        })
      );
      dispatch(
        updateAllFilter({
          key: "journalRank",
          value: queryParams?.fq__filter_metrix,
        })
      );
    }

    if (queryParams?.fq__author_country_name) {
      const countryOfPublishingAuthorParam =
        queryParams?.fq__author_country_name.split("OR").map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublishingAuthor(countryOfPublishingAuthorParam));
      dispatch(
        updateQueries({
          key: "countryOfPublishingAuthor",
          value: queryParams?.fq__author_country_name,
        })
      );
      dispatch(
        updateAllFilter({
          key: "countryOfPublishingAuthor",
          value: queryParams?.fq__author_country_name,
        })
      );
    }
  };
  const handleUsageLog = (data: any) => {
    const serachTypeId =
      data === "Basic Search"
        ? 1
        : data === "Advanced Search"
        ? 2
        : data === "Author Search"
        ? 3
        : data === "Browse By"
        ? 4
        : data === "Library Search"
        ? 5
        : data === "Consortium Search"
        ? 6
        : data === "Search History"
        ? 7
        : null;
    logBasicSearchUsageData(96, serachTypeId);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>#</TableCell>
              <TableCell>Alert Topic</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Search Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mySavedSearch &&
              mySavedSearch?.map((row, i) => (
                <TableRow
                  key={row.search_id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleKeywordClick(
                        row.SearchTerms,
                        row.search_url,
                        row?.search_type
                      );
                      setIsSearching(true);
                      handleUsageLog(row?.search_type);
                    }}
                    sx={{
                      cursor: "pointer",
                      width: "50% !important",
                    }}
                  >
                    {row.alert_topic + " "}(
                    {row.result_count?.toLocaleString("en-US")})
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {dayjs(row.createddate).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={style.searchType}>
                        {row.search_type === "Advanced Search"
                          ? "Search Builder"
                          : row.search_type}

                        <CustomTooltip
                          title={row?.mouse_over_data}
                          arrow
                          placement="top"
                        >
                          <IconButton size="small">
                            <InfoIcon fontSize="inherit" />
                          </IconButton>
                        </CustomTooltip>
                      </Box>

                      <Box sx={style.searchTypeOptions}>
                        <Tooltip
                          title={
                            row.alert_type > 0 ? "Modify Alert" : "Set Alert"
                          }
                        >
                          <IconButton
                            aria-label="rss"
                            onClick={() => openRssMenu(row)}
                            sx={{
                              "&:hover": {
                                color: "#F48120",
                              },
                              color: row?.alert_type > 0 ? "#F48120" : "",
                            }}
                          >
                            <RSSIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={(e) => openMenu(e, row)}>
                          <MoreIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <SavedSearchMenu
          open={open}
          onClose={closeMenu}
          anchor={anchorEl}
          currentAlert={selectedRow}
          refetch={refetch}
        />
        <RSSAlertModal
          show={openRss}
          onClose={closeRssModal}
          alertData={rssData}
          isSavedSearch
          refetch={refetch}
          emailLog={102}
          rssLog={103}
          setOpenRss={setOpenRss}
        />
      </TableContainer>

      {/* {mySavedSearch && <QueryBuilder searchHistory={mySavedSearch} />} */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSearching}
        onClick={undefined}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default SavedSearch;
