import { formatString, queryConstructor } from "../../api/formQuery";
import { basicSearch, dSpaceSearchQuery } from "../../api/Search/BasicSearch.api";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import { CustomerDetailsType, SaverArticlesType } from "../../types/interface";
import {
  getOpacData,
  getPersonalLibraryCollection,
} from "../../api/Search/CollectionApi";
import { AppThunk } from "../../store";
import { updateLoadingState } from "../../store/slice/filterSlice";
import {
  updateAlertSettingData,
  updateIsSettled,
  updateLinkToDisplay,
  updateLogSearch,
  updateSavedArticles,
  updateSearchResults,
} from "../../store/slice/searchSlice";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { getAlertSettings } from "../../api/MyLibrary/TOCAlerts.api";
import { setSearch } from "../../api/Search/SetSearch";
import { updateSetSearchQuery } from "../../store/slice/setSearch";
import { getParamsByName } from "../../utils/helper";
import { useAppSelector } from "../../store/hooks";

export const onSearch =
  (
    customQuery?: string,
    userDetail?: CustomerDetailsType | null,
    logSearchHistory = false
  ): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(updateLoadingState(true));

    const searchReducer = getState().searchReducer;
    const filterReducer = getState().filterReducer;
    const customerData = getState().customer.customerDetails;
    const user_id = getState().login.user_id;
    const searchKeys = Object.keys(searchReducer);
    const filterKeys = Object.keys(filterReducer);
    const setSearchReducer = getState().setSearch;

    let sessionKey = sessionStorage.getItem("session_key");
    let userId = sessionStorage.getItem("user_id");
    let informaticscustomerId = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const customerId = customerData?.informaticscustomer_id;
    const consortia_virtualid = customerData?.consortia_virtualid;
    const urlSearchTerm = getParamsByName("searchterm") || getParamsByName("q");
    const isDSpace = searchReducer.dSpace;

    dispatch(updateIsSettled(false));

    let searchQueryObj: any = {};
    let queryObj: any = {};
    let dataType: any = {};

    searchQueryObj.customerid = informaticscustomerId;
    searchQueryObj.sessionid = sessionKey;
    searchQueryObj.profileuserid = userId;

    //=============== Search Query ===================================//
    if (searchReducer?.logSearch) {
      searchQueryObj.sessionid = sessionStorage.getItem("session_key");
      searchQueryObj.profileuserid = sessionStorage.getItem("user_id");
      searchQueryObj.datsearchtype_id = "1";
      searchQueryObj.customerid = sessionStorage.getItem(
        "informaticscustomer_id"
      );
      dispatch(updateLogSearch(false));
    }

    if (searchKeys.includes("searchTerm")) {
      const includesTitleOrKeywords =
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/.test(
          searchReducer?.searchTerm.join()
        );
      if (includesTitleOrKeywords) {
        const keywordString = searchReducer?.searchTerm
          .toString()
          .split(",")
          .join(" ");
        let modifiedSearchTerm = keywordString.split(/:(.*)/);
        searchQueryObj[modifiedSearchTerm[0]] = modifiedSearchTerm[1];
      } else {
        searchQueryObj.titleKeywordsAbs = searchReducer?.searchTerm.length?searchReducer?.searchTerm:urlSearchTerm;
      }
    }

   
    if (searchKeys.includes("page")) {
      searchQueryObj.page = searchReducer.page;
    }

    if (searchKeys.includes("rows")) {
      searchQueryObj.rows = searchReducer.rows;
    }
    if (searchKeys.includes("authorName")) {
      searchQueryObj.authors = searchReducer.authorName;
    }

    if (searchKeys.includes("dataType")) {
      if (
        searchReducer.dataType.length &&
        !searchReducer.dataType.includes(0)
      ) {
        dataType.fq__resource_type = searchReducer.dataType;
      }
    }

    if (searchReducer.dataType.includes(0)) {
      dataType.fq__resource_type = searchReducer.defaultDataType;
    }

    if (searchKeys.includes("subjectType")) {
      if (searchReducer.subjectType.length) {
        dataType.fq__subjects_id_l2 = searchReducer.subjectType;
      }
    }

    searchQueryObj.op_mode = "and";

    if (searchReducer.advanceSearchQuery) {
      let refineSearchTerms = searchReducer.searchTerm;
      let query = "";
      if (refineSearchTerms.length) {
        refineSearchTerms.forEach((term) => {
          query += " AND " + term;
        });
      }
      let y = searchReducer.advanceSearchQuery.indexOf("=");
      let leftObj = searchReducer.advanceSearchQuery.slice(0, y);
      searchQueryObj[leftObj] =
        `${searchReducer.advanceSearchQuery.slice(y + 1)}` + query;
    }

    if(searchReducer.search_type_id) {
      searchQueryObj.datsearchtype_id = searchReducer.search_type_id
    }
    //================================================================//

    let searchQuery = await queryConstructor(searchQueryObj, "AND", true);
    let dataTypeQuery = await queryConstructor(dataType);
    //=============== FIlter Query ===================================//

    if (filterKeys.includes("subject")) {
      queryObj.fq__subjects_name_l3 = filterReducer["subject"];
    }
    if (filterKeys.includes("sourceType")) {
      queryObj.fq__data_type = filterReducer["sourceType"];
    }
    if (filterKeys.includes("author")) {
      queryObj.fq__authors_tk = filterReducer["author"];
    }
    if (filterKeys.includes("speaker")) {
      queryObj.fq__speakers = filterReducer["speaker"];
    }
    if (filterKeys.includes("research")) {
      queryObj.fq__guide_name_tk = filterReducer["research"];
    }
    if (filterKeys.includes("researcher")) {
      queryObj.fq__researcher_tk = filterReducer["researcher"];
    }
    if (filterKeys.includes("journal")) {
      queryObj.fq__journal_name = filterReducer["journal"];
    }
    if (filterKeys.includes("countryOfPublication")) {
      queryObj.fq__primary_publisher_country =
        filterReducer["countryOfPublication"];
    }
    if (filterKeys.includes("publisher")) {
      queryObj.fq__publisher_name = filterReducer["publisher"];
    }
    if (filterKeys.includes("institutions")) {
      queryObj.fq__author_address = filterReducer["institutions"];
    }
    if (filterKeys.includes("journalRank")) {
      queryObj.fq__filter_metrix = filterReducer["journalRank"];
    }
    if (filterKeys.includes("sort")) {
      queryObj.sort = filterReducer["sort"];
    }
    if (filterKeys.includes("countryOfPublishingAuthor")) {
      queryObj.fq__author_country_name =
        filterReducer["countryOfPublishingAuthor"];
    }
    if (filterKeys.includes("conference_name")) {
      queryObj.conference_name = filterReducer.conference_name;
    }
    if (filterKeys.includes("university_name")) {
      queryObj.fq__university_name = filterReducer.university_name;
    }
    if (filterKeys.includes("resourcemaster_id")) {
      queryObj.resourcemaster_id = filterReducer.resourcemaster_id;
    }

    if (filterKeys.includes("sourceIndex")) {
      if (filterReducer["sourceIndex"])
        queryObj.fq__pubmed_article = filterReducer["sourceIndex"];
    }
    if (filterKeys.includes("yearFrom")) {
      if (filterReducer.yearFrom) {
        queryObj.fq__yearfrom = filterReducer.yearFrom;
      }
    }
    if (filterKeys.includes("monthFrom")) {
      if (filterReducer.monthFrom) {
        queryObj.fq__dateofpublication = filterReducer.monthFrom;
      }
    }
    if (filterKeys.includes("dateFrom")) {
      if (filterReducer.dateFrom) {
        queryObj.fq__dateofpublication = filterReducer.dateFrom;
      }
    }
    if (filterKeys.includes("openAccess")) {
      if (filterReducer.openAccess) {
        queryObj.fq__fulltext = filterReducer.openAccess;
    }}
    if (filterKeys.includes("retractedArticle")) {
      if (filterReducer.retractedArticle) {
        queryObj.fq__is_retracted = true;
      }
    }
    if (filterKeys.includes("datesetAvailability")) {
      if (filterReducer.datesetAvailability) {
        queryObj.fq__has_datasets = filterReducer.datesetAvailability;
      }
    }
    if (filterReducer.earlyOnline) {
      queryObj.fq__early_online = true;
    }

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }

    if (filterReducer.myLibraryCollection && customerData) {
      queryObj.fq__acl_group = customerData?.my_library_filter;
    }
    if (filterReducer.consortiaCollection && customerData) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }
    if (filterReducer.onosCollection) {
      queryObj.fq__is_onos_resource = true;
    }

    // To trigger the useeffect in basicsearchscreen.tsx
    if (filterReducer.myPersonalLibCollection) {
      queryObj.page = searchReducer.page;
    }

    
    //================ Count Query ===================================//
    let fullTextQuery = await queryConstructor({
      ...queryObj,
      ...dataType,
      // fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${informaticscustomerId?informaticscustomerId:"0"}))`,
    });
    let allQuery = await queryConstructor({
      ...queryObj,
      ...dataType,
    });

    fullTextQuery += "&" + searchQuery;
    allQuery += "&" + searchQuery;

    if (customQuery) {
      fullTextQuery += "&" + searchQuery + "&" + customQuery;
      allQuery += "&" + searchQuery + "&" + customQuery;
    }
    if (filterReducer?.libraryOpac) {
      let kohaQueryObj = {};
      Object.assign(kohaQueryObj, {
        search_keyword: searchReducer?.searchTerm,
      });
      Object.assign(kohaQueryObj, {
        customer_id: customerData?.informaticscustomer_id,
      });
      Object.assign(kohaQueryObj, { page_no: searchReducer.page });
      Object.assign(kohaQueryObj, { per_page: searchReducer.rows });
      Object.assign(kohaQueryObj, { year_limit: filterReducer.yearFrom });

      let kohaQuery = await queryConstructor(kohaQueryObj, "AND", true);

      const data: any = await getOpacData(kohaQuery);

      dispatch(updateSearchResults(data?.data));
      dispatch(updateLoadingState(false));
      return data?.data;
    } // if library opac is true return
    // To update the full text and all count

    dispatch(
      updateQuery({
        fullTextQuery: fullTextQuery,
        allTextQuery: allQuery,
      })
    );

    //================================================================//

    
    let filterQuery = await queryConstructor(queryObj);
    if (searchReducer.fullText) {
      filterQuery += `&fq__(fulltext=true OR acl_group=(${informaticscustomerId?informaticscustomerId:"0"}))`
    }


    let query = searchQuery + "&" + filterQuery + "&" + dataTypeQuery + "&";
    if (customQuery) {
      query =
        searchQuery +
        "&" +
        filterQuery +
        "&" +
        dataTypeQuery +
        "&" +
        customQuery;
    }

    if (!isDSpace && logSearchHistory && !setSearchReducer.setSearchQuery && !filterReducer?.myPersonalLibCollection) {
      // to log search history send true in third argument
      let x = query + "&logsearchhistory=true";
      let y = x.replace(`&fq__(fulltext=true OR acl_group=(${informaticscustomerId}))`, "");
      y += "&datsearchtype_id=1";
      if (searchReducer.advanceSearchQuery) {
        y += "&datsearchtype_id=2";
      } else {
        y += "&datsearchtype_id=1";
      }
      basicSearch(y);
    }

    if (logSearchHistory && !setSearchReducer.setSearchQuery && filterReducer?.myPersonalLibCollection) {
      // to log search history send true in third argument
      let x = query + "&logsearchhistory=true";
      let y = x.replace(`&fq__(fulltext=true OR acl_group=(${informaticscustomerId}))`, "");
      y += "&datsearchtype_id=1";
      if (searchReducer.advanceSearchQuery) {
        y += "&datsearchtype_id=2";
      } else {
        y += "&datsearchtype_id=1";
      }
      getPersonalLibraryCollection(y);
    }

    if (
      filterReducer?.myPersonalLibCollection &&
      !setSearchReducer.setSearchQuery
    ) {
      const data = await getPersonalLibraryCollection(query);

      !!data && dispatch(updateSearchResults(data));
      !!data && dispatch(updateLoadingState(false));
      dispatch(updateIsSettled(true));

      let dataArray = data?.docs;
      let formData = new FormData();
  
      let x = dataArray?.map((obj: any) => {
        let object: any = {
          customer_id: customerId,
          article_id: obj?.article_id,
          consortia_virtualid: consortia_virtualid,
          resource_type: obj?.resource_type,
          access_type: obj?.article_open_status ||  obj?.access_type_id ||  null,
          is_oa_article: obj?.fulltext || false,
        };
  
        if (obj?.articledoi) {
          object.article_doi = obj?.articledoi;
        }
  
        if (obj?.resourceissue_id) {
          object.resourceissue_id = obj?.resourceissue_id;
        }
  
        return object;
      });
  
      let formApiData = {
        data: x,
      };
  
      formData.append("detailed_json", JSON.stringify(formApiData));
      const response = await getBulkLinkRecords(formData);
  
      if (response?.message === "Ok") {
        dispatch(updateLinkToDisplay(response?.data));
      }

      return data;
    }
    let data;
    // If setSearch enabled call setSearch api otherwise articlecore api

    if (
      setSearchReducer.isSetSearchEnabled &&
      setSearchReducer.setSearchQuery
    ) {
      const queryObj: any = {
        set_number_in: setSearchReducer.setInNumber,
      };
      let setInNumber = await queryConstructor(queryObj);

      let arr = searchReducer.searchTerm;
      if (Array.isArray(arr) && arr.length) {
        arr.forEach((str) => {
          setInNumber += " AND " + str;
        });
      }
      let setQuery =
        filterQuery +
        "&" +
        dataTypeQuery +
        "&" +
        searchQuery +
        "&" +
        setInNumber;

      dispatch(updateSetSearchQuery(setQuery));
      if(logSearchHistory && filterReducer.myPersonalLibCollection) {
        data = await setSearch(setQuery, "myPersonal", "count", "false", true);
      } else if (logSearchHistory) {
        setSearch(setQuery, "all", "count", "false", true);
      }

      if (filterReducer.myPersonalLibCollection) {
        data = await setSearch(setQuery, "myPersonal");
      } else if (filterReducer.myLibraryCollection) {
        data = await setSearch(setQuery, "myLibrary");
      } else if (filterReducer.consortiaCollection) {
        data = await setSearch(setQuery, "myConsortia");
      }
      else if (searchReducer.fullText && !filterReducer.myPersonalLibCollection) {
        data = await setSearch(setQuery, "fulltext");
      } else if(!searchReducer.fullText && !filterReducer.myPersonalLibCollection) {
        data = await setSearch(setQuery);
      }
    } else {
      //data = await basicSearch(query);
      if(isDSpace){
        queryObj={
          basic_search: formatString(searchReducer?.searchTerm),
          page_no: searchReducer.page,
          per_page: searchReducer.rows,
        }
      
        if (filterKeys.includes("subject")) {
          queryObj.fq__subjects_name_l3 = filterReducer["subject"];
        }
        if (filterKeys.includes("author")) {
          queryObj.fq__authors_tk = filterReducer["author"];
        }
        
        let filterQuery = await queryConstructor(queryObj);
        data = await dSpaceSearchQuery(filterQuery)
      }else{
        data = await basicSearch(query)
      }
    }
    dispatch(updateSearchResults(data));
    !!data && dispatch(updateLoadingState(false));

    // Getting the logic for the access typebutton and find in library
    
    let dataArray = data?.docs;
    let formData = new FormData();

    let x = dataArray?.map((obj: any) => {
      let object: any = {
        customer_id: customerId,
        article_id: obj?.article_id,
        consortia_virtualid: consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status ||  obj?.access_type_id ||  null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      return object;
    });

    let formApiData = {
      data: x,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    if(!isDSpace){
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      dispatch(updateLinkToDisplay(response?.data));
    }
  }

    // Checking the article is already added ot folder
    const articleData = new FormData();

    const articleIds =
      Array.isArray(dataArray) && dataArray?.map((x: any) => x?.article_id);
    articleData.append("profile_id", user_id);
    articleData.append("article_ids", articleIds.toString());
    if(!isDSpace){
    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));

  }

    // get rss/email alert
    const resourcemaster_ids =
      Array.isArray(dataArray) &&
      dataArray?.map((x: any) => x?.resourcemaster_id && x?.resourcemaster_id)?.filter((y: any) => y !== undefined);
    if (Array.isArray(resourcemaster_ids)) {
      if(!isDSpace){
      const alertSettingData = await getAlertSettings(
        user_id,
        resourcemaster_ids
      );
      let alertDataRes = alertSettingData.data.data[0][0];
      dispatch(updateAlertSettingData(alertDataRes));
    }
    }
    dispatch(updateLoadingState(false));
    return data;
  };
