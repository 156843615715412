import { Colors as ColorsType, Fonts as FontsType } from "../types";

const DARK_THEME = "DARK";
const LIGHT_THEME = "LIGHT";

const Colors: ColorsType = {
  black: "#000000",
  white: "#ffffff",
  primary: "#7852fb",
  coolGray50: "#f9fafb",
  coolGray100: "#f3f4f6",
  purple100: "#edebfe",
  gray_400: "#e5e7eb",
  coolGray300: "#d1d5db",
  gray_600: "#6b7280",
  gray_700: "#676769",
  grey: "#435969",
  coolGray700: "#374151",
  gray_1000: "#343332",
  gray_1100: "#212121",
  gray900: "#111928",
  purple800: "#5521b5",
  indigo_300: "rgba(120, 82, 251, 0.1)",
  red500: "#f05252",
  green100: "#def7ec",
  green800: "#03543f",
  green500: "#0e9f6e",
  orange_500: "#FF5A1F",
  gray_500: "rgba(0, 0, 0, 0.26)"
};

const Fonts: FontsType = {
  helvatica: "Helvetica Neue",
};

const authorRole: any = {
  1: "Author",
  2: "Editor",
  3: "Researcher",
  4: "Guide",
  5: "Speaker",
  6: "Reviewer",
  7: "Moderator"
}

const dataTypes = {
  journalArticle : "Journal Articles",
  journals: "Journals",
  // conferenceProceedings: "Conference proceedings",
  // dissertationThesis: "Dissertation & Thesis",
  conferenceProceedings: "Conference Papers",
  dissertationThesis: "Theses & Dissertation",
  magazine: "Magazine Articles",
  audioVideo: "Audio-visual lectures",
  preprint: "Pre-Print",
  bookSeries: "Books Series",
  books:"Books",
  dataset:"Datasets",
  patents:"Patents",

  dspaceThesis:"Thesis",
  dspaceBookChapter:"Book Chapter",
  dspaceBook:"Book",
  dspaceDataTypeNull:null
  
} 

export { DARK_THEME, LIGHT_THEME, Colors, Fonts, authorRole, dataTypes};
